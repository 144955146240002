import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";

export const CustomDropDown = styled(Select)(({ theme, width, error }) => ({
  "& .MuiSelect-select": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: error ? "1px solid red" : "1px solid #b3b9c9",
    fontSize: 14,
    width: width,
    padding: "12px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    color: "#777E90",
  },
  boxShadow: "none",
  marginBottom: "10px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));
