import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CloseIcon,
  RequiredStar,
  SuccessIcon,
  UploadIcon,
} from "../Icons/Icons";
import {
  DialogBackground,
  DialogTitleStyle,
  FileUploadDiv,
  SubmitButton,
} from "../Theme/Theme";
import { TextInput } from "../InputComponents/TextInput";
import { helperText } from "../Theme/Theme";
import Dropzone from "react-dropzone";
import {
  getAllTags,
  getOneTutorial,
  updateExercise,
  uploadExercise,
  uploadThumbnail,
} from "../Api/Api";
import { getAccessToken, getAdminId, getClinicAdminId } from "../Auth/Auth";
import { toast } from "react-toastify";
import getYouTubeID from "get-youtube-id";
import { YOUTUBE_EMBED_URL, YOUTUBE_THUMBNAIL_URL } from "../Api/Const";
import { MultiValuedTextField } from "../InputComponents/MultiValuedTextField";

export function ExerciseForm(props) {
  const [success, setSuccess] = useState(false);
  const [change, setChange] = useState(false);
  const [videoChange, setVideoChange] = useState(false);
  const [constVideo, setConstVideo] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailAdded, setThumbnailAdded] = useState(false);
  const [thumbURL, setThumbURL] = useState("");
  const [tags, setTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);

  const [titleError, setTitleError] = useState({ status: false, msg: "" });
  const [descriptionError, setDescriptionError] = useState({
    status: false,
    msg: "",
  });
  const [videoUrlError, setVideoUrlError] = useState({
    status: false,
    msg: "",
  });
  const [thumbnailError, setThumbnailError] = useState({
    status: false,
    msg: "",
  });
  const [tagError, setTagError] = useState({ status: false, msg: "" });

  const readTitle = (e) => {
    setTitleError({ status: false, msg: "" });
    setTitle(e);
    setChange(true);
  };
  const readDescription = (e) => {
    setDescriptionError({ status: false, msg: "" });
    setDescription(e);
    setChange(true);
  };
  const readVideoUrl = (e) => {
    if (getYouTubeID(e) === null) {
      setVideoUrlError({ status: true, msg: "Invalid youtube URL." });
      setVideoUrl(e);
      setChange(false);
      return;
    }
    if(props.type === "EDIT" && e.length>41){
      setVideoUrlError({ status: true, msg: "Invalid youtube URL." });
      setVideoUrl(e);
      setChange(false);
      return;
    }
    setVideoUrlError({ status: false, msg: "" });
    setVideoUrl(e);
    setChange(true);
    setVideoChange(true);
  };
  const readThumbnail = (e) => {
    if (e[0].type.includes("image")) {
      setThumbnailAdded(true);
      setThumbnailError({ status: false, msg: "" });
      setThumbnail(e[0]);
      setChange(true);
    }
  };
  const addTags = (values) => {
    setTagError({ status: false, msg: "" });
    setTags(values);
    setChange(true);
  };
  const removeTag = (index) => {
    const newValues = [...tags];
    newValues.splice(index, 1);
    setTags(newValues);
    setChange(true);
  };

  const submitExercise = async () => {
    let error = false;
    if (title === "") {
      setTitleError({ status: true, msg: "Title is Required." });
      error = true;
    }
    if (description === "") {
      setDescriptionError({ status: true, msg: "Description is Required." });
      error = true;
    }
    if (videoUrl === "") {
      setVideoUrlError({ status: true, msg: "Video URL is Required." });
      error = true;
    }
    if (tags.length === 0) {
      setTagError({ status: true, msg: "At least one tag is required." });
      error = true;
    }
    if (error) {
      return;
    }
    setChange(false);
    if (props.type === "NEW") {
      let file;
      if (thumbnail !== undefined) {
        file = await uploadThumbnail(thumbnail);
      }
      const data = {
        tutorialTitle: title,
        tutorialCategory: "EXERCISE_GUID",
        thumbnailURL:
          thumbnail === undefined
            ? YOUTUBE_THUMBNAIL_URL + getYouTubeID(videoUrl) + "/0.jpg"
            : file,
        videoURL: YOUTUBE_EMBED_URL + getYouTubeID(videoUrl),
        description: description,
        tags: tags,
        tutorialUploader: getAdminId(), //If tutorial is uploaded by clinic this id will be clinic id. Else it will be admin id.
      };
      if (file !== "error") {
        const result = await uploadExercise(
          data,
          props.tutorialUploader === "CLINIC"
            ? getClinicAdminId()
            : getAdminId()
        );
        if (result.message === "Success") {
          toast.success(result.message, {
            style: {
              background: "#4F9E4E",
            },
          });
          setSuccess(true);
        } else {
          //Show error notification if upload exercise  fails.
          toast.error(result.message);
        }
      } else {
        //Show error notification if upload thumbnail  fails.
        toast.error("Thumbnail upload failed.");
      }
    }
    if (props.type === "EDIT") {
      let file;
      if (thumbnailAdded === true) {
        file = await uploadThumbnail(thumbnail);
      }
      const data = {
        id: props.exerciseId,
        tutorialTitle: title,
        tutorialCategory: "EXERCISE_GUID",
        thumbnailURL: thumbnailAdded ? file : null,
        videoURL:
          videoChange === true && constVideo !== videoUrl
            ? YOUTUBE_EMBED_URL + getYouTubeID(videoUrl)
            : null,
        description: description,
        tags: tags,
      };
      if (file !== "error") {
        const token = getAccessToken();
        const result = await updateExercise(data, token);
        if (result.message === "Success") {
          toast.success(result.message, {
            style: {
              background: "#4F9E4E",
            },
          });
          setSuccess(true);
        } else {
          //Show error notification if upload exercise  fails.
          toast.error(result.message);
        }
      } else {
        //Show error notification if upload thumbnail  fails.
        toast.error("Thumbnail update failed.");
      }
    }
  };

  const close = () => {
    setSuccess(false);
    setChange(false);
    setVideoChange(false);
    setTitle("");
    setDescription("");
    setVideoUrl("");
    setConstVideo("");
    setThumbnail();
    setThumbnailAdded(false);
    setTags([]);
    setTitleError({ status: false, msg: "" });
    setDescriptionError({ status: false, msg: "" });
    setVideoUrlError({ status: false, msg: "" });
    setThumbnailError({ status: false, msg: "" });
    setTagError({ status: false, msg: "" });
    props.handleClose();
  };

  const getAllTagsOfAdmin = async () => {
    const result = await getAllTags(
      props.tutorialUploader === "CLINIC" ? getClinicAdminId() : getAdminId()
    );
    if (result.message === "Success") {
      setExistingTags(result.content);
    } else {
      setExistingTags([]);
    }
  };

  useEffect(() => {
    const getExercise = async (id) => {
      const result = await getOneTutorial(id);
      setTitle(result.tutorialTitle);
      setDescription(result.description);
      setVideoUrl(result.videoURL);
      setConstVideo(result.videoURL);
      setThumbURL(result.thumbnailURL);
      setTags(result.tags);
    };
    if (props.type === "EDIT") {
      getExercise(props.exerciseId);
    }
    getAllTagsOfAdmin();
  }, [props.open]);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open}
      onClose={close}
      style={DialogBackground}
    >
      {success === false ? (
        <>
          <DialogTitle style={DialogTitleStyle} id="alert-dialog-title">
            {props.type === "NEW" && "Upload Exercise Video"}
            {props.type === "EDIT" && "Edit Exercise Video"}
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              variant="subtitle2"
              style={{
                color: "rgba(36, 39, 49, 1)",
                paddingBottom: "5px",
                display: "inline",
              }}
            >
              Exercise Title
            </DialogContentText>
            <RequiredStar />

            <TextInput
              value={title}
              error={titleError.status}
              onChange={(e) => readTitle(e.target.value)}
              fullWidth
              style={{ paddingBottom: "10px" }}
              placeholder="Enter your exercise title"
            />
            <Typography style={helperText}>{titleError.msg}</Typography>

            <DialogContentText
              variant="subtitle2"
              style={{
                color: "rgba(36, 39, 49, 1)",
                paddingBottom: "5px",
                display: "inline",
              }}
            >
              Exercise Description
            </DialogContentText>
            <RequiredStar />
            <TextInput
              value={description}
              error={descriptionError.status}
              onChange={(e) => readDescription(e.target.value)}
              fullWidth
              style={{ paddingBottom: "10px" }}
              placeholder="Enter your exercise description"
              multiline
              rows={3}
            />
            <Typography style={helperText}>{descriptionError.msg}</Typography>

            <DialogContentText
              variant="subtitle2"
              style={{
                color: "rgba(36, 39, 49, 1)",
                paddingBottom: "5px",
                display: "inline",
              }}
            >
              Video URL
            </DialogContentText>
            <RequiredStar />
            <TextInput
              value={videoUrl}
              error={videoUrlError.status}
              onChange={(e) => readVideoUrl(e.target.value)}
              fullWidth
              style={{ paddingBottom: "10px" }}
              placeholder="Enter your exercise video URL"
            />
            <Typography style={helperText}>{videoUrlError.msg}</Typography>

            <DialogContentText
              variant="subtitle2"
              style={{
                color: "rgba(36, 39, 49, 1)",
                paddingBottom: "5px",
                display: "inline",
              }}
            >
              Tags
            </DialogContentText>
            <RequiredStar />
            <MultiValuedTextField
              tags={tags}
              addTags={(values) => addTags(values)}
              removeTag={(i) => removeTag(i)}
              error={tagError.status}
              existingTags={existingTags}
            />
            <Typography style={helperText}>{tagError.msg}</Typography>

            <DialogContentText
              variant="subtitle2"
              style={{
                color: "rgba(36, 39, 49, 1)",
                paddingBottom: "5px",
                display: "inline",
              }}
            >
              Video Thumbnail
            </DialogContentText>
            <Dropzone onDrop={(acceptedFiles) => readThumbnail(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} style={FileUploadDiv}>
                    <input {...getInputProps()} />
                    {props.type === "EDIT" &&
                    thumbURL !== "" &&
                    thumbnailAdded === false ? (
                      <img
                        src={thumbURL}
                        width="50%"
                        alt="thumbnail"
                        style={{ borderRadius: "8px" }}
                      />
                    ) : (
                      <UploadIcon />
                    )}

                    <br />
                    {thumbnail === null || thumbnail === undefined ? (
                      <>
                        <Typography variant="subtitle2">Drag & Drop</Typography>
                        <Typography variant="fileUploadBoxSubText">
                          or Select files from device
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="fileUploadBoxSubText">
                        {thumbnail.name}
                      </Typography>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <Typography style={helperText}>{thumbnailError.msg}</Typography>
          </DialogContent>

          <DialogActions
            style={{ paddingRight: "20px", paddingBottom: "20px" }}
          >
            <Button
              style={{ textTransform: "none" }}
              color="customGray"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={SubmitButton}
              onClick={submitExercise}
              disabled={!change}
            >
              {props.type === "EDIT" ? "Update" : "Save"}
            </Button>
          </DialogActions>
        </>
      ) : (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <SuccessIcon />
          <br />
          <Typography variant="successFormText">
            Exercise video {props.type === "EDIT" ? "updated" : "uploaded"}{" "}
            succesfully !!!
          </Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={close}
            style={{
              boxShadow: "none",
              marginTop: "30px",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Continue
          </Button>
        </div>
      )}
    </Dialog>
  );
}
