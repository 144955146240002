import React, { useEffect, useState } from "react";
import SideNav from "../../UIComponents/SideNav";
import {
  DeleteButton,
  EditButton,
  FloatingButtonDiv,
  ImportButton,
  MedicationTableDark,
  MedicationTableLight,
  SortButton,
  mainDivStyle,
} from "../../Theme/Theme";
import {
  Button,
  Checkbox,
  Fab,
  Grid,
  TablePagination,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  CheckBoxIcon,
  DeleteButtonIcon,
  EditButtonIcon,
  FloatingButtonAddIcon,
  ImportIcon,
  SortIcon,
} from "../../Icons/Icons";
import { EditMedicationForm } from "../../UIComponents/EditMedicationForm";
import { DeleteMedication } from "../../UIComponents/DeleteMedication";
import { getMedications } from "../../Api/Api";
import { getAccessToken, getAdminId } from "../../Auth/Auth";
import { AddMedicationForm } from "../../UIComponents/AddMedicationForm";
import { MedicationCsvUpload } from "../../UIComponents/MedicationCsvUpload";
import { toast } from "react-toastify";

export function Medications(props) {
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [uploadScv, setUploadCsv] = useState(false);
  const [sort, setSort] = useState(false);
  const [selectAllIds, setSelectAllIds] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [medication, setMedication] = useState([]);
  const [constMedication, setConstMedication] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const selectItems = (i) => {
    if (!selectedItems.includes(i)) {
      setSelectedItems((previous) => {
        return [...previous, i];
      });
    }
    if (selectedItems.includes(i)) {
      setSelectedItems((previous) => {
        return previous.filter((item) => item !== i);
      });
    }
  };

  //Window open functionalities
  const openEdit = () => {
    setEditOpen(true);
  };
  const closeEdit = (status) => {
    setEditOpen(false);
    setSelectedItems([]);
    if (status === true) {
      getMedicationList("A-Z");
    }
  };
  const openDelete = () => {
    setDeleteOpen(true);
  };
  const closeDelete = (status) => {
    setDeleteOpen(false);
    setSelectedItems([]);
    if (status === true) {
      getMedicationList("A-Z");
    }
  };
  const openAddNew = () => {
    setAddNewOpen(true);
  };
  const closeAddNew = (status) => {
    setAddNewOpen(false);
    if (status === true) {
      getMedicationList("A-Z");
    }
  };
  const openCsv = () => {
    setUploadCsv(true);
  };
  const closeScv = (status) => {
    setUploadCsv(false);
    if (status === true) {
      getMedicationList("A-Z");
    }
  };

  //table functions
  const sortMediaction = () => {
    let s = !sort;
    setSort(!sort);
    getMedicationList(s === false ? "A-Z" : "Z-A");
  };
  const selectAll = (e) => {
    setSelectAllIds(e);
    if (e) {
      let arr = [];
      medication.map((m) => {
        arr.push(m.id);
        return arr;
      });
      setSelectedItems(arr);
    } else {
      setSelectedItems([]);
    }
  };
  const filterMedication = (e) => {
    let filteredMedications = [];
    constMedication.filter((obj) => {
      if (obj.name.toLowerCase().includes(e.trim().toLowerCase())) {
        filteredMedications.push(obj);
      }
      return filteredMedications;
    });
    setMedication(filteredMedications);
  };

  const getMedicationList = async (type) => {
    setMedication([]);
    const token = getAccessToken();
    const id = getAdminId();
    setLoading(true);
    const medList = await getMedications(token, id, type);
    if (medList === "Network Error") {
      toast.error(medList, { toastId: "id" });
    }
    if (medList.message === "Success") {
      setMedication(medList.content);
      setConstMedication(medList.content);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMedicationList("A-Z");
  }, []);

  return (
    <>
      <SideNav filterFunction={(e) => filterMedication(e)} />
      <div style={mainDivStyle}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right", paddingBottom: "24px" }}
          >
            <Button
              startIcon={<ImportIcon />}
              style={ImportButton}
              onClick={() => openCsv()}
            >
              Import
            </Button>
            <Button
              startIcon={
                <EditButtonIcon
                  disabled={
                    selectedItems.length > 1 || selectedItems.length === 0
                  }
                />
              }
              style={EditButton}
              color="primary"
              disabled={selectedItems.length > 1 || selectedItems.length === 0}
              onClick={() => openEdit()}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteButtonIcon />}
              style={DeleteButton}
              disabled={selectedItems.length === 0}
              onClick={() => openDelete()}
            >
              Delete
            </Button>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={medication !== undefined && medication.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* top header of the table */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={MedicationTableLight}
          >
            <Checkbox
              icon={<CheckBoxIcon />}
              size="small"
              style={{ padding: "0px" }}
              onClick={(e) => selectAll(e.target.checked)}
              checked={selectAllIds}
            />
            <Typography variant="medicationTableHeaderText">
              Medication
            </Typography>
            <Button
              size="small"
              style={SortButton}
              onClick={() => sortMediaction()}
              endIcon={<SortIcon />}
            >
              Sort
            </Button>
          </Grid>
          {loading === true && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center", paddingTop: "30px" }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
          {medication.length === 0 && loading === false ? (
            <Grid
              container
              style={{
                paddingTop: "50px",
                width: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
              >
                <Typography style={{ margin: "auto" }}>No data</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                maxHeight: "450px",
                overflowY: "scroll",
              }}
            >
              <Grid container>
                {/* content of the table */}
                {medication
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((d, i) => (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={
                        i % 2 === 0 ? MedicationTableDark : MedicationTableLight
                      }
                    >
                      <Checkbox
                        icon={<CheckBoxIcon />}
                        size="small"
                        style={{ padding: "0px" }}
                        onChange={() => selectItems(d.id)}
                        checked={selectedItems.includes(d.id) ? true : false}
                      />
                      <Typography variant="medicationTableBodyText">
                        {d.name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <div style={FloatingButtonDiv}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{ boxShadow: "none" }}
            onClick={() => openAddNew()}
          >
            <FloatingButtonAddIcon />
          </Fab>
        </div>
      </div>

      <EditMedicationForm
        open={editOpen}
        handleClose={(status) => closeEdit(status)}
        selectedItems={selectedItems}
      />
      <DeleteMedication
        open={deleteOpen}
        selectedItems={selectedItems}
        handleClose={(status) => closeDelete(status)}
      />
      <AddMedicationForm
        open={addNewOpen}
        handleClose={(status) => closeAddNew(status)}
      />
      <MedicationCsvUpload
        open={uploadScv}
        handleClose={(status) => closeScv(status)}
      />
    </>
  );
}
