import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Chip,
  IconButton,
  Skeleton,
  TablePagination,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { LogsIcon, ProfileIcon, ProgressIcon } from "../Icons/Icons";
import { useNavigate } from "react-router-dom";
import { FILTER_TYPES } from "../Utils/Enums";
import Prize from "../Images/Prize.png";

export default function PatientTable(props) {
  const navigator = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onClickProgressPage = (patientId) => {
    navigator(`/clinicUser/patientProgress/${patientId}`);
  };

  const onClickLogsPage = (patientId) => {
    navigator(`/clinicUser/patientAllLogs/${patientId}`);
  };

  const onClickProfilePage = (patientId) => {
    const high =
      props.highPatients.find((value) => value.id === patientId) && "High";
    const low =
      props.lowPatients.find((value) => value.id === patientId) && "Low";
    const active =
      props.activePatients.find((value) => value.id === patientId) && "Active";
    const idle =
      props.idlePatients.find((value) => value.id === patientId) && "Idle";
    const patient = props.patientDetails.filter(function (obj) {
      return obj.id === patientId;
    })[0];

    const patientStatus = [high, low, active, idle];
    const exerciseCount = patient.exerciseTutorials
      ? patient.exerciseTutorials.length
      : 0;
    const medicationCount = patient.prescribedMedication
      ? patient.prescribedMedication.length
      : 0;

    navigator(`/clinicUser/patientProfile/${patientId}`, {
      state: { patientStatus, medicationCount, exerciseCount },
    });
  };

  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Patient Name</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Joined Date </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Status </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Last Activity</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography variant="tableHeader">Actions</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.patientDetails !== undefined &&
            props.patientDetails.length > 0 ? (
              props.patientDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((patient, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt="profile"
                          width={"30px"}
                          height={"30px"}
                          src={
                            patient.profilePicURL === null
                              ? require("../Images/testuser.png")
                              : patient.profilePicURL
                          }
                          style={{ borderRadius: "100%", marginRight: "20px" }}
                        />
                        <Typography variant="tableBody">
                          {patient.givenName !== null &&
                          patient.familyName !== null
                            ? patient.givenName + " " + patient.familyName
                            : patient.patientName}
                        </Typography>
                        {props.sixteenPlusUsagePatient.includes(patient.id) ? (
                          <img
                            alt="prize"
                            src={Prize}
                            width={"20px"}
                            height={"20px"}
                          />
                        ) : null}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {new Date(patient.joinedDate)
                          .getDate()
                          .toString()
                          .padStart(2, "0") +
                          "/" +
                          (new Date(patient.joinedDate).getMonth() + 1)
                            .toString()
                            .padStart(2, "0") +
                          "/" +
                          new Date(patient.joinedDate).getFullYear().toString()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {props.isStatusLoading ? (
                        <Skeleton
                          variant="rounded"
                          style={{ borderRadius: "10px" }}
                          animation="wave"
                          height={24}
                          width={45}
                        />
                      ) : null}
                      {[
                        FILTER_TYPES.all,
                        FILTER_TYPES.sixteenPlus,
                        FILTER_TYPES.highUsage,
                      ].includes(props.statusType) &&
                        props.highPatients.filter((p) => p.id === patient.id)
                          .length > 0 && (
                          <Chip
                            label={"High"}
                            size="small"
                            hidden={true}
                            style={{
                              margin: "2px",
                              backgroundColor: "rgba(226, 64, 32, 0.2)",
                              color: "rgba(226, 64, 32, 1)",
                            }}
                          />
                        )}
                      {[
                        FILTER_TYPES.all,
                        FILTER_TYPES.sixteenPlus,
                        FILTER_TYPES.lowUsage,
                      ].includes(props.statusType) &&
                        props.lowPatients.filter((p) => p.id === patient.id)
                          .length > 0 && (
                          <Chip
                            label={"Low"}
                            size="small"
                            hidden={true}
                            style={{
                              margin: "2px",
                              backgroundColor: "rgba(235, 184, 4, 0.2)",
                              color: "rgba(250, 195, 0, 1)",
                            }}
                          />
                        )}
                      {[
                        FILTER_TYPES.all,
                        FILTER_TYPES.sixteenPlus,
                        FILTER_TYPES.idle,
                      ].includes(props.statusType) &&
                        props.idlePatients.filter((p) => p.id === patient.id)
                          .length > 0 && (
                          <Chip
                            label={"Idle"}
                            size="small"
                            hidden={true}
                            style={{
                              margin: "2px",
                              backgroundColor: "rgba(117, 117, 117, 0.2)",
                              color: "rgba(119, 119, 119, 1)",
                            }}
                          />
                        )}
                      {[
                        FILTER_TYPES.all,
                        FILTER_TYPES.sixteenPlus,
                        FILTER_TYPES.activePatient,
                      ].includes(props.statusType) &&
                        props.activePatients.filter((p) => p.id === patient.id)
                          .length > 0 && (
                          <Chip
                            label={"Active"}
                            size="small"
                            hidden={true}
                            style={{
                              margin: "2px",
                              backgroundColor: "rgba(35, 198, 135, 0.2)",
                              color: "rgba(0, 147, 90, 1)",
                            }}
                          />
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {patient.mostRecentUploadDate !== null
                          ? new Date(patient.mostRecentUploadDate)
                              .getDate()
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            (
                              new Date(
                                patient.mostRecentUploadDate
                              ).getMonth() + 1
                            )
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            new Date(patient.mostRecentUploadDate)
                              .getFullYear()
                              .toString()
                          : "-"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        <IconButton
                          title="Patient Profile"
                          style={{
                            padding: "5px",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => onClickProfilePage(patient.id)}
                        >
                          <ProfileIcon iconColor={"#7D7D7D"} />
                        </IconButton>
                      </Typography>
                      {/* <Typography variant="tableBody">
                        <IconButton
                          title="Patient Logs"
                          style={{
                            padding: "5px",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => onClickLogsPage(patient.id)}
                        >
                          <LogsIcon iconColor={"#7D7D7D"} />
                        </IconButton>
                      </Typography> */}
                      <Typography variant="tableBody">
                        <IconButton
                          title="Patient Progress"
                          style={{
                            padding: "5px",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => onClickProgressPage(patient.id)}
                        >
                          <ProgressIcon iconColor={"#7D7D7D"} />
                        </IconButton>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <TableRow>
                {props.isLoading ? (
                  [...Array(5)].map((row, index) => (
                    <TableCell
                      key={index}
                      style={{
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        style={{ borderRadius: "10px" }}
                        variant="rounded"
                        height={15}
                      />
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    colSpan={5}
                    style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
                  >
                    No data
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={
          props.patientDetails !== undefined && props.patientDetails.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.5)",
    paddingTop: "30px",
    fontSize: 12,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000000",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
