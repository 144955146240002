import {
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  IconButton,
  Typography,
  StepIcon,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import { CloseIcon } from "../Icons/Icons";
import { DialogTitleStyle, SubmitButton } from "../Theme/Theme";
import { steps } from "../Api/Const";

export function Instructions(props) {
  return (
    <>
      <DialogTitle style={DialogTitleStyle} id="alert-dialog-title">
        Bulk Upload Videos: How to create CSV file
        <IconButton onClick={props.close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="exerciseInstructionSubText">
          Follow these steps to create a CSV file with the correct data formats
          for each column
        </Typography>
        <Stepper orientation="vertical" activeStep={steps.length}>
          {steps.map((s, i) => (
            <Step key={i} active={true}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIcon
                    {...props}
                    icon={props.icon}
                    active={props.active || props.completed}
                    completed={false}
                  />
                )}
              >
                {s.label}
              </StepLabel>
              <StepContent>
                <Typography variant="exerciseInstructionSubText">
                  {s.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions style={{ paddingRight: "20px", paddingBottom: "20px" }}>
        <Button
          variant="contained"
          style={SubmitButton}
          onClick={() => props.next()}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}
