import { Button, Checkbox, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../Api/Api";
import PasswordInput from "../../InputComponents/PasswordInput";
import { TextInput } from "../../InputComponents/TextInput";
import { CenterGrid, helperText } from "../../Theme/Theme";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { getRememberMe, setRememberMe } from "../../Auth/Auth";
import { CheckBoxIcon, CheckboxBorder } from "../../Icons/Icons";

export function Login() {
  const navigator = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPasssword] = useState("");
  const [remember, setRemember] = useState(0);

  const [emailError, setEmailError] = useState({
    status: false,
    msg: "",
  });
  const [passwordError, setPassswordError] = useState({
    status: false,
    msg: "",
  });

  const readEmail = (e) => {
    setEmailError({ status: false, msg: "" });
    setEmail(e);
  };
  const readPassword = (e) => {
    setPassswordError({ status: false, msg: "" });
    setPasssword(e);
  };

  const rememberMe = () => {
    const val = parseInt(getRememberMe());
    console.log(val);
    if (val === 0 || val === 2) {
      setRemember(1);
      setRememberMe(1);
    }
    if (val === 1) {
      setRemember(0);
      setRememberMe(0);
    }
  };

  const loginOrSignUp = async () => {
    let error = false;
    if (email === "") {
      setEmailError({ status: true, msg: "Email is Required." });
      error = true;
    }
    if (email !== "" && email.indexOf("@") === -1) {
      setEmailError({ status: true, msg: "Invalid Email" });
      error = true;
    }
    if (password === "") {
      setPassswordError({ status: true, msg: "Password is Required." });
      error = true;
    }
    if (error) {
      return;
    }
    const result = await getToken(email, password);
    if (result === undefined) {
      toast.error("Invalid email or password");
    }
    if (result.status === 200 && result.role === 0) {
      navigator("/dashboard");
    }
    if (result.status === 200 && result.role === 100) {
      navigator("/super/dashboard");
    }
    if (result.status === 200 && result.role === 1) {
      navigator("/clinicUser/dashboard");
    }
    if (result.status === 200 && result.role === 5) {
      navigator("/clinicUser/dashboard");
    }
    if (result.status === 200 && result.role === 6) {
      navigator("/clinicUser/dashboard");
    }
    if (result.status === 200 && result.role === 7) {
      navigator("/clinicUser/dashboard");
    }
    if (result.status !== 200) {
      toast.error("Invalid email or password");
    }
  };
  return (
    <div style={CenterGrid}>
      <Grid container>
        <Grid item xs={1} md={4}></Grid>
        <Grid item xs={10} md={4} style={{ textAlign: "center" }}>
          <Grid container style={{ paddingTop: "40px" }}>
            <Grid item xs={12} md={12}>
              <img
                src={require("../../Images/logo.png")}
                style={{ width: "110px" }}
                alt="logo"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{
                textAlign: "left",
                border: "1px solid #F4F5F6",
                boxShadow: "0px 10px 110px 1px rgba(59, 59, 59, 0.08)",
                marginTop: "20px",
                paddingTop: "20px",
                padding: "15px",
                borderRadius: "10px",
                backgroundColor: "#FFF",
              }}
            >
              <Typography variant="subtitle2">Email</Typography>
              <TextInput
                error={emailError.status}
                onChange={(e) => readEmail(e.target.value)}
                onKeyPress={(e) => e.code === "Enter" && loginOrSignUp()}
                fullWidth
                style={{ marginTop: "5px", marginBottom: "10px" }}
              />
              <Typography style={helperText}>{emailError.msg}</Typography>
              <Typography variant="subtitle2">Password</Typography>
              <PasswordInput
                error={passwordError.status}
                onChange={(e) => readPassword(e)}
                onKeyPress={(e) => e.code === "Enter" && loginOrSignUp()}
              />
              <Typography style={helperText}>{passwordError.msg}</Typography>
            </Grid>

            <Grid item xs={6} md={5} style={{ textAlign: "left" }}>
              <Checkbox
                icon={remember === 0 ? <CheckboxBorder /> : <CheckBoxIcon />}
                style={{ paddingLeft: "0px" }}
                size="small"
                onChange={() => rememberMe()}
                checked={remember === 0 ? false : true}
              />
              <Typography
                variant="subtitle2"
                style={{
                  display: "inline",
                  color: "#B1B5C3",
                }}
              >
                Remember me
              </Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item xs={6} md={5} style={{ textAlign: "right" }}>
              <a style={{ textDecoration: "none" }} href="/reset">
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{ paddingTop: "6px" }}
                >
                  Forgot your password?
                </Typography>
              </a>
            </Grid>

            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
              <Button
                // component={Link}
                // to="/dashboard"
                onClick={loginOrSignUp}
                style={{
                  width: "100%",
                  boxShadow: "none",
                  marginTop: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                color="primary"
              >
                Log in
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={4}></Grid>
      </Grid>
    </div>
  );
}
