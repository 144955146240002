import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";

const Search = styled("div")(({ theme, width, error, disabled }) => ({
  display: "flex",
  position: "relative",
  borderRadius: 10,
  backgroundColor: "#ffffff",
  border: error
    ? "1px solid red"
    : disabled
    ? "1px solid #d3d6de"
    : "1px solid #B3B9C9",
  width: width,
  ".MuiInputBase-root": {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "30px",
  paddingTop: "10px",
  paddingLeft: "10px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777E90",
  fontSize: 16,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function IconTextInput(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search width={props.width} error={props.error} disabled={props.disabled}>
        <SearchIconWrapper>{props.icon}</SearchIconWrapper>
        <StyledInputBase
          error={props.error}
          value={props.value}
          disabled={props.disabled}
          onKeyDown={(evt) =>
            props.types === "phone" && evt.key === "e" && evt.preventDefault()
          }
          type={props.type}
          onChange={(e) => props.onChange(e.target.value, props.types, e)}
        />
      </Search>
    </Box>
  );
}
