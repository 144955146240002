import React, { useEffect, useState } from "react";
import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import { mainDivStyle } from "../Theme/Theme";
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import PatientRecordTable from "../UIComponents/PatientRecordTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllRecordTypesInLast30Days,
  getAssignedTutorials,
  getClinicInfo,
  getPatient,
  getPatientRecordCount,
  getPatientRecordSummary,
  getPatientRecords,
  getPatientsExerciseProgress,
} from "../Api/Api";
import { getAccessToken, getAdminId } from "../Auth/Auth";
import { BackArrow, DateIcon } from "../Icons/Icons";
import _, { map, pluck } from "underscore";
import { formatDate } from "../Utils/Helpers";
import {
  CLINIC_TYPES,
  CLINIC_TYPES_KEYS,
  DAYS_SHORT,
  GRAPH_TYPES,
  Months,
} from "../Utils/Enums";
import { Bar } from "react-chartjs-2";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../calender.css";
import Calendar from "../UIComponents/Calender";
import "../Theme/Calender.css";
import RecordPopup from "../UIComponents/RecordPopup";
import Prize from "../Images/Prize.png";

function ClinicAdminPatientProgress() {
  const { patientId } = useParams();
  const token = getAccessToken();
  const navigator = useNavigate();
  const [patientRecords, setPatientRecords] = useState([]);
  const [patientAllRecords, setPatientAllRecords] = useState([]);
  const [category, setCategory] = useState(CLINIC_TYPES.ALL.key);
  const [days, setDays] = useState(0);
  const [patientData, setPatientData] = useState({});
  const [recordList, setRecordList] = useState([]);
  const [summary, setSummary] = useState([]);
  const [exFrequency, setExFrequency] = useState({});
  const [recordTitle, setRecordTitle] = useState("All");
  const [record, setRecord] = useState({});
  const [assignedTutorials, setAssignedTutorials] = useState([]);
  const [clinicRecordType, setClinicRecordType] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState({ type: "day", duration: -7 });
  const [recordData, setRecordData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [filterDisabled, setFilterDisabled] = useState(false)

  useEffect(() => {
    setFilterDisabled(true)
    function getDataCallback(data) {
      const orderedRecord = _.sortBy(
        data,
        (item) => item.uploadedDate
      ).reverse();
      setPatientRecords(orderedRecord);
      setPatientAllRecords(orderedRecord);
    }

    const getData=async()=>{
      await getPatientRecords(patientId, selectedMonth, getDataCallback, token);
      setFilterDisabled(false)
    }
    getData()
  }, [selectedMonth]);

  useEffect(() => {
    async function fetchPatient() {
      const result = await getPatient(patientId, token);
      if (result.message === "Success") {
        setPatientData(result.content);
      }
    }

    async function fetchClinic() {
      const clinicId = getAdminId(); //method was declared in earlier versions. Here It returns the clinicId
      const result = await getClinicInfo(clinicId, token);
      if (result.content !== null) {
        const filteredClinicTypes = CLINIC_TYPES_KEYS.filter(
          (value) => result.content?.clinicTypes.includes(value) && value
        );
        setClinicRecordType([CLINIC_TYPES.ALL.key, ...filteredClinicTypes]);
      }
    }

    async function fetchPatientAssignedTutorials() {
      const result = await getAssignedTutorials(patientId, token);
      if (result.message === "Success") {
        setAssignedTutorials(result.content);
      }
    }

    fetchPatient();
    fetchClinic();
    fetchPatientAssignedTutorials();
    getCategory(CLINIC_TYPES.ALL.key);
  }, []);

  useEffect(() => {
    const date = new Date();
    onSelectedMonth(date);
  }, []);

  useEffect(() => {
    async function fetchPatientExerciseProgress() {
      let today = new Date();
      const res = await getPatientsExerciseProgress(
        patientId,
        type.type,
        today,
        type.duration,
        token
      );

      let item = res.content;
      if (type.type === "day") {
        let dataItems = {
          labels: map(DAYS_SHORT, function (num) {
            return num;
          }),
          datasets: [
            {
              data: map(DAYS_SHORT, function (num, index) {
                return item[index + 1];
              }),
              backgroundColor: "#E24020",
              barThickness: 20,
            },
          ],
        };
        setRecordData(dataItems);
      } else if (type.type === "week") {
        let dataItems = {
          labels: map(Object.keys(item), function (num) {
            return "Week " + num;
          }),
          datasets: [
            {
              data: Object.values(item),
              backgroundColor: "#E24020",
              barThickness: 20,
            },
          ],
        };
        setRecordData(dataItems);
      } else if (type.type === "month") {
        let dataItems = {
          labels: map(Object.keys(item), function (num) {
            return Months[num - 1];
          }),
          datasets: [
            {
              data: Object.values(item),
              backgroundColor: "#E24020",
              barThickness: 20,
            },
          ],
        };
        setRecordData(dataItems);
      }
    }

    fetchPatientExerciseProgress();
  }, [type]);

  function onClickCategory(value) {
    setCategory(value);
    getCategory(value, selectedMonth);
  }

  function onSelectedMonth(date) {
    const month = [Months[date.getMonth()]];
    setSelectedDate(`${month[0].slice(0, 3)}, ${date.getFullYear()}`);
    setCategory(CLINIC_TYPES.ALL.key);
    getCategory(CLINIC_TYPES.ALL.key, date);
    setSelectedMonth(date);
    setAnchorEl(null);
  }

  function filterRecords(type) {
    if (type === CLINIC_TYPES.ALL.label) {
      setPatientRecords(patientAllRecords);
    } else {
      const clone = patientAllRecords.filter((value) => value.type === type);
      setPatientRecords(clone);
    }
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function getCategory(condition, month) {
    let cond;
    let formattedDates;
    let recordType;

    switch (condition) {
      case CLINIC_TYPES.ALL.key:
        cond = "allRecords";
        recordType = CLINIC_TYPES.ALL.label;
        setRecordTitle("All");
        break;
      case CLINIC_TYPES.EXERCISE.key:
        cond = "record";
        recordType = "record";
        setRecordTitle(CLINIC_TYPES.EXERCISE.name);
        break;
      case CLINIC_TYPES.DIET.key:
        cond = "dietRecord";
        recordType = CLINIC_TYPES.DIET.label;
        setRecordTitle(CLINIC_TYPES.DIET.name);
        break;
      case CLINIC_TYPES.MEDICATION.key:
        cond = "medicationRecord";
        recordType = CLINIC_TYPES.MEDICATION.label;
        setRecordTitle(CLINIC_TYPES.MEDICATION.name);
        break;
      case CLINIC_TYPES.VITALS.key:
        cond = "vitalRecord";
        recordType = CLINIC_TYPES.VITALS.label;
        setRecordTitle(CLINIC_TYPES.VITALS.name);
        break;
      case CLINIC_TYPES.SYMPTOMS.key:
        cond = "symptomRecord";
        recordType = CLINIC_TYPES.SYMPTOMS.label;
        setRecordTitle(CLINIC_TYPES.SYMPTOMS.name);
        break;
      default:
        break;
    }

    getSummary();
    filterRecords(recordType);
    const today = new Date();
    const firstDay = new Date(
      month ? month.getFullYear() : today.getFullYear(),
      month ? month.getMonth() : today.getMonth(),
      1
    );
    const lastDay = new Date(
      month ? month.getFullYear() : today.getFullYear(),
      month ? month.getMonth() + 1 : today.getMonth() + 1,
      0
    );

    // Format the dates to 'yyyy-mm-dd' format
    const formattedStartDate = formatDate(firstDay);
    const formattedEndDate = formatDate(lastDay);

    const res = await getPatientRecordCount(
      patientId,
      cond,
      formattedStartDate,
      formattedEndDate,
      token
    );

    try {
      if (condition === CLINIC_TYPES.ALL.key) {
        formattedDates = res.content.map((e) => {
          return e; // Assuming e is a date string in a format like 'YYYY-MM-DD'
        });
      } else {
        formattedDates = pluck(res.content, "uploadedDate").map((e) => {
          return e?.slice(0, 10); // Assuming e is a date string in a format like 'YYYY-MM-DD'
        });
      }

      const markedDatesObject = formattedDates.map((date) => {
        const splitDate = date.split("-");
        return parseInt(splitDate[2]);
      });

      setRecordList(markedDatesObject);

      let groupedByDate;
      if (condition === CLINIC_TYPES.ALL.key) {
        groupedByDate = _.groupBy(res.content, (obj) => obj?.split(" ")[0]);
        setDays(Object.keys(groupedByDate).length);
      } else {
        groupedByDate = _.groupBy(
          res.content,
          (obj) => obj.uploadedDate?.split(" ")[0]
        );
        setDays(Object.keys(groupedByDate).length);
      }

      let result = {};
      Object.keys(groupedByDate).map((item) => {
        const month = item.split("-");
        result = {
          ...result,
          [month[2].replace(/^0+/, "")]: groupedByDate[item].length,
        };
      });

      // Output the result object containing uploadedDate as keys and the count as values
      if (cond === CLINIC_TYPES.ALL.key) {
        setExFrequency(0);
      } else {
        setExFrequency(result);
      }
    } catch (error) {
      console.log("result error.", error);
    }
  }

  async function getSummary() {
    let currentDate = new Date();
    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const res = await getAllRecordTypesInLast30Days(
      patientId,
      token,
      thirtyDaysAgo.getFullYear(),
      thirtyDaysAgo.getMonth() + 1,
      thirtyDaysAgo.getDate(),
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    if (res.message === "Success") {
      setSummary(res.content);
    }
  }

  function onViewRecord(data) {
    setRecord(data);
    handleOpen();
  }

  function goBack() {
    navigator(`/clinicUser/patients`);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  function onSetGraphDataType(type) {
    if (type === "day") {
      setType({ type: "day", duration: -7 });
    } else if (type === "week") {
      setType({ type: "week", duration: -45 });
    } else if (type === "month") {
      setType({ type: "month", duration: 45 });
    }
  }

  return (
    <>
      <ClinicAdminSideNav />
      <div style={mainDivStyle}>
        <Grid container>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Grid container justifyContent={"space-between"}>
              <Grid
                style={{
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton padding={"10px"} onClick={goBack}>
                  <BackArrow />
                </IconButton>
                <Typography style={{ fontWeight: "700", paddingLeft: "10px" }}>
                  Patient Progress
                </Typography>
              </Grid>
              <Grid padding={"10px"}>
                {clinicRecordType &&
                  clinicRecordType.map((item, index) => {
                    return (
                      <IconButton
                        key={index}
                        onClick={() => {
                          onClickCategory(item);
                        }}
                        style={{ backgroundColor: "transparent" }}
                        disabled={filterDisabled}
                      >
                        <div
                          style={{
                            borderRadius: 60,
                            alignItems: "center",
                            justifyContent: "center",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            backgroundColor: category === item && "#E24020",
                          }}
                        >
                          <Typography
                            style={{
                              color: category === item ? "#fff" : "#000000",
                              fontSize: 12,
                              padding: "5px",
                            }}
                          >
                            {Object.values(CLINIC_TYPES).map((record) => {
                              return record.key === item && record.name;
                            })}
                          </Typography>
                        </div>
                      </IconButton>
                    );
                  })}
                <Button
                  onClick={handleClick}
                  style={{
                    color: "#000000",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  endIcon={<DateIcon iconColor={"#E24020"} />}
                >
                  {selectedDate}
                </Button>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClosePopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  elevation={0}
                >
                  <ReactDatePicker
                    selected={selectedMonth}
                    onChange={(date) => onSelectedMonth(date)}
                    inline
                    showMonthYearPicker
                  />
                </Popover>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} style={{ padding: "10px" }}>
                <Grid width="100%" backgroundColor="white" padding="10px">
                  <Grid container>
                    <Grid item xs={11}>
                      <Grid container>
                        <img
                          alt="profile"
                          width={"100px"}
                          height={"100px"}
                          src={
                            !patientData.profilePicURL
                              ? require("../Images/testuser.png")
                              : patientData.profilePicURL
                          }
                          style={{ borderRadius: "100%", margin: "10px" }}
                        />
                        <CardContent>
                          <Typography fontWeight="md" variant="h4">
                            {patientData.givenName && patientData.familyName
                              ? patientData.givenName +
                                " " +
                                patientData.familyName
                              : patientData.patientName}
                          </Typography>
                          <Typography variant="body2" paddingTop={"10px"}>
                            Activity
                          </Typography>
                          <Grid container direction="row">
                            <Typography
                              variant="body2"
                              fontWeight={"500"}
                              color={
                                summary.length >= 16 ? "#009D09" : "#E24020"
                              }
                            >
                              {summary.length} Days
                            </Typography>
                            <Typography
                              variant="body2"
                              fontWeight={"500"}
                              color={"#72767C"}
                              marginLeft={"5px"}
                            >
                              Usage in Last 30 Days{" "}
                            </Typography>
                          </Grid>
                        </CardContent>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      {summary.length >= 16 ? (
                        <img alt="prize" src={Prize} />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    padding="10px"
                    height={"240px"}
                    width="100%"
                    alignItems={"center"}
                  >
                    {category === CLINIC_TYPES.EXERCISE.key ? (
                      recordData?.labels && recordData.labels.length > 0 ? (
                        <Grid container>
                          <Grid container justifyContent={"flex-end"}>
                            {GRAPH_TYPES.map((item, index) => {
                              return (
                                <Button
                                  key={index}
                                  variant="outlined"
                                  style={{
                                    color:
                                      type.type === item
                                        ? "#E24020"
                                        : "#000000",
                                    borderColor:
                                      type.type === item
                                        ? "#E24020"
                                        : "#FFFFFF",
                                    textTransform: "capitalize",
                                    marginLeft: 5,
                                    marginRight: 5,
                                    padding: 0,
                                    fontSize: 12,
                                  }}
                                  onClick={() => onSetGraphDataType(item)}
                                >
                                  {item}
                                </Button>
                              );
                            })}
                          </Grid>
                          <Bar
                            data={recordData}
                            height={100}
                            options={{
                              plugins: {
                                legend: {
                                  labels: {
                                    boxWidth: 0,
                                    color: "#FFFFFF",
                                  },
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    callback: function (value) {
                                      return `${value} min`;
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )
                    ) : (
                      <>
                        <Typography
                          variant="subtitle1"
                          color="#E24020"
                          fontWeight="700"
                        >
                          Diagnosis:
                        </Typography>
                        <Typography variant="body2">
                          {patientData.diagnosis}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ padding: "10px" }}>
                <Grid width="100%" backgroundColor="white" padding="10px">
                  <Grid
                    container
                    flexDirection={"row"}
                    paddingLeft={"35px"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="subtitle1">{`${
                      Months[selectedMonth.getMonth()]
                    }, ${selectedMonth.getFullYear()}`}</Typography>
                    <Grid container width={"23%"} flexDirection="row">
                      <Typography variant="caption" color="#E24020">
                        {days} Days
                      </Typography>
                      <Typography variant="caption" marginLeft={"2px"}>
                        Usage
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    height={"345px"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Calendar
                      highlightedDays={recordList}
                      selectedMonth={selectedMonth}
                      isRecordExercise={
                        recordTitle === CLINIC_TYPES.EXERCISE.name
                          ? true
                          : false
                      }
                      exFrequency={exFrequency}
                      isRecordMed={
                        recordTitle === CLINIC_TYPES.MEDICATION.name
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid padding={"10px"}>
              <Typography
                variant="body1"
                fontWeight="500"
                paddingBottom={"15px"}
              >
                {recordTitle} Logs
              </Typography>
              <PatientRecordTable
                patientRecords={patientRecords}
                onViewRecord={onViewRecord}
              />
            </Grid>
          </Grid>
        </Grid>
        <RecordPopup
          isModalOpen={open}
          onClose={handleClose}
          recordData={record}
          type={record?.type}
          assignedTutorials={assignedTutorials}
        />
      </div>
    </>
  );
}

export default ClinicAdminPatientProgress;
