import React, { useEffect, useState } from "react";
import { mainDivStyle } from "../../Theme/Theme";
import { Grid, IconButton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { BackArrow } from "../../Icons/Icons";
import { BarChart } from "../../UIComponents/BarChart";
import dayjs from "dayjs";
import { getRecordProgressOfClinic } from "../../Api/Api";
import SideNav from "../../UIComponents/SideNav";
import { getAccessToken } from "../../Auth/Auth";

export function ClinicSummary(props) {
  const id = useParams().clinicId;
  const [loading, setLoading] = useState(true);
  const [progressData, setProgressData] = useState();

  useEffect(() => {
    const token = getAccessToken();
    const today = dayjs().format("YYYY-MM-DD");
    const weekBefor = dayjs().subtract(1, "week").format("YYYY-MM-DD");
    const summary = async () => {
      const summaryData = await getRecordProgressOfClinic(
        id,
        weekBefor,
        today,
        token
      );
      if (summaryData.content !== undefined) {
        setProgressData(summaryData.content);
        setLoading(false);
      }
    };
    summary();
  }, [id]);
  return (
    <>
      <SideNav />
      <div style={mainDivStyle}>
        <Grid
          container
          style={{
            paddingTop: "20px",
            backgroundColor: "white",
            marginTop: "40px",
            marginLeft: "20px",
            marginRight: "20px",
            width: "96%",
            marginBottom: "20px",
            paddingBottom: "30px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{
              paddingBottom: "30px",
            }}
          >
            <IconButton
              component={Link}
              to={`/clinics/${id}/view`}
              style={{
                marginLeft: "30px",
                marginRight: "10px",
                paddingTop: "5px",
              }}
            >
              <BackArrow />
            </IconButton>
            <Typography variant="viewAndUpdateHeaderFont">
              Clinic Progress
            </Typography>
            <br />
            <br />
            <Typography variant="graphHeader">
              Exercise Analysis Graph
            </Typography>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid
            item
            xs={12}
            md={10}
            style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
          >
            {loading === true || progressData === undefined ? (
              <Typography>No data</Typography>
            ) : (
              <BarChart progressData={progressData} />
            )}
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </div>
    </>
  );
}
