import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
} from "../Theme/Theme";
import { CloseIcon } from "../Icons/Icons";
import { getAccessToken } from "../Auth/Auth";
import { deleteExerciseTag } from "../Api/Api";
import { toast } from "react-toastify";

export function DeleteTag(props) {
  const close = (status) => {
    props.handleClose(status);
  };

  const deleteTag = async () => {
    const token = getAccessToken();
    const data = [props.tagId];
    const result = await deleteExerciseTag(token, data);
    if (result.message === "Success") {
      close(true);
      toast.success("Tag deleted successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      toast.error(result.message);
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          Delete Tag ?
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle1"
            style={{ color: "rgba(113, 113, 113, 1)", fontWeight: 400 }}
          >
            Deleting Tag will permanently remove it from your exercises.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={close}
          >
            No, Keep Tag
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={SubmitButton}
            autoFocus
            onClick={deleteTag}
          >
            Yes, Delete Tag
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
