import React, { useEffect, useState } from 'react';
import '../Theme/Calender.css';
import { Popover, Typography } from '@mui/material';

const Calendar = ({ highlightedDays, selectedMonth, isRecordExercise = false, exFrequency, isRecordMed = false}) => {
    const date = new Date();
    const [calendarDays, setCalendarDays] = useState([]);
    const [anchorEl, setAnchorEl] = useState({ anchorEl: null, popNo: -1 });
    const currentMonth = selectedMonth ? selectedMonth.getMonth() : date.setMonth();
    const currentYear = selectedMonth ? selectedMonth.getFullYear() : date.getFullYear();

    useEffect(() =>{
        renderCalendar();
    },[currentMonth]);
      
    // function to render days
    function renderCalendar() {
      // get prev month current month and next month days
      date.setDate(1);
      const firstDay = new Date(currentYear, currentMonth, 1);
      const lastDay = new Date(currentYear, currentMonth + 1, 0);
      const lastDayIndex = lastDay.getDay();
      const lastDayDate = lastDay.getDate();
      const prevLastDay = new Date(currentYear, currentMonth, 0);
      const prevLastDayDate = prevLastDay.getDate();
      const nextDays = 7 - lastDayIndex - 1;
    
      let newDays = [];
      
      // prev days
      for (let x = firstDay.getDay(); x > 0; x--) {
          newDays = [...newDays, { class: "day prev", day: prevLastDayDate - x + 1, months: currentMonth}];
      }
    
      // current month days
      for (let i = 1; i <= lastDayDate; i++) {
        // check if its today then add today className
        if (
          i === new Date().getDate() &&
          currentMonth === new Date().getMonth() &&
          currentYear === new Date().getFullYear()
        ) {
          // if date month year matches add today
          newDays = [...newDays, { class: "day today", day: i, months: currentMonth }];
        } else {
          newDays = [...newDays, { class: "day", day: i , months: currentMonth}];
        }
      }
      // next Month days
      for (let j = 1; j <= nextDays; j++) {
          newDays = [...newDays, { class: "day next", day: j, months: currentMonth }];
      }
    
      setCalendarDays(newDays);
    }

    const handleClick = (event, index) => {
      setAnchorEl({anchorEl: event.currentTarget, popNo: index});
    };
  
    const handleClosePopup = () => {
      setAnchorEl({anchorEl: null, popNo: -1});
    };
    
    return (
        <div className="container">
          <div className="calendar">
            <div className="weekdays">
              <div className="day">Sun</div>
              <div className="day">Mon</div>
              <div className="day">Tue</div>
              <div className="day">Wed</div>
              <div className="day">Thu</div>
              <div className="day">Fri</div>
              <div className="day">Sat</div>
            </div>
            <div className="days">
                {
                    calendarDays.map((item, index) => {
                        return <div key={index} className={`${item.class}`}>
                            {
                                item.class === 'day today' ? <div className='selectedToday'>{item.day}</div>
                                : highlightedDays.includes(item.day) && item.class !== "day next" && item.class !== "day prev" ?
                                    <> 
                                      <button className='selected' onClick={(e) => handleClick(e, index)}>
                                        {item.day}
                                      </button>
                                      {
                                        (isRecordExercise || isRecordMed) && <Popover
                                          key={index}
                                          open={index === anchorEl.popNo}
                                          anchorEl={anchorEl.anchorEl}
                                          onClose={handleClosePopup}
                                          anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                          }}
                                          transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                          }}
                                          >
                                            <Typography variant="caption" paddingLeft={"5px"} paddingRight={"5px"} borderRadius={"5px"}>
                                              {`${isRecordExercise ? 'No of exercises': 'No of Medications'}: ${exFrequency[item.day]}`}
                                            </Typography>
                                        </Popover>
                                      }
                                    </> 
                                  :<div>{item.day}</div>
                            }   
                        </div>
                    })
                }
            </div>
        </div>
    </div>
    )
};

export default Calendar;