import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { EyeIcon, HideEyeIcon } from "../Icons/Icons";

const Search = styled("div")(({ theme, width, error }) => ({
  display: "flex",
  position: "relative",
  borderRadius: 8,
  backgroundColor: "#ffffff",
  border: error ? "1px solid red" : "1px solid #b3b9c9",
  width: width,
  ".MuiInputBase-root": {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "30px",
  paddingTop: "6px",
  paddingRight: "10px",
  cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777E90",
  fontSize: 16,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function PasswordInput(props) {
  const [show, setShow] = React.useState(false);
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ marginTop: "5px", marginBottom: "10px" }}
    >
      <Search width={props.width} error={props.error}>
        <StyledInputBase
          type={show === false ? "password" : "text"}
          value={props.value}
          disabled={props.disabled}
          onChange={(e) => props.onChange(e.target.value)}
          onKeyPress={(e) => props.onKeyPress(e)}
        />
        <SearchIconWrapper onClick={() => setShow(!show)}>
          {show ? (
            <HideEyeIcon error={props.error} />
          ) : (
            <EyeIcon error={props.error} />
          )}
        </SearchIconWrapper>
      </Search>
    </Box>
  );
}
