import React from "react";
import { AppBar, Grid } from "@mui/material";
import { SearchBar } from "../InputComponents/SearchBar";
import { Link } from "react-router-dom";

export function ClinicAdminTopNav(props) {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${props.drawerWidth}px)`,
        ml: `${props.drawerWidth}px`,
      }}
      style={{ backgroundColor: "white" }}
      elevation={0}
    >
      <Grid container style={{ padding: "15px", paddingRight: "0px" }}>
        <Grid item xs={6} md={3} style={{ paddingTop: "8px" }}>
          {props.tabName !== "/clinicUser/dashboard" &&
            props.tabName !== "/clinicUser/patients" &&
            props.tabName !== "/clinicUser/chat" &&
            props.tabName !== "/clinicUser/users" && (
              <SearchBar
                placeHolder={"Search or type"}
                filterFunction={(e) => props.filterFunction(e)}
                bgColor={"#F5F4F6"}
              />
            )}
        </Grid>
        <Grid item md={7}></Grid>
        <Grid
          item
          xs={3}
          md={1}
          style={{
            textAlign: "right",
            display: "flex",
            paddingRight: "8px",
            justifyContent: "right",
            alignItems: "right",
            cursor: "pointer",
            paddingTop: "12px",
          }}
        >
          {/* <NotificationIcon /> */}
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
          style={{
            paddingLeft: "8px",
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/clinicUser">
            <img
              alt="profile"
              width={"40px"}
              height={"40px"}
              src={
                localStorage.getItem("profilePic") === ""
                  ? require("../Images/testuser.png")
                  : localStorage.getItem("profilePic")
              }
              style={{ borderRadius: "100%" }}
            />
          </Link>
        </Grid>
      </Grid>
    </AppBar>
  );
}
