import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
} from "../Theme/Theme";
import { CloseIcon, SuccessIcon } from "../Icons/Icons";
import { IconButton, Typography } from "@mui/material";
import { getAccessToken } from "../Auth/Auth";
import { deleteAdminAccount } from "../Api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function AdminDelete(props) {
  const [success, setSuccess] = useState(false);

  const deleteAdmin = async () => {
    const token = getAccessToken();
    const result = await deleteAdminAccount(token, props.adminId);
    if (result === 200) {
      close();
    } else {
      toast.error(result.message);
    }
  };

  const close = () => {
    setSuccess(false);
    props.handleClose();
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        {success === false ? (
          <>
            <DialogTitle style={DialogTitleStyle}>
              Delete User?
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                variant="subtitle1"
                style={{ color: "rgba(113, 113, 113, 1)", fontWeight: 400 }}
              >
                Deleting a User will permanently remove it from your panel.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ marginRight: "20px", marginBottom: "20px" }}
            >
              <Button
                color="customGray"
                style={{ textTransform: "none" }}
                onClick={close}
              >
                No, Keep User
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={SubmitButton}
                autoFocus
                onClick={deleteAdmin}
              >
                Yes, Delete User
              </Button>
            </DialogActions>
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <SuccessIcon />
            <br />
            <Typography variant="successFormText">
              Exercise video deleted succesfully !!!
            </Typography>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={close}
              style={{
                boxShadow: "none",
                marginTop: "30px",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Continue
            </Button>
          </div>
        )}
      </Dialog>
    </>
  );
}
