import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DeleteIcon, EditIcon, ViewIcon } from "../Icons/Icons";
import { IconButton, TablePagination, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AlertDialog from "./AlertDialog";
import { useState } from "react";

export default function ClinicTable(props) {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Clinic Name</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Location</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">
                  Number of Patients
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">
                  No of Exercise Logs
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography variant="tableHeader">Contact no</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography variant="tableHeader">Actions</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.clinicDetails !== undefined &&
            props.clinicDetails.length > 0 ? (
              props.clinicDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((clinic, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      <Typography variant="tableBody">
                        {clinic.clinicName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {clinic.location}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {clinic.patients === null ? 0 : clinic.patients.length}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {clinic.recordings === null
                          ? 0
                          : clinic.recordings.length}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {clinic.contactNO}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        component={Link}
                        to={`/clinics/${clinic.id}/view`}
                      >
                        <ViewIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={`/clinics/${clinic.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <AlertDialog
                        clinicId={clinic.id}
                        title={"Delete Clinic"}
                        sentence2="Deleting a Clinic will permanently remove it from your panel."
                        icon={DeleteIcon()}
                        deleteClinic={(token, clinicId) =>
                          props.deleteClinic(token, clinicId)
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
                >
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={props.clinicDetails !== undefined && props.clinicDetails.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.5)",
    paddingTop: "30px",
    fontSize: 12,
    fontWeigth: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeigth: 400,
    color: "#000000",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
