import { Autocomplete, Chip, Paper, TextField } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";

export function MultiValuedTextField(props) {
  const [existingTags, setExistingTags] = useState([]);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const readTags = (event, newValue) => {
    props.addTags(newValue);
    setDropDownOpen(false);
  };
  useEffect(() => {
    let tags = [];
    props.existingTags.map((t) => {
      tags.push(t.name);
    });
    setExistingTags(tags);
  }, [props.existingTags]);

  return (
    <Autocomplete
      clearIcon={false}
      options={existingTags}
      freeSolo
      multiple
      value={props.tags}
      onChange={(event, newValue) => readTags(event, newValue)}
      open={dropDownOpen}
      onInputChange={(event, newInputValue) => {
        setDropDownOpen(Boolean(newInputValue.trim()));
      }}
      PaperComponent={(props) => <Paper {...props} elevation={10} />}
      style={{ marginBottom: "10px" }}
      renderInput={(params) => (
        <CustomTextInput
          placeholder="Type the tag name and hit enter"
          {...params}
          InputLabelProps={{
            shrink: false,
          }}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            startAdornment: props.tags.map((value, index) => (
              <Chip
                key={index}
                label={value}
                onDelete={() => {
                  props.removeTag(index);
                }}
                style={{ marginRight: 5, borderRadius: "35px", color: "white" }}
                color="primary"
              />
            )),
            sx: {
              borderRadius: "8px",
              border: props.error ? "1px solid red " : "none",
              "&:hover fieldset": {
                border: "1px solid #C4C4C4 !important",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid #C4C4C4 !important",
              },
            },
          }}
        />
      )}
    />
  );
}

export const CustomTextInput = styled(TextField)(({ theme, width, error }) => ({
  "& .MuiAutocomplete-input": {
    position: "relative",
    backgroundColor: "white",
    fontSize: 14,
    color: "#777E90",
  },
}));
