import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ChatIcon,
  DashboardIcon,
  ExerciseIcon,
  LogoutIcon,
  PatientGreyIcon,
  PatientRedIcon,
  SettingsIcon,
  TherapistsIcon,
} from "../Icons/Icons";
import { ClinicAdminTopNav } from "./ClinicAdminTopNav";
import { getRole } from "../Auth/Auth";
import { getClinicInfo } from "../Api/Api";
import { getAccessToken, getAdminId } from "../Auth/Auth";
import { Chip } from "@mui/material";

const drawerWidth = 240;

export default function ClinicAdminSideNav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabName, setTabName] = useState("/");
  const [listItems, setItemList] = useState([]);
  const [isChatDisabled, setChatDisabled] = useState(true);

  const clinicOwnerAndClinicAdmin = [
    {
      name: "Dashboard",
      route: "/clinicUser/dashboard",
    },
    {
      name: "Users",
      route: "/clinicUser/users",
    },
    {
      name: "Patients",
      route: "/clinicUser/patients",
    },
    {
      name: "Exercise Library",
      route: "/clinicUser/exercise",
    },
    {
      name: "Message",
      route: "/clinicUser/chat",
    },
  ];
  const therapist = [
    {
      name: "Dashboard",
      route: "/clinicUser/dashboard",
    },
    {
      name: "Patients",
      route: "/clinicUser/patients",
    },
    {
      name: "Exercise Library",
      route: "/clinicUser/exercise",
    },
    {
      name: "Message",
      route: "/clinicUser/chat",
    },
  ];
  const reportingStaff = [
    {
      name: "Dashboard",
      route: "/clinicUser/dashboard",
    },
    {
      name: "Patients",
      route: "/clinicUser/patients",
    },
  ];

  const bottomListItems = [
    {
      name: "Logout",
      route: "/",
    },
  ];

  useEffect(() => {
    if (parseInt(getRole()) === 1 || parseInt(getRole()) === 6) {
      setItemList(clinicOwnerAndClinicAdmin);
    }
    if (parseInt(getRole()) === 5) {
      setItemList(therapist);
    }
    if (parseInt(getRole()) === 7) {
      setItemList(reportingStaff);
    }
    if (
      [
        "/clinicUser/dashboard",
        "/clinicUser/users",
        "/clinicUser/exercise",
        "/clinicUser/chat",
      ].includes(location.pathname)
    ) {
      setTabName(location.pathname);
    } else if (location.pathname === "/clinicUser") {
      setTabName("");
    } else {
      setTabName("/clinicUser/patients");
    }
  }, [location.pathname]);

  useEffect(() => {
    async function fetchClinic() {
      const clinicId = getAdminId();
      const token = getAccessToken();
      const result = await getClinicInfo(clinicId, token);
      if (result.content !== null) {
        setChatDisabled(result.content.chatDisabled);
      }
    }

    fetchClinic();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ClinicAdminTopNav
        drawerWidth={drawerWidth}
        filterFunction={(e) => props.filterFunction(e)}
        tabName={tabName}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderWidth: "0px",
          },
        }}
        variant="permanent"
        anchor="left"
        elevation={0}
      >
        <Toolbar style={{ paddingLeft: "0px" }}>
          <img
            src={require("../Images/logo.png")}
            style={{ width: "95px" }}
            alt="logo"
          />
        </Toolbar>
        <List style={{ paddingTop: "30px" }}>
          {listItems.map((text, index) =>
            text.name === "Message" ? (
              !isChatDisabled ? (
                <ListItem
                  key={index}
                  disablePadding
                  style={{
                    backgroundColor:
                      tabName === text.route ||
                      tabName.substring(0, 8) === text.route
                        ? "rgba(226, 64, 32, 0.1)"
                        : "white",
                    marginLeft: "10px",
                    width: "90%",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={text.route}
                    onClick={() => {
                      setTabName(text.route);
                    }}
                  >
                    {text.route === "/clinicUser/chat" && (
                      <ChatIcon
                        color={
                          tabName === text.route ||
                          tabName.substring(0, 8) === text.route
                            ? "#E24020"
                            : "#5F6165"
                        }
                      />
                    )}
                    <Typography
                      variant="sideNavFont"
                      style={{
                        paddingLeft: "20px",
                        color:
                          tabName === text.route ||
                          tabName.substring(0, 8) === text.route
                            ? "#E24020"
                            : "#5F6165",
                      }}
                    >
                      {text.name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ) : null
            ) : (
              <ListItem
                key={index}
                disablePadding
                style={{
                  backgroundColor:
                    tabName === text.route ||
                    tabName.substring(0, 8) === text.route
                      ? "rgba(226, 64, 32, 0.1)"
                      : "white",
                  marginLeft: "10px",
                  width: "90%",
                  borderRadius: "6px",
                }}
              >
                <ListItemButton
                  component={Link}
                  to={text.route}
                  onClick={() => {
                    setTabName(text.route);
                  }}
                >
                  {text.route === "/clinicUser/dashboard" && (
                    <DashboardIcon
                      color={
                        tabName === text.route ||
                        tabName.substring(0, 8) === text.route
                          ? "#E24020"
                          : "#5F6165"
                      }
                    />
                  )}
                  {text.route === "/clinicUser/users" && (
                    <TherapistsIcon
                      color={
                        tabName === text.route ||
                        tabName.substring(0, 8) === text.route
                          ? "#E24020"
                          : "#5F6165"
                      }
                    />
                  )}
                  {text.route === "/clinicUser/patients" &&
                    (tabName === text.route ||
                    tabName.substring(0, 8) === text.route ? (
                      <PatientRedIcon />
                    ) : (
                      <PatientGreyIcon />
                    ))}
                  {text.route === "/clinicUser/exercise" && (
                    <ExerciseIcon
                      color={
                        tabName === text.route ||
                        tabName.substring(0, 8) === text.route
                          ? "#E24020"
                          : "#5F6165"
                      }
                    />
                  )}
                  {text.route === "/clinicUser/chat" && (
                    <ChatIcon
                      color={
                        tabName === text.route ||
                        tabName.substring(0, 8) === text.route
                          ? "#E24020"
                          : "#5F6165"
                      }
                    />
                  )}
                  <Typography
                    variant="sideNavFont"
                    style={{
                      paddingLeft: "20px",
                      color:
                        tabName === text.route ||
                        tabName.substring(0, 8) === text.route
                          ? "#E24020"
                          : "#5F6165",
                    }}
                  >
                    {text.name}
                  </Typography>
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>

        <List style={{ position: "absolute", bottom: 0, width: "90%" }}>
          <Chip
            size="small"
            style={{
              marginLeft: "10px",
              backgroundColor: "#edede9",
              marginBottom: "15px",
            }}
            label={`Web v.${process.env.REACT_APP_WEB_VERSION}`}
          />
          {bottomListItems.map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              style={{
                backgroundColor:
                  tabName === text.route ? "rgba(226, 64, 32, 0.1)" : "white",
                marginLeft: "10px",
                width: "100%",
                borderRadius: "6px",
              }}
            >
              <ListItemButton
                component={Link}
                to={text.route}
                onClick={() => {
                  if (text.route === "/") {
                    localStorage.clear();
                    navigate("/");
                  }
                  setTabName(text.route);
                }}
              >
                {text.route === "/settings" && (
                  <SettingsIcon
                    color={tabName === text.route ? "#E24020" : "#5F6165"}
                  />
                )}
                {text.route === "/" && (
                  <LogoutIcon
                    color={tabName === text.route ? "#E24020" : "#5F6165"}
                  />
                )}
                <Typography
                  variant="sideNavFont"
                  style={{
                    paddingLeft: "20px",
                    color: tabName === text.route ? "#E24020" : "#5F6165",
                  }}
                >
                  {text.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      ></Box>
    </Box>
  );
}
