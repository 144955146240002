import { Button, Checkbox, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAdminDetails,
  getClinicInfo,
  updateClinicChatDisable,
  updateClinicInfo,
} from "../../Api/Api";
import { getAccessToken, getAdminId } from "../../Auth/Auth";
import {
  BackArrow,
  CheckBoxIcon,
  CheckboxBorder,
  GmailIcon,
  MobileIcon,
} from "../../Icons/Icons";
import { CustomDivider } from "../../InputComponents/CustomDivider";
import IconTextInput from "../../InputComponents/IconTextInput";
import { TextInput } from "../../InputComponents/TextInput";
import { helperText, mainDivStyle, SubmitButton } from "../../Theme/Theme";
import SideNav from "../../UIComponents/SideNav";
import { SwitchButton } from "../../InputComponents/SwitchButton";

export function ClinicDetails(props) {
  const id = useParams().clinicId;
  const type = useParams().type;
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState();
  const [phone, setPhone] = useState();
  const [clinicName, setClinicName] = useState();
  const [disable, setDisable] = useState(true);
  const [adminName, setAdminName] = useState("");
  const [chatDisable, setChatDisable] = useState();

  const [nameError, setNameError] = useState({ status: false, msg: "" });
  const [locationError, setLocationError] = useState({
    status: false,
    msg: "",
  });
  const [clinicTypeError, setClinicTypeError] = useState({
    status: false,
    msg: "",
  });
  const [phoneError, setPhoneError] = useState({ status: false, msg: "" });

  const [data, setData] = useState({
    name: "",
    date: "",
    total: "",
    location: "",
    email: "",
    phone: "",
    img: "",
  });

  const [selectedClinicTypes, setSelectedClinicType] = useState([]);

  const clinicTypes = [
    {
      name: "Exercise",
      code: "E",
    },
    {
      name: "Medication",
      code: "M",
    },
    {
      name: "Diet",
      code: "D",
    },
    {
      name: "Vitals",
      code: "V",
    },
    {
      name: "Symptoms",
      code: "S",
    },
  ];

  const update = (value, input, k) => {
    setDisable(false);
    if (input === "name") {
      setClinicName(value);
      setNameError({ status: false, msg: "" });
    }
    if (input === "location") {
      setLocation(value);
      setLocationError({ status: false, msg: "" });
    }
    if (input === "phone") {
      let no = value.toLowerCase();
      if (no.startsWith("-")) {
        return;
      }
      if (no.includes("--")) {
        return;
      }
      if (/^[0-9-]+$/i.test(no) === false && no !== "") {
        return;
      }
      if (
        no.length === 3 &&
        k.nativeEvent.inputType !== "deleteContentBackward"
      ) {
        no = no + "-";
      }
      if (
        no.length === 7 &&
        k.nativeEvent.inputType !== "deleteContentBackward"
      ) {
        no = no + "-";
      }
      if (no.length > 12) {
        return;
      }
      setPhone(no);
      setPhoneError({ status: false, msg: "" });
    }
  };

  const readClinicType = (code) => {
    setDisable(false);
    setClinicTypeError({ status: false, msg: "" });
    if (selectedClinicTypes.includes(code)) {
      const newSelectedClinicTypes = selectedClinicTypes.filter(
        (t) => t !== code
      );
      setSelectedClinicType(newSelectedClinicTypes);
    } else {
      setSelectedClinicType([...selectedClinicTypes, code]);
    }
  };

  const updateClinic = async () => {
    console.log(selectedClinicTypes);
    let error = false;
    if (clinicName === "" || clinicName === null || clinicName === undefined) {
      setNameError({ status: true, msg: "Clinic Name is reqiured" });
      error = true;
    }
    if (location === "" || location === null || location === undefined) {
      setLocationError({ status: true, msg: "Location is reqiured" });
      error = true;
    }
    if (phone === "" || phone === null || phone === undefined) {
      setPhoneError({ status: true, msg: "Phone number is reqiured" });
      error = true;
    }
    if (selectedClinicTypes.length === 0) {
      setClinicTypeError({
        status: true,
        msg: "At least one record type is Required.",
      });
      error = true;
    }
    if (error) {
      return;
    }
    const token = getAccessToken();
    const obj = {
      clinicName: clinicName,
      contactNO: phone,
      location: location,
      clinicTypes: selectedClinicTypes,
    };
    const updatedClinic = await updateClinicInfo(obj, token, id);
    if (updatedClinic.message === "Success") {
      toast.success("Clinic details updated successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
      setDisable(true);
    } else {
      toast.error(updatedClinic.message);
    }
  };

  const toggleClinicChatDisable = async () => {
    const token = getAccessToken();
    const result = await updateClinicChatDisable(token, !chatDisable, id);
    if (result.message === "Success") {
      toast.success("Clinic details updated successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
      setChatDisable(!chatDisable);
    } else {
      toast.error(result.message);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    const adminId = getAdminId();
    const getClinicDetails = async () => {
      const clinicData = await getClinicInfo(id, token);
      if (clinicData.content !== undefined) {
        const obj = {
          name: clinicData.content.clinicName,
          date: clinicData.content.joinedDate,
          total: clinicData.content.patients,
          location: clinicData.content.location,
          email: clinicData.content.email,
          phone: clinicData.content.contactNO,
          img: clinicData.content.profilePicURL,
          chatDisabled: clinicData.content.chatDisabled,
        };
        setData(obj);
        setLocation(clinicData.content.location);
        setPhone(clinicData.content.contactNO);
        setClinicName(clinicData.content.clinicName);
        setSelectedClinicType(clinicData.content.clinicTypes);
        setChatDisable(obj.chatDisabled);
        setLoading(false);
      }
    };
    const getAdminInfo = async () => {
      const admin = await getAdminDetails(token, adminId);
      if (admin.content !== undefined) {
        setAdminName(admin.content.firstName + " " + admin.content.lastName);
      }
    };
    getClinicDetails();
    getAdminInfo();
  }, [id]);
  return (
    <>
      <SideNav />
      <div style={mainDivStyle}>
        <Grid
          container
          style={{
            paddingTop: "20px",
            backgroundColor: "white",
            marginTop: "40px",
            marginLeft: "20px",
            marginRight: "20px",
            width: "96%",
            marginBottom: "20px",
            paddingBottom: "30px",
          }}
        >
          <Grid item xs={12} md={12}>
            <IconButton
              component={Link}
              to={`/clinics`}
              style={{
                marginLeft: "30px",
                marginRight: "10px",
                paddingTop: "5px",
              }}
            >
              <BackArrow />
            </IconButton>
            <Typography variant="viewAndUpdateHeaderFont">
              {type === "view" ? "View Clinic Details" : "Edit Clinic Profile"}
            </Typography>
            <CustomDivider width="95%" mb="30px" mt="20px" ml="30px" />
          </Grid>

          {loading === true ? (
            <Grid
              item
              xs={12}
              md={12}
              style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
            >
              <Typography>No data</Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={10} style={{ paddingLeft: "30px" }}>
                <Typography
                  variant="viewClinicNameFont"
                  hidden={type === "edit" && true}
                >
                  {data.name}
                </Typography>
                <Grid container style={{ paddingBottom: "20px" }}>
                  <Grid item xs={6} md={2}>
                    <img
                      alt="profile"
                      src={
                        data.img === ""
                          ? require("../../Images/defaultClinicLogo.png")
                          : data.img
                      }
                      style={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "100%",
                        maxWidth: "200px",
                        minWidth: "100px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    style={{
                      textAlign: "left",
                      paddingLeft: "30px",
                      paddingTop: "3%",
                    }}
                    hidden={type === "edit" && true}
                  >
                    <Typography variant="subtitle1">{adminName}</Typography>
                    <Typography variant="dateFont">
                      Joined Date: {data.date.substring(0, 10)}
                    </Typography>
                    <Typography color="primary" variant="subtitle1">
                      Total Number of Patients - {data.total.length}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {type === "view" && (
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ textAlign: "right", paddingRight: "2%" }}
                >
                  <Button
                    component={Link}
                    to={`/clinics/${id}/summary`}
                    variant="contained"
                    size="small"
                    style={{
                      boxShadow: "none",
                      textTransform: "none",
                      marginLeft: "30px",
                    }}
                  >
                    View Progress
                  </Button>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  marginTop: "40px",
                  paddingLeft: "30px",
                  paddingBottom: "40px",
                }}
              >
                <span hidden={type === "view" && true}>
                  <Typography variant="subtitle1">Clinic Name</Typography>
                  <Typography style={helperText}>{nameError.msg}</Typography>
                  <TextInput
                    error={nameError.status}
                    style={{ width: "90%", marginBottom: "20px" }}
                    value={clinicName}
                    onChange={(e) => update(e.target.value, "name")}
                    hidden={type === "view" && true}
                  />
                </span>

                <Typography variant="subtitle1">Location</Typography>
                {type === "view" ? (
                  <span>
                    <Typography variant="detailValueFont">
                      {location}
                    </Typography>
                    <div style={{ paddingBottom: "20px" }}></div>
                  </span>
                ) : (
                  <>
                    <Typography style={helperText}>
                      {locationError.msg}
                    </Typography>
                    <TextInput
                      error={locationError.status}
                      style={{ width: "90%", marginBottom: "20px" }}
                      value={location}
                      onChange={(e) => update(e.target.value, "location")}
                    />
                  </>
                )}

                <Typography variant="subtitle1">
                  Reporting record types for the patient app
                </Typography>
                <Typography style={helperText}>
                  {clinicTypeError.msg}
                </Typography>
                {clinicTypes.map((t, i) => {
                  return (
                    <div key={i}>
                      <Checkbox
                        disabled={type === "view" && true}
                        icon={
                          selectedClinicTypes.includes(t.code) ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckboxBorder />
                          )
                        }
                        style={{ paddingLeft: "0px" }}
                        size="small"
                        onChange={() => readClinicType(t.code)}
                        checked={
                          selectedClinicTypes.includes(t.code) ? true : false
                        }
                      />
                      <Typography
                        variant="subtitle2"
                        style={{
                          display: "inline",
                          color: "#676767",
                        }}
                      >
                        {t.name}
                      </Typography>
                      <br />
                    </div>
                  );
                })}
                <Grid container style={{ paddingTop: "10px" }}>
                  <Grid item sm={6} xs={4} md={4} lg={4}>
                    <Typography variant="subtitle1">
                      Enable Chat Functionality in mobile apps
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={8} md={8} lg={8}>
                    <SwitchButton
                      disabled={type === "view" && true}
                      disableChat={chatDisable}
                      readChatDisable={() => toggleClinicChatDisable()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ paddingLeft: "30px" }}>
                <Grid item xs={6} md={2}>
                  <Typography
                    variant="subtitle1"
                    style={{ paddingBottom: "20px", display: "inline" }}
                  >
                    Contact Details
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <CustomDivider
                    width="98%"
                    mb="20px"
                    mt="14px"
                    style={{ display: "inline" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ paddingBottom: "10px" }}
                  >
                    Email Address
                  </Typography>

                  {type === "view" ? (
                    <>
                      <GmailIcon />
                      <Typography
                        variant="detailValueFont"
                        style={{
                          display: "inline",
                          marginLeft: "10px",
                        }}
                      >
                        {data.email}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography style={helperText}>{""}</Typography>
                      <IconTextInput
                        icon={<GmailIcon />}
                        width="80%"
                        disabled={true}
                        value={data.email}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ paddingBottom: "10px" }}
                  >
                    Phone Number
                  </Typography>
                  {type === "view" ? (
                    <>
                      <MobileIcon />
                      <Typography
                        variant="detailValueFont"
                        style={{
                          display: "inline",
                          marginLeft: "10px",
                        }}
                      >
                        {data.phone}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography style={helperText}>
                        {phoneError.msg}
                      </Typography>
                      <IconTextInput
                        error={phoneError.status}
                        icon={<MobileIcon error={phoneError.status} />}
                        width="80%"
                        disabled={false}
                        value={phone}
                        types="phone"
                        type="text"
                        onChange={(e, t, k) => update(e, t, k)}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={6} md={8}></Grid>
                {type === "edit" && (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    style={{
                      textAlign: "right",
                      paddingRight: "20px",
                      paddingTop: "30px",
                    }}
                  >
                    <Button
                      color="customGray"
                      style={{ textTransform: "none" }}
                      component={Link}
                      to="/clinics"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={SubmitButton}
                      disabled={disable}
                      onClick={updateClinic}
                    >
                      Update Profile
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
}
