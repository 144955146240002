import React from "react";
import { Box, CardContent, Grid, IconButton, Modal, Typography } from "@mui/material";
import { BloodPressIcon, BloodSugarIcon, BodyWeighIcon, DateIcon, HourGlassIcon, RecordCloseIcon } from "../Icons/Icons";
import { dateFormatter, getRecordColor } from "../Utils/Helpers";
import { CLINIC_TYPES, } from "../Utils/Enums";
import PillImage from '../Images/Pills.png';
import WeightLift from "../Images/WeightLift.png";

function MedicationRecord({ record, onClose }) {
    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
            >
                <IconButton style={{padding:"0px"}} onClick={onClose}>
                    <RecordCloseIcon iconColor={getRecordColor(record.type)} />
                </IconButton>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"15px"} >
                Medication
                <Typography variant="body2" color="#72767C"><DateIcon iconColor={getRecordColor(record.type)}/> {dateFormatter(record.uploadedDate)}</Typography>
            </Typography>
            <Grid container spacing={{ xs: 2, md: 1}} columns={{ xs: 4, sm: 8, md: 12 }}>
                {record.medications.map((item, index) => (
                    <Grid key={index} item md>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                        >
                            <img alt="pillImage" src={PillImage} width={"20px"} height={"20px"}/>
                            <Typography marginLeft={"2px"} marginTop={"1px"}>{item.name}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

function VitalsRecord({ record, onClose}) {
    function VitalCard({title, value, VitalIcon, measureValue}) {
        return (
            <Grid container direction="row" alignItems="center">
                <VitalIcon />
                <CardContent>
                    <Typography marginTop={"10px"} fontWeight="600" variant="body2">{title}</Typography>
                    <Typography variant="caption">{`${value ? value : 0} ${measureValue}`}</Typography>
                </CardContent>
            </Grid>
        );
    }

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
            >
                <IconButton style={{padding:"0px"}} onClick={onClose}>
                    <RecordCloseIcon iconColor={getRecordColor(record.type)} />
                </IconButton>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"15px"} >
                Vitals
                <Typography variant="body2" color="#72767C"><DateIcon iconColor={getRecordColor(record.type)}/> {dateFormatter(record.uploadedDate)}</Typography>
            </Typography>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={4}>
                    <VitalCard title={"Blood Sugar"} value={record.bloodSugar} VitalIcon={BloodSugarIcon} measureValue={"mg/dl"}/>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <VitalCard title={"Blood Pressure"} value={record.bloodPressure} VitalIcon={BloodPressIcon} measureValue={"mm Hg"}/>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <VitalCard title={"Body Weight"} value={record.bodyWeight} VitalIcon={BodyWeighIcon} measureValue={"lbs"}/>
                </Grid>
            </Grid>
        </div>
    );
}

function DietRecord({ record, onClose }) {
    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
            >
                <IconButton style={{padding:"0px"}} onClick={onClose}>
                    <RecordCloseIcon iconColor={getRecordColor(record.type)} />
                </IconButton>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"15px"} >
                Diet
                <Typography variant="body2" color="#72767C"><DateIcon iconColor={getRecordColor(record.type)}/> {dateFormatter(record.uploadedDate)}</Typography>
            </Typography>
            <Grid container padding={"10px"}>
                <Typography fontWeight="500" noWrap={true} variant="body2">{record.dietDescription}</Typography>
            </Grid>
        </div>
    );
}

function ExerciseRecord({ record, onClose, assignedTutorials }) {

    function ExerciseNote() {
        return <>
            {
                record.noteTutorialList?.length > 0 && <Grid container>
                    <Typography fontWeight="600">Covered Exercises</Typography>
                    <Grid container marginBottom={"15px"}>
                        {
                            assignedTutorials.map((item, index) =>{
                                return record.noteTutorialList && record.noteTutorialList?.includes(item.id) &&
                                    <Grid key={index} container  alignItems={"center"}>
                                        <img alt="weightLift" src={WeightLift} width={"15px"} height={"15px"}/>
                                        <Typography key={index} fontWeight="500" variant="caption" marginLeft={"5px"}>{item.tutorialTitle}</Typography>
                                    </Grid>
                            })
                        }
                    </Grid>
                </Grid>
            }
            {
                record.exercise && <Grid container direction="row">
                    <Typography fontWeight="600">Note</Typography>
                    <Grid container>
                        <Typography fontWeight="500" variant="caption">{record.exercise}</Typography>
                    </Grid>
                </Grid>
            }
        </>
    }

    function ExerciseVideo(params) {
        const video = assignedTutorials.find(video => {return record.noteTutorialList?.includes(video.id) && video.videoURL});

        return <Grid 
            container 
            direction="row" 
            alignItems={"center"} 
            justifyContent={"center"} 
            style={{
                backgroundColor:"#FFF5F5",
                borderRadius:"10px"
            }}>
                <Grid
                    item
                    xs={7}
                    md={7}
                    lg={7}
                    xl={7}
                    style={{
                        width: "500px",
                        height: "220px",
                        backgroundColor: "rgba(0,0,0,0)",
                        textAlign: "center",
                        padding:"20px",
                    }}
                    >
                    {video?.videoURL.includes("https://www.youtube.com/") ? (
                        <iframe
                        width="100%"
                        height="180px"
                        src={video?.videoURL}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        ></iframe>
                    ) : (
                        <video width="100%" controls>
                            <source src={video?.videoURL} type="video/mp4" />
                        </video>
                    )}
                </Grid>
                <Grid 
                    item xs={5} 
                    alignItems="center"
                    justifyContent="center"
                    style={{
                        paddingLeft:"20px"
                    }}>
                        <Typography variant="subtitle1">Feedback</Typography>
                        <Typography variant="subtitle2" color="#D44C31">{record.feedback}</Typography>
                </Grid>
        </Grid>
    }

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
            >
                <IconButton style={{padding:"0px"}} onClick={onClose}>
                    <RecordCloseIcon iconColor={getRecordColor(record.type)} />
                </IconButton>
            </Grid>
            <Grid container justifyContent={"space-between"}>
                <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"15px"} >
                    Exercise
                    <Typography variant="body2" color="#72767C"><DateIcon iconColor={getRecordColor(record.type)}/> {dateFormatter(record.uploadedDate)}</Typography>
                </Typography>
                <Typography variant="body2" fontWeight="500" color="#E24020" marginTop="5px"><HourGlassIcon/> {record.durationInMinutes.toFixed(2)} Minutes</Typography>
            </Grid>
            {
                record && record.feedback ? <ExerciseVideo/> : <ExerciseNote/>
            }
        </div>
    );
}

function SymptomsRecord({ record, onClose }) {
    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
            >
                <IconButton style={{padding:"0px"}} onClick={onClose}>
                    <RecordCloseIcon iconColor={getRecordColor(record.type)} />
                </IconButton>
            </Grid>
            <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"15px"} >
                Symptoms
                <Typography variant="body2" color="#72767C"><DateIcon iconColor={getRecordColor(record.type)}/> {dateFormatter(record.uploadedDate)}</Typography>
            </Typography>
            <Grid container padding={"10px"}>
                <Typography fontWeight="400" variant="body2">{record.symptomDescription}</Typography>
            </Grid>
        </div>
    );
}

function RecordPopup({ isModalOpen, recordData, type, onClose, assignedTutorials }) {
    function renderView() {
        switch (type) {
            case CLINIC_TYPES.EXERCISE.label:
                return <ExerciseRecord record={recordData} onClose={onClose} assignedTutorials={assignedTutorials}/>;
            case CLINIC_TYPES.VITALS.label:
                return <VitalsRecord record={recordData} onClose={onClose}/>;
            case CLINIC_TYPES.DIET.label:
                return <DietRecord record={recordData} onClose={onClose}/>;
            case CLINIC_TYPES.MEDICATION.label:
                return <MedicationRecord record={recordData} onClose={onClose}/>;
            case CLINIC_TYPES.SYMPTOMS.label:
                return <SymptomsRecord record={recordData} onClose={onClose}/>;
            default:
                return <></>;
        }
    }

    return (
        <Modal open={isModalOpen }onClose={onClose}>
            <Box 
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'background.paper',
                    borderTop: `5px solid ${getRecordColor(recordData?.type)}`,
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom:"20px",
                    paddingTop:"5px",
                    width:"700px"
                }}
            >
                {renderView()}
            </Box>
        </Modal>
    );
}

export default RecordPopup;