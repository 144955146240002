import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Calender } from "../Icons/Icons";

export default function CustomDatePicker(props) {
  //const [value, setValue] = React.useState(new Date());
  const aYearBeforeNow = new Date();
  aYearBeforeNow.setFullYear(aYearBeforeNow.getFullYear(), 0, 1);

  const aYearAfterNow = new Date();
  aYearAfterNow.setFullYear(aYearAfterNow.getFullYear() + 7);

  const poppersx = {
    "& .PrivatePickersYear-yearButton": {
      color: "black",
      "&.Mui-selected, &.Mui-selected:hover,&.Mui-selected:focus": {
        color: "white",
        backgroundColor: "#E24020",
      },
      "&:hover fieldset": {
        backgroundColor: "#E24020",
      },
    },
    "& .PrivatePickersMonth-root": {
      color: "black",
      "&.Mui-selected, &.Mui-selected:hover,&.Mui-selected:focus": {
        color: "white",
        backgroundColor: "#E24020",
      },
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month"]}
        value={props.displayDate}
        onChange={(newValue) => props.onDateChange(newValue)}
        components={{
          OpenPickerIcon: Calender,
        }}
        minDate={aYearBeforeNow}
        maxDate={aYearAfterNow}
        PopperProps={{ sx: poppersx }}
        renderInput={(params) => <CssTextField size="small" {...params} />}
      />
    </LocalizationProvider>
  );
}

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    borderColor: "#FFFFFF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
    },
  },
});
