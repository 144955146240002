import { Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CenterGrid, helperText } from "../../Theme/Theme";
import PasswordInput from "../../InputComponents/PasswordInput";
import { passwordReset } from "../../Api/Api";
import { toast } from "react-toastify";

export function ResetPassword(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [password, setPasssword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPassswordError] = useState({
    status: false,
    msg: "",
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    status: false,
    msg: "",
  });

  const readPassword = (e) => {
    setPassswordError({ status: false, msg: "" });
    setPasssword(e);
  };
  const readConfirmPassword = (e) => {
    setConfirmPasswordError({ status: false, msg: "" });
    setConfirmPassword(e);
  };

  const submit = async () => {
    let err = false;
    if (password === "") {
      setPassswordError({ status: true, msg: "Password is Required." });
      err = true;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError({
        status: true,
        msg: "Confirm password is Required.",
      });
      err = true;
    }
    if (password !== "" && password.length < 8) {
      setPassswordError({
        status: true,
        msg: "Password should be at least 8 characters long.",
      });
      err = true;
    }
    if (password.length >= 8 && password !== confirmPassword) {
      setPassswordError({
        status: true,
        msg: "Passwords don't match.",
      });
      setConfirmPasswordError({
        status: true,
        msg: "Passwords don't match.",
      });
      err = true;
    }
    if (err) {
      return;
    }

    const data = {
      userId: params.userid,
      key: params.keyId,
      password: password,
      confirmPassword: confirmPassword,
    };

    const result = await passwordReset(data);
    if (result === 200) {
      localStorage.clear();
      navigate("/");
    }
    if (result !== 200) {
      toast.error(result.message);
    }
  };
  return (
    <div style={CenterGrid}>
      <Grid container>
        <Grid item xs={1} md={4}></Grid>
        <Grid item xs={10} md={4} style={{ textAlign: "center" }}>
          <Grid container style={{ paddingTop: "30%" }}>
            <Grid item xs={12} md={12}>
              <img
                style={{ width: "110px" }}
                src={require("../../Images/logo.png")}
                alt="logo"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                textAlign: "left",
                border: "1px solid #F4F5F6",
                boxShadow: "0px 10px 110px 1px rgba(59, 59, 59, 0.08)",
                marginTop: "20px",
                paddingTop: "20px",
                padding: "15px",
                borderRadius: "10px",
                backgroundColor: "#FFF",
              }}
            >
              <Typography variant="subtitle2">Password</Typography>
              <PasswordInput
                error={passwordError.status}
                onChange={(e) => readPassword(e)}
              />
              <Typography style={helperText}>{passwordError.msg}</Typography>
              <Typography variant="subtitle2">Confirm password</Typography>
              <PasswordInput
                error={confirmPasswordError.status}
                onChange={(e) => readConfirmPassword(e)}
              />
              <Typography style={helperText}>
                {confirmPasswordError.msg}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
              <Button
                style={{
                  width: "100%",
                  boxShadow: "none",
                  marginTop: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                color="primary"
                onClick={submit}
              >
                Update
              </Button>

              <Typography
                variant="subtitle2"
                style={{
                  color: "#777E91",
                  paddingTop: "10px",
                  paddingBottom: "30px",
                }}
              >
                <a href="/" style={{ textDecoration: "none" }}>
                  <span style={{ color: "#E24020", cursor: "pointer" }}>
                    Back to Login
                  </span>
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
