import { Pagination, PaginationItem } from "@mui/material";
import React from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "../Icons/Icons";

export function CustomPagination(props) {
  return (
    <>
      <Pagination
        style={{ margin: "auto" }}
        count={props.maxPages}
        shape="rounded"
        color="primary"
        onChange={(e, n) => props.paginationFilter(n)}
        size="small"
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: ArrowBackIcon,
              next: ArrowForwardIcon,
            }}
            {...item}
          />
        )}
      />
    </>
  );
}
