import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createNewClinic,
  deleteClinicById,
  getClinicDetails,
} from "../../Api/Api";
import { getAccessToken, getAdminId } from "../../Auth/Auth";
import { mainDivStyle } from "../../Theme/Theme";
import ClinicTable from "../../UIComponents/ClinicTable";
import SideNav from "../../UIComponents/SideNav";
import "react-toastify/dist/ReactToastify.css";
import AlertForm from "../../UIComponents/AlertForm";

export function Clinics(props) {
  const [clinicDetails, setClinicDetails] = useState([]);
  const [constClinicDetails, setConstClinicDetails] = useState([]);

  const [clinicName, setClinicName] = useState("");
  const [clinicLocation, setClinicLocation] = useState("");
  const [clinicContact, setClinicContact] = useState("");
  const [clinicEmail, setClincEmail] = useState("");
  const [selectedClinicTypes, setSelectedClinicType] = useState([]);
  const [nameError, setNameError] = useState({ status: false, msg: "" });
  const [locationError, setLocationError] = useState({
    status: false,
    msg: "",
  });
  const [contactError, setContactError] = useState({ status: false, msg: "" });
  const [emailError, setEmailError] = useState({ status: false, msg: "" });
  const [clinicTypeError, setClinicTypeError] = useState({
    status: false,
    msg: "",
  });
  const [disableChat, setDisableChat] = useState(false);
  const [open, setOpen] = useState(false);

  const clinicTypes = [
    {
      name: "Exercise",
      code: "E",
    },
    {
      name: "Medication",
      code: "M",
    },
    {
      name: "Diet",
      code: "D",
    },
    {
      name: "Vitals",
      code: "V",
    },
    {
      name: "Symptoms",
      code: "S",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setClinicName("");
    setClinicLocation("");
    setClinicContact("");
    setClincEmail("");
    setSelectedClinicType([]);
    setNameError({ status: false, msg: "" });
    setLocationError({ status: false, msg: "" });
    setContactError({ status: false, msg: "" });
    setEmailError({ status: false, msg: "" });
    setClinicTypeError({ status: false, msg: "" });
    setDisableChat(false);
    setOpen(false);
  };

  //read input data
  const readClinic = (e) => {
    setNameError({ status: false, msg: "" });
    setClinicName(e);
  };
  const readLocation = (e) => {
    setLocationError({ status: false, msg: "" });
    setClinicLocation(e);
  };
  const readContact = (e, k) => {
    let no = e.toLowerCase();
    if (no.startsWith("-")) {
      return;
    }
    if (no.includes("--")) {
      return;
    }
    if (/^[0-9-]+$/i.test(no) === false && no !== "") {
      return;
    }
    if (
      no.length === 3 &&
      k.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      no = no + "-";
    }
    if (
      no.length === 7 &&
      k.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      no = no + "-";
    }
    if (no.length > 12) {
      return;
    }

    setContactError({ status: false, msg: "" });
    setClinicContact(no);
  };
  const readEmail = (e) => {
    setEmailError({ status: false, msg: "" });
    setClincEmail(e);
  };

  const readClinicType = (code) => {
    setClinicTypeError({ status: false, msg: "" });
    if (selectedClinicTypes.includes(code)) {
      const newSelectedClinicTypes = selectedClinicTypes.filter(
        (t) => t !== code
      );
      setSelectedClinicType(newSelectedClinicTypes);
    } else {
      setSelectedClinicType([...selectedClinicTypes, code]);
    }
  };
  const toggleChatDisability = () => {
    setDisableChat(!disableChat);
  };

  const addNewClinic = async () => {
    let error = false;
    if (clinicName === "") {
      setNameError({ status: true, msg: "Clinic Name is Required." });
      error = true;
    }
    if (clinicLocation === "") {
      setLocationError({ status: true, msg: "Location is Required." });
      error = true;
    }
    if (clinicContact === "") {
      setContactError({ status: true, msg: "Contact No is Required." });
      error = true;
    }
    if (clinicContact !== "" && clinicContact.length < 10) {
      setContactError({ status: true, msg: "Invalid Contact No." });
      error = true;
    }
    if (clinicEmail === "") {
      setEmailError({ status: true, msg: "Email is Required." });
      error = true;
    }
    if (clinicEmail !== "" && clinicEmail.indexOf("@") === -1) {
      setEmailError({ status: true, msg: "Invalid Email." });
      error = true;
    }
    if (selectedClinicTypes.length === 0) {
      setClinicTypeError({
        status: true,
        msg: "At least one record type is Required.",
      });
    }

    if (error) {
      return;
    }

    const token = getAccessToken();
    const adminId = getAdminId();
    const data = {
      adminId: adminId,
      clinicName: clinicName,
      profilePicURL: "",
      location: clinicLocation,
      contactNO: clinicContact,
      patients: [],
      email: clinicEmail,
      clinicTypes: selectedClinicTypes,
      chatDisabled: disableChat,
    };
    const result = await createNewClinic(data, token);
    console.log(result);
    if (result.message === "Success") {
      toast.success("Clinic created successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
      const clinicSummary = await getClinicDetails(token, adminId);
      setClinicDetails(clinicSummary.content);
      setConstClinicDetails(clinicSummary.content);
      setClinicName("");
      setClinicLocation("");
      setClinicContact("");
      setClincEmail("");
      setSelectedClinicType([]);
      setNameError({ status: false, msg: "" });
      setLocationError({ status: false, msg: "" });
      setContactError({ status: false, msg: "" });
      setEmailError({ status: false, msg: "" });
      setClinicTypeError({ status: false, msg: "" });
      setOpen(false);
    } else {
      toast.error(result.message);
      setOpen(true);
    }
  };

  const deleteClinic = async (token, clinicId) => {
    const response = await deleteClinicById(token, clinicId);
    console.log(response);
    if (response.message === "Success") {
      let newClinics = [];
      clinicDetails.map((c) => {
        if (c.id !== clinicId) {
          newClinics.push(c);
        }
        return newClinics;
      });
      setClinicDetails(newClinics);
      setConstClinicDetails(newClinics);
      toast.success("Clinic deleted successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      toast.error(response.message);
    }
  };

  const filterClinic = (e) => {
    let filteredClinics = [];
    constClinicDetails.filter((obj) => {
      if (obj.clinicName.toLowerCase().includes(e.trim().toLowerCase())) {
        filteredClinics.push(obj);
      }
      return filteredClinics;
    });
    setClinicDetails(filteredClinics);
  };

  useEffect(() => {
    const token = getAccessToken();
    const adminId = getAdminId();
    const getClinicSummary = async () => {
      const clinicSummary = await getClinicDetails(token, adminId);
      if (clinicSummary === "Network Error") {
        toast.error(clinicSummary, { toastId: "id" });
      }
      setClinicDetails(clinicSummary.content);
      setConstClinicDetails(clinicSummary.content);
    };

    getClinicSummary();
  }, []);

  return (
    <>
      <SideNav filterFunction={(e) => filterClinic(e)} />
      <div style={mainDivStyle}>
        <Grid container style={{ paddingTop: "30px" }}>
          <Grid item xs={6} md={9}></Grid>
          <Grid
            item
            xs={6}
            md={3}
            style={{ textAlign: "right", paddingRight: "10px" }}
          >
            <AlertForm
              title={"New Clinic"}
              clinicTypes={clinicTypes}
              handleClickOpen={() => handleClickOpen()}
              handleClose={() => handleClose()}
              open={open}
              name={clinicName}
              location={clinicLocation}
              contact={clinicContact}
              email={clinicEmail}
              selectedClinicTypes={selectedClinicTypes}
              nameError={nameError}
              locationError={locationError}
              contactError={contactError}
              emailError={emailError}
              clinicTypeError={clinicTypeError}
              disableChat={disableChat}
              readClinic={(e) => readClinic(e)}
              readLocation={(e) => readLocation(e)}
              readContact={(e, k) => readContact(e, k)}
              readEmail={(e) => readEmail(e)}
              readClinicType={(e) => readClinicType(e)}
              readChatDisable={() => toggleChatDisability()}
              addNewClinic={() => addNewClinic()}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingRight: "10px", paddingTop: "25px" }}
          >
            <ClinicTable
              clinicDetails={clinicDetails}
              deleteClinic={(token, clinicId) => deleteClinic(token, clinicId)}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
