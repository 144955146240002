import styled from "@emotion/styled";
import { Dialog, Fab, Grid } from "@mui/material";
import React from "react";
import { VideoPlayerCloseIcon } from "../Icons/Icons";
import { DialogBackground } from "../Theme/Theme";

export function VideoPlayer(props) {
  return (
    <CustomDialog open={props.open} style={DialogBackground}>
      <Grid container>
        <Grid
          item
          xs={11}
          md={11}
          lg={11}
          xl={11}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            textAlign: "right",
            minWidth: "510px",
          }}
        ></Grid>
        <div
          style={{
            textAlign: "right",
            width: "100%",
          }}
        >
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{
              boxShadow: "none",
              marginBottom: "10px",
            }}
            onClick={() => props.handleClose()}
          >
            <VideoPlayerCloseIcon />
          </Fab>
        </div>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={11}
          md={11}
          lg={11}
          xl={11}
          style={{
            minWidth: "480px",
            minHeight: "270px",
            backgroundColor: "rgba(0,0,0,0)",
            textAlign: "center",
          }}
        >
          {props.videoURL.includes("https://www.youtube.com/") ? (
            <iframe
              width="100%"
              height="315px"
              src={props.videoURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <video width="100%" controls>
              <source src={props.videoURL} type="video/mp4" />
            </video>
          )}
        </Grid>
      </Grid>
    </CustomDialog>
  );
}

export const CustomDialog = styled(Dialog)((theme) => ({
  "& .MuiPaper-root": {
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "none",
  },
}));
