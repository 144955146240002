import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.yLabel;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      ticks: {
        callback: function (value, index) {
          return value + "hrs";
        },
      },
    },
  },
};

const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export function BarChart(props) {
  console.log(props.progressData);
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#F67A60",
        barPercentage: 0.4,
      },
    ],
  });

  useEffect(() => {
    const keys = Object.keys(props.progressData);
    let values = [];
    keys.map((i) => {
      if (props.progressData[i] !== undefined) {
        values[i - 1] = props.progressData[i] / 60;
      } else {
        values[i - 1] = 0;
      }
      return values;
    });
    setData({
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: "#F67A60",
          barPercentage: 0.4,
        },
      ],
    });
  }, [props.progressData]);
  return <Bar options={options} data={data} />;
}
