import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
} from "../Theme/Theme";
import { CloseIcon } from "../Icons/Icons";
import { getAccessToken } from "../Auth/Auth";

export function DeleteTherapist(props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTherapist = () => {
    const token = getAccessToken();
    props.deleteTherapist(token, props.therapistId);
    handleClose();
  };

  return (
    <Fragment>
      <IconButton onClick={handleClickOpen}>{props.icon}</IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          {props.title}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle1"
            style={{ color: "rgba(113, 113, 113, 1)", fontWeight: 400 }}
          >
            {props.sentence2}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={handleClose}
          >
            No, Keep Therapist
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={SubmitButton}
            onClick={deleteTherapist}
            autoFocus
          >
            Yes, Delete Therapist
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
