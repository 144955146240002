import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PasswordInput from "../../InputComponents/PasswordInput";
import { TextInput } from "../../InputComponents/TextInput";
import { CenterGrid, helperText } from "../../Theme/Theme";
import "react-toastify/dist/ReactToastify.css";
import { verifyAdminAccount } from "../../Api/Api";
import { toast } from "react-toastify";

export function VerifyAccount() {
  const params = useParams();
  const navigator = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [password, setPasssword] = useState("");

  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: "",
  });
  const [secondNameError, setSecondNameError] = useState({
    status: false,
    msg: "",
  });

  const [passwordError, setPassswordError] = useState({
    status: false,
    msg: "",
  });

  const readFirstName = (e) => {
    setFirstNameError({ status: false, msg: "" });
    setFirstName(e);
  };
  const readSecondName = (e) => {
    setSecondNameError({ status: false, msg: "" });
    setSecondName(e);
  };
  const readPassword = (e) => {
    setPassswordError({ status: false, msg: "" });
    setPasssword(e);
  };

  const verifyAccount = async () => {
    let err = false;
    if (firstName === "") {
      setFirstNameError({ status: true, msg: "First Name is Required." });
      err = true;
    }
    if (secondName === "") {
      setSecondNameError({ status: true, msg: "Last Name is Required." });
      err = true;
    }
    if (password === "") {
      setPassswordError({ status: true, msg: "Password is Required." });
      err = true;
    }
    if (password !== "" && password.length < 8) {
      setPassswordError({
        status: true,
        msg: "Password should be at least 8 characters long.",
      });
      err = true;
    }
    if (err) {
      return;
    }
    let data = {
      firstName: firstName,
      lastName: secondName,
      password: password,
    };
    const result = await verifyAdminAccount(data, params.userid, params.keyId);
    console.log(result);
    if (result.message === "Success") {
      navigator("/");
    }
    if (result.message !== "Success") {
      toast.error(result.message);
    }
  };
  return (
    <div style={CenterGrid}>
      <Grid container>
        <Grid item xs={1} md={4}></Grid>
        <Grid item xs={10} md={4} style={{ textAlign: "center" }}>
          <Grid container style={{ paddingTop: "40px" }}>
            <Grid item xs={12} md={12}>
              <img
                src={require("../../Images/logo.png")}
                style={{ width: "110px" }}
                alt="logo"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{
                textAlign: "left",
                border: "1px solid #F4F5F6",
                boxShadow: "0px 10px 110px 1px rgba(59, 59, 59, 0.08)",
                marginTop: "20px",
                paddingTop: "20px",
                padding: "15px",
                borderRadius: "10px",
                backgroundColor: "#FFF",
              }}
            >
              <Typography variant="subtitle2">First Name</Typography>
              <TextInput
                error={firstNameError.status}
                onChange={(e) => readFirstName(e.target.value)}
                fullWidth
                style={{ marginTop: "5px", marginBottom: "10px" }}
              />
              <Typography style={helperText}>{firstNameError.msg}</Typography>
              <Typography variant="subtitle2">Last Name</Typography>
              <TextInput
                error={secondNameError.status}
                onChange={(e) => readSecondName(e.target.value)}
                fullWidth
                style={{ marginTop: "5px", marginBottom: "10px" }}
              />
              <Typography style={helperText}>{secondNameError.msg}</Typography>

              <Typography variant="subtitle2">Password</Typography>
              <PasswordInput
                error={passwordError.status}
                onChange={(e) => readPassword(e)}
              />
              <Typography style={helperText}>{passwordError.msg}</Typography>
            </Grid>

            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
              <Button
                // component={Link}
                // to="/dashboard"
                onClick={verifyAccount}
                style={{
                  width: "100%",
                  boxShadow: "none",
                  marginTop: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                color="primary"
              >
                Verify
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={4}></Grid>
      </Grid>
    </div>
  );
}
