import React, { useEffect } from "react";
import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import {
  FilterButton,
  FilterButtonNotSelected,
  mainDivStyle,
} from "../Theme/Theme";
import TherapistTable from "../UIComponents/TherapistTable";
import { LogOut, getAccessToken, getAdminId, getRole } from "../Auth/Auth";
import { getAllTherapists } from "../Api/Api";
import { useState } from "react";
import { Grid, Button, Popover, MenuItem } from "@mui/material";
import { IconButton as CustomizedButton } from "../InputComponents/IconButton";
import { AddtherapistForm } from "../UIComponents/AddNewTherapist";
import { SearchBar } from "../InputComponents/SearchBar";
import { FilterIcon } from "../Icons/Icons";
import {
  USER_TYPES_FOR_CLINIC_ADMIN,
  USER_TYPES_FOR_CLINIC_OWNER,
} from "../Utils/Enums";
import { toast } from "react-toastify";

export function ClinicAdminTherapists(props) {
  const [open, setOpen] = useState(false);
  const [constTherapist, setConstTherapist] = useState([]);
  const [therapitsDetails, setTherapistDetails] = useState([]);
  const [openType, setOpenType] = useState("");
  const [id, setId] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");

  const handleCli = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClo = () => {
    setAnchorEl(null);
  };

  const filterUsersByRole = (e) => {
    setSelectedFilter(e);
    if (e === "ALL") {
      setTherapistDetails([...constTherapist]);
    } else {
      setTherapistDetails(
        constTherapist.filter((usr) => usr.therapistsType === e)
      );
    }
  };

  const handleOpen = (id, t) => {
    setOpenType(t);
    setId(id);
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    setOpenType("");
    getAllTheapistsOfClinic();
  };

  const getAllTheapistsOfClinic = async () => {
    const token = getAccessToken();
    const clinicId = getAdminId();
    const therapists = await getAllTherapists(token, clinicId);

    if (therapists === "Network Error") {
      toast.error(therapists, { toastId: "id" });
    }

    if (therapists.content !== null) {
      if (parseInt(getRole()) === 1) {
        setTherapistDetails(
          therapists.content.sort((a, b) => {
            const dateA = new Date(a.joinedDate);
            const dateB = new Date(b.joinedDate);
            return dateB - dateA;
          })
        );
        setConstTherapist(therapists.content);
      }
      if (parseInt(getRole()) === 6) {
        setTherapistDetails(
          therapists.content
            .sort((a, b) => {
              const dateA = new Date(a.joinedDate);
              const dateB = new Date(b.joinedDate);
              return dateB - dateA;
            })
            .filter((t) => t.therapistsType !== "CLINIC_ADMIN")
        );
        setConstTherapist(
          therapists.content.filter((t) => t.therapistsType !== "CLINIC_ADMIN")
        );
      }
    }
  };

  const filterTherapists = (e) => {
    let filteredTherapists = [];

    constTherapist.filter((obj) => {
      if (
        obj.therapistName.toLowerCase().includes(e.trim().toLowerCase()) ||
        obj.therapistsType
          .toLowerCase()
          .replace(/_/g, "")
          .includes(e.trim().toLowerCase().replace(/ /g, ""))
      ) {
        filteredTherapists.push(obj);
      }
      return filteredTherapists;
    });
    setTherapistDetails(filteredTherapists);
  };

  useEffect(() => {
    if (parseInt(getRole()) === 5 || parseInt(getRole()) === 7) {
      LogOut();
    }
    getAllTheapistsOfClinic();
  }, []);
  return (
    <>
      <>
        <ClinicAdminSideNav />
        <div style={mainDivStyle}>
          <Grid container style={{ paddingTop: "30px" }}>
            <Grid item xs={6} md={4}>
              <SearchBar
                placeHolder={"Search user by name or role"}
                filterFunction={filterTherapists}
                bgColor={"#FFFFFF"}
              />
            </Grid>
            <Grid item md={5}>
              <Button
                onClick={handleCli}
                style={{
                  ...(selectedFilter === ""
                    ? FilterButtonNotSelected
                    : FilterButton),
                  ...{ marginRight: 0 },
                }}
                startIcon={
                  <FilterIcon
                    iconColor={selectedFilter === "" ? "#E24020" : "white"}
                  />
                }
              >
                Filter
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClo}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {parseInt(getRole()) === 1 &&
                  Object.keys(USER_TYPES_FOR_CLINIC_OWNER).map(
                    (item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => filterUsersByRole(item)}
                          style={{
                            backgroundColor:
                              selectedFilter === item && "#FCE8E4",
                          }}
                        >
                          {USER_TYPES_FOR_CLINIC_OWNER[item]}
                        </MenuItem>
                      );
                    }
                  )}
                {parseInt(getRole()) === 6 &&
                  Object.keys(USER_TYPES_FOR_CLINIC_ADMIN).map(
                    (item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => filterUsersByRole(item)}
                          style={{
                            backgroundColor:
                              selectedFilter === item && "#FCE8E4",
                          }}
                        >
                          {USER_TYPES_FOR_CLINIC_ADMIN[item]}
                        </MenuItem>
                      );
                    }
                  )}
              </Popover>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              style={{ textAlign: "right", paddingRight: "10px" }}
            >
              <CustomizedButton text="New User" onClick={() => setOpen(true)} />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingRight: "10px", paddingTop: "25px" }}
            >
              <TherapistTable
                therapistDetails={therapitsDetails}
                handleOpen={(id, t) => handleOpen(id, t)}
                handleClose={() => handleClose()}
              />
            </Grid>
          </Grid>

          <AddtherapistForm
            open={open}
            handleClose={() => handleClose()}
            type={openType}
            id={id}
          />
        </div>
      </>
    </>
  );
}
