import React, { useEffect, useRef, useState } from "react";
import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import { mainDivStyle } from "../Theme/Theme";
import {Grid, Typography, IconButton, ButtonBase, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { getPatientsChatList, messageReadRequest, sendMessage } from "../Api/Api";
import { LogOut, getAccessToken, getAdminId, getRole } from "../Auth/Auth";
import { convertTo12HourTime, getToday } from "../Utils/Helpers";
import _ from "underscore";
import { Months } from "../Utils/Enums";
import { SendIcon, TickIcon } from "../Icons/Icons";
import { CustomDivider } from "../InputComponents/CustomDivider";
import { SearchBar } from "../InputComponents/SearchBar";

function ChatBubble({ data }) {

    function getChatBubbleStyle() {
        if(data.position === "left"){
            return {
                borderTopRightRadius:"10px",
                borderBottomLeftRadius:"10px",
                borderBottomRightRadius:"10px"
            }
        }else{
            return {
                borderTopLeftRadius:"10px",
                borderBottomLeftRadius:"10px",
                borderBottomRightRadius:"10px"
            }
        }
        
    }

    function isMessageIsTooLong() {
        const textLength = data.text.length;
        if(textLength > 50){
            return true;
        }else{
            return false;
        }
    }

    return (
        <Grid
            item
            padding={"10px"} 
            justifyContent={"center"} 
            alignItems={"center"} 
            zeroMinWidth
            backgroundColor={data.position === "left" ? "#E24020" :"#e4e4e4"}
            width={isMessageIsTooLong() ? "30%" : null} 
            style={getChatBubbleStyle()}
        >
            <Typography  
                variant="body2" 
                style={{overflowWrap: 'break-word'}}
                color={data.position === "left" ?"#FFFFFF": "#000000"}>
                    {data.text}
            </Typography>
            <Grid container justifyContent={data.position === "right" ? "flex-end": null}>
                <Typography
                    variant="caption" 
                    color={data.position === "left" ?"#FFFFFF": "#000000"}>
                        {convertTo12HourTime(data.date.split(" ")[1])}
                </Typography>
            </Grid>
    </Grid>
    );
}

function ChatRow({ chat }) {
    return <Grid container justifyContent={chat.position === "right" ? "flex-end" : "flex-start" } padding={"10px"}>
        <ChatBubble data={chat}/>
    </Grid>
}

function PatientChat({ chatData, patientId, clinicId, patientName, patientProfileUrl, updateChatList }) {
    const token = getAccessToken();
    const messagesEndRef = useRef(null);
    const listRef = useRef(null);
    const [patientChatData, setPatientChatData]= useState({});
    const [filteredMessages, setFilterMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() =>{
        setPatientChatData(chatData);
    },[chatData])

    useEffect(() =>{
        const data = _.groupBy(patientChatData, function (el) {
            const dates = new Date(el.dateTime)
            return (dates.getFullYear() + '-') + ((dates.getMonth() !== 0 ? dates.getMonth() : 1 )+ '-') + (dates.getDate());
        });
        setFilterMessages(data);
    },[patientChatData]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
        setMessage("");
        setTimeout(() => setLoading(false), 3000);
    },[chatData]);

    useEffect(() => {
        async function msgReadRequest() {
            await messageReadRequest(patientId, "CLINIC", token);
        }
        
        if(chatData && Object.keys(chatData).length > 0){
            msgReadRequest();
        }
    },[chatData]);

    useEffect(() =>{
        setTimeout(() => listRef.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
    },[patientChatData]);

    async function onSend() {
        const todayDate = getToday();
        const currentTime = new Date().toTimeString();

        const msgData = {
            message: message,
            patientId: patientId,
            clinicId: clinicId,
            sender: "CLINIC",
            patientName: patientName,
            dateTime: `${todayDate} ${currentTime.split(" ")[0]}`,
            position:'right',
        }

        const sendMsgData = {
            patientId: patientId,
            clinicId: clinicId,
            sender: "CLINIC",
            message: message
        }
        
        if(message && message !== "") {
            setPatientChatData([...patientChatData, msgData]);
            updateChatList(patientId, message, `${todayDate} ${currentTime.split(" ")[0]}`);
            await sendMessage(sendMsgData, token);
            setMessage("");
        }
    };

    function chatDateFormatter(date) {
        const day = new Date(date).getDate().toString().padStart(2, "0");
        const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");

        return `${Months[parseInt(month)]?.slice(0, 3)} ${day}, ${new Date(date).getFullYear().toString()}`;
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
          await onSend();
        }
    }

    return (
        chatData && Object.keys(chatData).length <= 0 ?
            <Grid container height="80vh" justifyContent={"center"} alignItems={"center"}>
                <Typography>Welcome to Jogo Web Messaging</Typography>
            </Grid> 
        : <Grid position={"relative"} height="80vh">
            <Grid 
                container 
                style={{
                    backgroundColor:"#FFFFFF",
                    paddingBottom:"5px", 
                    paddingLeft:"5px",
                    alignItems:"center", 
                    borderRadius:"5px",
                }}
            >
                    <img
                        alt="profile"
                        width={"40px"}
                        height={"40px"}
                        src={
                            ! patientProfileUrl
                            ? require("../Images/testuser.png")
                            : patientProfileUrl
                        }
                        style={{ borderRadius: "100%", margin:"5px" }}
                    />
                <Typography style={{fontWeight:"600", paddingLeft:"5px"}}>{patientName}</Typography>
            </Grid>
            {
                loading && <Grid container height="70vh" backgroundColor={"#F5F5F5"} justifyContent={"center"} alignItems={"center"} position={"absolute"}>
                    <CircularProgress/>
                </Grid>
            }
            <StyledGrid height="70vh" overflow={"scroll"}>
                {
                    Object.keys(filteredMessages).map((value, index) => {
                        const chatList = filteredMessages[value];
                        return (
                            <Grid ref={listRef} key={index}>
                                <Grid container justifyContent={"center"} padding={"10px"} height={"30px"}>
                                    <Typography variant="caption">{chatDateFormatter(value)}</Typography>
                                </Grid>
                                {
                                    chatList && chatList.map((item, j) =>{
                                        return <ChatRow key={j} chat={{
                                            position: item.sender === "CLINIC" ? 'right' : "left",
                                            type: 'text',
                                            text: item.message,
                                            date: item.dateTime
                                        }}/>
                                    }) 
                                }
                            </Grid>
                        )
                    })
                }
                <div ref={messagesEndRef}></div>
            </StyledGrid>
            <Grid container height="10vh" justifyContent={"center"} alignItems={"center"} marginLeft="10px">
                <Grid item xs={11} container backgroundColor="#FFFFFF" borderRadius="10px">
                    <InputBase placeholder="Type your message here..." value={message} onChange={(e => setMessage(e.target.value))} style={{ width:"95%",padding:"10px" }} onKeyDown={handleKeyDown} />
                </Grid>
                <Grid item xs={1}>
                    <IconButton type="button" onClick={onSend} style={{ backgroundColor:"#E24020", marginLeft:"10px" }}>
                        <SendIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

const ChatListCard = ({ patientData, onClick }) =>{

    function isMessageIsTooLong(data) {
        const textLength = data.length;
        if(textLength > 10){
            return true;
        }else{
            return false;
        }
    }

    return (
        <ButtonBase disableRipple style={{ width:"100%"}} onClick={() => onClick(patientData.id)}>
            <Grid container backgroundColor={"#FFFFFF"}>
                <Grid item justifyContent={"center"} alignItems={"center"}> 
                    <img
                        alt="profile"
                        width={"60px"}
                        height={"60px"}
                        src={
                            !patientData.avatar
                            ? require("../Images/testuser.png")
                            : patientData.avatar
                        }
                        style={{ borderRadius: "100%", margin:"10px" }}
                    />
                </Grid>
                <Grid item xs={9} paddingTop={"15px"}>
                    <Grid container justifyContent={'space-between'}>
                        <Typography fontWeight="500" color="#000000" variant="body2">{patientData.title}</Typography>
                        <Typography color="#72767C" variant="caption">{patientData.date}</Typography>
                    </Grid>
                    <Grid container justifyContent={'space-between'}>
                        <Typography variant="caption" noWrap color="#72767C" paddingTop={"8px"} width={isMessageIsTooLong(patientData.subtitle) ? '50%': null}>
                            {patientData.subtitle}
                        </Typography>
                        {
                            patientData.unread !== 0 ? 
                                <Grid backgroundColor="#E24020" marginTop={"10px"} height="20px" width="20px" borderRadius="50%" alignItems="center" justifyContent="center">
                                    <Typography variant="caption" color="#FFFFFF">{patientData.unread}</Typography>
                                </Grid>
                            : <Grid marginTop={"15px"} ><TickIcon/></Grid>
                        }
                    </Grid>
                </Grid>
                <CustomDivider width={"100%"}/>
            </Grid>
        </ButtonBase>
    );
}

function ClinicAdminChat() {
    const clinicId = getAdminId();
    const token = getAccessToken();
    const [chatList, setChatList] = useState([]);
    const [filteredChatData, setFilteredChatData] = useState([]);
    const [chatAllData, setChatAllData] = useState([]);
    const [patientChatData, setPatientChatData] = useState({});
    const [patientId, setPatientId] = useState("");
    const [patientName, setPatientName] = useState("");
    const [patientProfileUrl, setPatientProfileUrl] = useState("");

    useEffect(() => {
        if (parseInt(getRole()) === 7) {
            LogOut();
          }
        async function fetchChatList(){
            const res = await getPatientsChatList(clinicId, token);
            const filteredChatData = res.content.map((item) => {
                return {
                    id: item.patientId,
                    avatar: item.profilePicUrl,
                    title: item.patientName,
                    subtitle: item.messages[0]?.message,
                    date: getLastChatDateAndTime(item.lastMsgdateTime),
                    unread: item.unreadMsg,
                    dateTime:item.lastMsgdateTime,
                }
            });
            const orderByLatestDate = filteredChatData.sort((d1, d2) => new Date(d1.dateTime).getTime() - new Date(d2.dateTime).getTime()).reverse();

            setChatList(orderByLatestDate);
            setFilteredChatData(filteredChatData);

            const filterAllChatData = res.content.map(item => {
                return {
                    ...item,
                    messages: item.messages.reverse()
                }
            });
            setChatAllData(filterAllChatData);

        } 

        fetchChatList();
    }, []);

    function getLastChatDateAndTime(date){
        const yesterday = new Date(new Date());
        yesterday.setDate(new Date().getDate() - 1);
    
        if(date?.split(' ')[0] === new Date().toISOString().split('T')[0]){
          return convertTo12HourTime(date?.slice(11, 16));
        }else if(yesterday.toISOString().slice(0, 10) === date?.split(' ')[0]){
          return 'Yesterday';
        }else{
          return date?.split(' ')[0];
        }
    }

    function onClickPatientChat(id) {
        const chatData = chatAllData.find(value => value.patientId === id);
        setPatientChatData(chatData?.messages);
        setPatientId(chatData?.patientId);
        setPatientName(chatData?.patientName);
        setPatientProfileUrl(chatData?.profilePicUrl);

        const clone = chatList.map(value => {
            if(value.id === id && value.unread !== 0){
                return { ...value, unread : 0};
            }

            return value;
        });

        setChatList(clone);
    }

    const filterFunction = (e) => {
        const searchResults = filteredChatData.filter(value => {
          return value["title"].toLowerCase().match(e.toLowerCase());
        });

        if(searchResults.length > 0){
            setChatList(searchResults);
        }else{
            setChatList(filteredChatData);
        }
    
    };

    const updateChatList = (patientId, latestMsg, time) =>{
        const newChatList = chatList.map(item =>{
            if(item.id === patientId){
                return {
                    ...item,
                    dateTime: time,
                    subtitle: latestMsg,
                    date: getLastChatDateAndTime(time),
                    unread:0
                }
            }else{
                return item;
            }
        });

        const orderByLatestDate = newChatList.sort((d1, d2) => new Date(d1.dateTime).getTime() - new Date(d2.dateTime).getTime()).reverse();
        setChatList(orderByLatestDate);
    }

    return <>
        <ClinicAdminSideNav/>
        <div style={mainDivStyle}>
            <Grid container columns={{ md: 10 }} height="80vh" paddingTop={"5px"}>
                <Grid item backgroundColor={"#FFFFFF"} xs={3}>
                    <Grid padding={"10px"}>
                        <SearchBar placeHolder={"Search user"} filterFunction={filterFunction} bgColor={"#F5F4F6"}/>
                    </Grid>
                    <StyledGrid container backgroundColor={"#FFFFFF"} height="78vh" overflow={"scroll"}>
                        <Grid width={"100%"}>
                        {
                            chatList.map((item, index) =>{
                                if(item.date){
                                    return <ChatListCard key={index} patientData={item} onClick={onClickPatientChat}/>
                                } else {
                                    return <div key={index}/>
                                }
                            })
                        }
                        </Grid>
                    </StyledGrid>
                </Grid>
                <Grid item xs={7}>
                    <PatientChat 
                        chatData={patientChatData}  
                        onClick={() => {}} 
                        clinicId={clinicId}
                        patientId={patientId}
                        patientName={patientName}
                        patientProfileUrl={patientProfileUrl}
                        updateChatList={updateChatList}
                    />
                </Grid>
            </Grid>
        </div>
    </>
}


export const StyledGrid = styled(Grid)(({ theme }) => ({
    '&::-webkit-scrollbar': {
        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
        display: 'none', // Hide the scrollbar for IE
    },
  }));

export default ClinicAdminChat;