import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {
  DialogBackground,
  DialogTitleStyle,
  FileUploadDiv,
  SubmitButton,
  helperText,
} from "../Theme/Theme";
import { CloseIcon, UploadIcon } from "../Icons/Icons";
import Dropzone from "react-dropzone";
import Papa from "papaparse";
import {
  getAccessToken,
  getAdminId,
  getClinicAdminId,
  getRole,
} from "../Auth/Auth";
import { uploadBulkExercise } from "../Api/Api";
import { toast } from "react-toastify";
import getYouTubeID from "get-youtube-id";
import { YOUTUBE_EMBED_URL, YOUTUBE_THUMBNAIL_URL } from "../Api/Const";
import { Instructions } from "./Instructions";

export function ExerciseCsvUpload(props) {
  const [csv, setCsv] = useState();
  const [csvError, setCsvError] = useState({ status: false, msg: "" });
  const [exerciseData, setExerciseData] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [invalidUrls, setInvalidUrls] = useState([]);

  const [instructions, setInstructions] = useState(false);
  const readCsvfile = (e) => {
    setCsvError({ status: false, msg: "" });
    if (e[0].path.slice(-3) !== "csv") {
      setCsvError({ status: true, msg: "Invalid file format." });
      return;
    }
    setCsv(e[0]);
    if (e) {
      Papa.parse(e[0], {
        complete: function (results) {
          let data = [];
          let errorUrls = [];
          results.data.map((arr, index) => {
            let tagsArray = [];
            const id = getYouTubeID(arr[2]);
            if (id === null) {
              errorUrls.push(index + 1);
            }
            arr.map((a, i) => {
              if (i > 3) {
                a !== "" && tagsArray.push(a);
              }
            });
            let obj = {
              tutorialTitle: arr[0],
              tutorialCategory: "EXERCISE_GUID",
              thumbnailURL:
                arr[3] === "" ? YOUTUBE_THUMBNAIL_URL + id + "/0.jpg" : arr[3],
              videoURL: YOUTUBE_EMBED_URL + getYouTubeID(arr[2]),
              description: arr[1],
              tags: tagsArray,
              tutorialUploader: getAdminId(), //If clinic uploades this it will be clinic id else it will be admin id
            };
            data.push(obj);
            return data;
          });
          setInvalidUrls(errorUrls);
          setDisabled(false);
          setExerciseData(data);
        },
      });
    }
  };

  const next = () => {
    setInstructions(false);
  };

  const submit = async () => {
    const token = getAccessToken();
    const adminId =
      props.tutorialUploader === "CLINIC" ? getClinicAdminId() : getAdminId();

    //If invalid links are included show the error toast and return.
    if (invalidUrls.length > 0) {
      const invalidRows = invalidUrls.map((row) => `Row ${row}`).join(", ");
      toast.error(
        `Invalid video URLs found in the following 
      rows (${invalidRows}) are preventing 
      upload.`,
        { autoClose: false }
      );
      setDisabled(true);
      return;
    }

    const result = await uploadBulkExercise(token, adminId, exerciseData);
    if (result.message === "Success") {
      toast.success(
        `${exerciseData.length} exercises uploaded successfully.Landmark extraction is running in the background. You can continue using the website as usual.`,
        {
          style: {
            background: "#4F9E4E",
          },
        }
      );
      close(true);
    } else {
      toast.error(result.message);
    }
  };

  const close = (status) => {
    setCsv();
    setCsvError({ status: false, msg: "" });
    setExerciseData([]);
    setDisabled(true);
    setInstructions(false);
    props.handleClose(status);
  };
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.open}
        onClose={close}
        style={DialogBackground}
      >
        {instructions === true && parseInt(getRole()) === 1 ? (
          <>
            <Instructions close={close} next={() => next()} />
          </>
        ) : (
          <>
            <DialogTitle style={DialogTitleStyle} id="alert-dialog-title">
              Upload Bulk Exercise Videos
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Dropzone onDrop={(acceptedFiles) => readCsvfile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={FileUploadDiv}>
                      <input {...getInputProps()} />
                      <UploadIcon />

                      <br />
                      {csv === null || csv === undefined ? (
                        <>
                          <Typography variant="subtitle2">
                            Drag & Drop URLs
                          </Typography>
                          <Typography variant="fileUploadBoxSubText">
                            The desired file format for this table is .csv,
                            which will contain exercise title, description, and
                            URL.
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="fileUploadBoxSubText">
                          {csv.name}
                        </Typography>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <Typography style={helperText}>{csvError.msg}</Typography>
              {parseInt(getRole()) === 1 && (
                <div style={{ textAlign: "right" }}>
                  <Button
                    style={{ backgroundColor: "white", textTransform: "none" }}
                    color="primary"
                    onClick={() => setInstructions(true)}
                  >
                    Get Help ?
                  </Button>
                </div>
              )}
            </DialogContent>

            <DialogActions
              style={{ paddingRight: "20px", paddingBottom: "20px" }}
            >
              <Button
                style={{ textTransform: "none" }}
                color="customGray"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={SubmitButton}
                onClick={() => submit()}
                disabled={disabled}
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
