const token = "4Y6X8MIDj9OyiSsng24wa97h2YDMOJiYg9f2J2IE";
const id = "VD2jpG75n6D2dSjUPkOcpd6QAZAjjeXykqUclNlx";
const role = "0PCZQrepxEmbkVWIaLbmqoSFuRARLQUgizXK4mfG";
const rememberMe = "oFclW9sT5hJwvJvlySuFezaM6x6V4LZbVJeAFAwE";
const clinicAdminId = "mtirzWTv05TPQpnlEe35QsV1fDDtTKgZkfRbwyhG1DMgr4KTVg";
const expireDate = "lxrZznimwpXed9UcEpCSeHS2w";

export const getAccessToken = () => {
  return localStorage.getItem(token);
};
export const getAdminId = () => {
  return localStorage.getItem(id);
};
export const getRole = () => {
  const value = localStorage.getItem(role);
  if (value !== null) {
    const roleNo = value.replace("4Y6X8M", "").replace("0PCZQre", "");
    return roleNo;
  }
};
export const getRememberMe = () => {
  const value = localStorage.getItem(rememberMe);
  if (value !== null) {
    const status = value.replace("94myLwux22", "").replace("yQm4", "");
    return status;
  } else {
    return 2;
  }
};
export const getClinicAdminId = () => {
  return localStorage.getItem(clinicAdminId);
};
export const getExpireTime = () => {
  return localStorage.getItem(expireDate);
};

export const setAccessToken = (t) => {
  setExpireTime();
  localStorage.setItem(token, t);
};
export const setAdminId = (i) => {
  localStorage.setItem(id, i);
};
export const setRole = (r) => {
  localStorage.setItem(role, "4Y6X8M" + r + "0PCZQre");
};
export const setRememberMe = (m) => {
  localStorage.setItem(rememberMe, "94myLwux22" + m + "yQm4");
};
export const setClinicAdminId = (i) => {
  localStorage.setItem(clinicAdminId, i);
};
export const setExpireTime = () => {
  let dateNow = new Date();
  let expDate = new Date(dateNow.getTime() +  (1000*60*60*48));
  localStorage.setItem(expireDate, expDate.getTime());
};

export const LogOut = () => {
  localStorage.clear();
  window.location.reload();
};
