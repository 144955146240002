import axios from "axios";
import { LogOut, setAccessToken, setAdminId, setRole } from "../Auth/Auth";
import { uploadFile } from "react-s3";
import { getTodayDate } from "../Utils/Helpers";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const getClinicDetails = async (token, adminId) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}clinicSummary/${adminId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") {
        return error.message;
      }
      if (error.response.status === 401) {
        LogOut();
      }
      return error;
    });
};

export const getGrowthRate = async (token, adminId) => {
  const date = new Date();
  const thisMonth = date.getMonth() + 1;
  const thisYear = date.getFullYear();

  let lastMonth;
  let lastYear;

  if (thisMonth === 1) {
    lastMonth = 12;
    lastYear = thisYear - 1;
  } else {
    lastMonth = thisMonth - 1;
    lastYear = thisYear;
  }

  const thisDate = `${thisYear}-${thisMonth}`.replace(/-0+/g, "-");
  const lastDate = `${lastYear}-${lastMonth}`.replace(/-0+/g, "-");

  return axios
    .get(
      `${process.env.REACT_APP_API_URL}admin/${adminId}/growthRate/${thisDate}/${lastDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err;
    });
};

export const getRecordSummary = async (today, token, adminId) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}admin/${adminId}/${today}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err;
    });
};

export const getClinicInfo = async (clinicId, token) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}clinic/${clinicId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err;
    });
};

export const getRecordProgressOfClinic = async (
  clinicId,
  startDate,
  endDate,
  token
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}clinic/${clinicId}/progress?type=day&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err;
    });
};

export const deleteClinicById = async (token, clinicId) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}clinic/${clinicId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getAdminDetails = async (token, adminId) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}admin/${adminId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const updateClinicInfo = async (data, token, clinicId) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}clinic/${clinicId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const createNewClinic = async (data, token) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}admin/clinic`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getToken = async (email, password) => {
  const body =
    "client_id=" +
    "jogo-client" +
    "&client_secret=" +
    "jogo-secret" +
    "&grant_type=" +
    "password" +
    "&username=" +
    email +
    "&password=" +
    password;
  return axios
    .post(`${process.env.REACT_APP_API_URL}/oauth/token`, body)
    .then(async (res) => {
      if (
        res.status === 200 &&
        (res.data.role === 0 || res.data.role === 100 || res.data.role === 1)
      ) {
        setAccessToken(res.data.access_token);
        setAdminId(res.data.id);
        setRole(res.data.role);
        return { status: res.status, role: res.data.role };
      }
      if (
        res.status === 200 &&
        (res.data.role === 5 || res.data.role === 6 || res.data.role === 7)
      ) {
        setAccessToken(res.data.access_token);
        await getTherapistAndSave(res.data.id, res.data.access_token);
        setRole(res.data.role);
        return { status: res.status, role: res.data.role };
      }
    })
    .catch((err) => {
      return err.response.status;
    });
};

const getTherapistAndSave = async (therapistId, token) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/clinic/getOneTherapist/${therapistId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      console.log(res.data.content.clinicId);
      setAdminId(res.data.content.clinicId);
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const requestForResetPassword = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}reset/request-resetpassword`, data)
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const passwordReset = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}reset/resetpassword`, data)
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getAllExercises = async (filterArray, adminId) => {
  let requestUrl;
  if (filterArray === undefined) {
    requestUrl = `${process.env.REACT_APP_API_URL}tutorial/admin/${adminId}`;
  } else if (filterArray[0] === "0" || filterArray.length === 0) {
    requestUrl = `${process.env.REACT_APP_API_URL}tutorial/admin/${adminId}`;
  } else {
    let filter = "";
    filterArray.map((i) => {
      if (filter === "") {
        filter = filter + i;
      } else {
        filter = filter + "," + i;
      }
      requestUrl = `${process.env.REACT_APP_API_URL}tutorial/admin/${adminId}?tagsFilters=${filter}`;
    });
  }
  return axios
    .get(requestUrl)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.message === "Network Error") {
        return err.message;
      }
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

const thumbnailConfig = {
  dirName: "images/jogo/Thumpnails",
  bucketName: "senzmate-polar",
  region: "us-west-2",
  accessKeyId: "AKIA5XGNU22IOH7IWSV4",
  secretAccessKey: "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",
  s3Url: "s3://senzmate-polar/images/jogo/Thumpnails/",
};

export const uploadThumbnail = async (thumbnail) => {
  return uploadFile(thumbnail, thumbnailConfig)
    .then((data) => {
      return data.location;
    })
    .catch((err) => {
      return "error";
    });
};

export const uploadExercise = async (data, adminId) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}addNewTutorial/${adminId}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getOneTutorial = async (tutorialExerciseId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}tutorial/tutorialContent/${tutorialExerciseId}`
    )
    .then((res) => {
      return res.data.content;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const updateExercise = async (data, token) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}admin/updateTutorial`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const deleteExerciseById = async (id, token) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}admin/deleteTutorial/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const featureExtraction = async (videoUrl, exerciseId) => {
  const data = {
    url: videoUrl,
    tutorial_id: exerciseId,
    post_request_url: `${process.env.REACT_APP_API_URL}tutorial/${exerciseId}`,
    error_request_url: `${process.env.REACT_APP_API_URL}tutorial/${exerciseId}`,
  };
  return axios
    .post(`${process.env.REACT_APP_FEATURE_EXTRACTION_API}process-video`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return undefined;
    });
};

export const verifyAdminAccount = (data, id, key) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}verify/admin/${id}/${key}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getAlladmins = (token) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}super/getAllAdmins`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.content;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const addNewAdminAccount = (token, data) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}super/adminSignUp`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const deleteAdminAccount = (token, id) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}super/deleteAdmin/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getMedications = (token, id, type) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}medication/${id}/${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.message === "Network Error") {
        return err.message;
      }
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const addOneMedication = (token, id, data) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}medication/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const deleteBulkMedications = (token, d) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}medication`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: d,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getOneMedication = (token, id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}medication/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const editMedication = (token, id, data) => {
  return axios
    .put(`${process.env.REACT_APP_API_URL}medication/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const uploadBulkMedication = (token, id, data) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}medication/medicationBulk/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};
export const uploadBulkExercise = (token, id, data) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}addNewBulkTutorial/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getAllTags = (adminId) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}tutorial/tags/admin/${adminId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const updateTag = async (token, adminId, tagId, data) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}tutorial/tags/${adminId}/${tagId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const deleteExerciseTag = (token, d) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}tutorial/tags`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: d,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getClinicPatientOverView = (token, clinicId, filter) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}clinic/${clinicId}/viewPatients?filter=${filter}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getAllTherapists = (token, clinicId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/clinic/getClinicTherapists/${clinicId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.message === "Network Error") {
        return err.message;
      }
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const addTherapist = (token, data) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/clinic/newTherapist`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getOneTherapist = (token, therapistId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/clinic/getOneTherapist/${therapistId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 422) {
        LogOut();
      }
      return err.response.data;
    });
};

export const updateTherapist = (token, data, therapistId) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}clinic/editTherapist/${therapistId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const deleteOneTherapist = (token, therapistId) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}clinic/deleteTherapist/${therapistId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const updateClinicChatDisable = (token, value, clinicId) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}clinic/${clinicId}/disableChat/${value}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getPatientsOfClinic = async (token, clinicId, filter) => {
  const filterUrl = `${process.env.REACT_APP_API_URL}clinic/${clinicId}/viewPatients/?filter=${filter}`;
  const normalUrl = `${process.env.REACT_APP_API_URL}clinic/${clinicId}/viewPatients`;
  return axios
    .get(filter !== undefined ? filterUrl : normalUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") {
        return error.message;
      }
      if (error.response.status === 401 || error.response.status === 422) {
        LogOut();
      }
      return error;
    });
};

export const getPatient = async (id, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}patient/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const getPatientRecords = async (patientId, date, setRecords, token) => {
  let endpoints = [
    `${
      process.env.REACT_APP_API_URL
    }patient/${patientId}/record?year=${date.getFullYear()}&month=${
      date.getMonth() + 1
    }`,
    `${
      process.env.REACT_APP_API_URL
    }patient/${patientId}/dietRecord?year=${date.getFullYear()}&month=${
      date.getMonth() + 1
    }`,
    `${
      process.env.REACT_APP_API_URL
    }patient/${patientId}/vitalRecord?year=${date.getFullYear()}&month=${
      date.getMonth() + 1
    }`,
    `${
      process.env.REACT_APP_API_URL
    }patient/${patientId}/medicationRecord?year=${date.getFullYear()}&month=${
      date.getMonth() + 1
    }`,
    `${
      process.env.REACT_APP_API_URL
    }patient/${patientId}/symptomRecord?year=${date.getFullYear()}&month=${
      date.getMonth() + 1
    }`,
  ];

  return axios
    .all(
      endpoints.map((endpoint) =>
        axios.get(endpoint, { headers: { Authorization: `Bearer ${token}` } })
      )
    )
    .then(
      axios.spread(
        (
          { data: { content: record } },
          { data: { content: dietRecord } },
          { data: { content: vitals } },
          { data: { content: medication } },
          { data: { content: symptoms } }
        ) => {
          setRecords(
            record
              .map((v) => ({ ...v, expand: false, type: "record" }))
              .concat(
                dietRecord.map((v) => ({ ...v, expand: false, type: "diet" }))
              )
              .concat(
                vitals.map((v) => ({ ...v, expand: false, type: "vitals" }))
              )
              .concat(
                medication.map((v) => ({
                  ...v,
                  expand: false,
                  type: "medication",
                }))
              )
              .concat(
                symptoms.map((v) => ({ ...v, expand: false, type: "symptoms" }))
              )
          );
        }
      )
    )
    .catch((err) => console.log(err));
};

export const getAssignedTutorials = async (patientId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}patient/exerciseTutorials/${patientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const getPatientRecordSummary = async (patientId, token) => {
  try {
    const res = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }patient/${patientId}/averageSessionLength?year=${new Date().getFullYear()}&month=${
        new Date().getMonth() + 1
      }&day=${new Date().getDate()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const getPatientRecordCount = async (
  patientId,
  condition,
  startDate,
  endDate,
  token
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}patient/${patientId}/${condition}?startYear=${startDate.year}&startMonth=${startDate.month}&startDate=${startDate.day}&endYear=${endDate.year}&endMonth=${endDate.month}&endDate=${endDate.day}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const getSixteenPlusPatients = async (clinicId, startDate, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}clinic/${clinicId}/get16PlusPatients?startDate=${startDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const getPatientsExerciseProgress = async (
  patientId,
  type,
  today,
  duration,
  token
) => {
  try {
    const res = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }patient/${patientId}/progress?type=${type}&startDate=${
        type === "month"
          ? today.getFullYear() + "-01-01"
          : getTodayDate(duration)
      }&endDate=${
        type === "month" ? today.getFullYear() + "-12-31" : getTodayDate()
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const getAllRecordTypesInLast30Days = async (
  patientId,
  token,
  startYear,
  startMonth,
  startDay,
  endYear,
  endMonth,
  endDay
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}patient/${patientId}/allRecords?startYear=${startYear}&startMonth=${startMonth}&startDate=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDate=${endDay}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        LogOut();
      }
      return err.response.data;
    });
};

export const getPatientsChatList = async (clinicId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}clinic/message/${clinicId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const sendMessage = async (messageData, token) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}message`,
      messageData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const messageReadRequest = async (id, type, token) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}message/read/${id}/sender/${type}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};

export const editDiagnosisOnly = async (id, data, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}patient/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 422) {
      LogOut();
    }
    return err.response.data;
  }
};
