import React, { useEffect, useState } from "react";
import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import { mainDivStyle } from "../Theme/Theme";
import {
  Button,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllRecordTypesInLast30Days,
  getPatient,
  getPatientRecordSummary,
} from "../Api/Api";
import { getAccessToken } from "../Auth/Auth";
import {
  BackArrow,
  EditDiagnosisIcon,
  ExerciseStatusIcon,
  GmailRedIcon,
  MedStatusIcon,
  PhoneIcon,
  RightArrow,
} from "../Icons/Icons";
import PatientProgressIcon from "../Images/Clock.png";
import PatientLogIcon from "../Images/Logs.png";
import { DiagnosisPopUp } from "../UIComponents/DiagnosisPopUp";

const LABELS = [
  {
    label: "High",
    backgroundColor: "rgba(226, 64, 32, 0.2)",
    color: "rgba(226, 64, 32, 1)",
  },
  {
    label: "Low",
    backgroundColor: "rgba(235, 184, 4, 0.2)",
    color: "rgba(250, 195, 0, 1)",
  },
  {
    label: "Idle",
    backgroundColor: "rgba(117, 117, 117, 0.2)",
    color: "rgba(119, 119, 119, 1)",
  },
  {
    label: "Active",
    backgroundColor: "rgba(35, 198, 135, 0.2)",
    color: "rgba(0, 147, 90, 1)",
  },
];

function StatusCard({ name, number, StatusIcon, color }) {
  return (
    <Grid
      container
      style={{
        backgroundColor: "white",
        paddingTop: "20px",
        width: "49%",
        textAlign: "center",
        marginTop: "10px",
        borderLeft: `6px solid ${color}`,
        borderRadius: "4px",
        boxShadow: "0px 10px 100px rgba(0, 0, 0, 0.04)",
        minHeight: "180px",
        maxHeight: "180px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StatusIcon />
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="subtitle1" component={"span"}>
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="cardNoFont" color={color}>
          {number}
        </Typography>
      </Grid>
    </Grid>
  );
}

function ClinicAdminPatientProfile() {
  const { patientId } = useParams();
  const { state } = useLocation();
  const token = getAccessToken();
  const navigator = useNavigate();
  const [patientData, setPatientData] = useState({});
  const [summary, setSummary] = useState([]);

  const [openEditDiagnosis,setOpenEditDiagnosis]= useState(false);
  const [patient,setPatient]= useState("")

  const open=()=>{
    setOpenEditDiagnosis(true)
    setPatient(patientId)
  }

  const handleClose=()=>{
    setOpenEditDiagnosis(false)
    fetchPatient()
  }

  async function fetchPatient() {
    const result = await getPatient(patientId, token);
    if (result.message === "Success") {
      setPatientData(result.content);
    }
  }

  useEffect(() => {

    async function getLast30DaysSummary() {
      let currentDate = new Date();
      let thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const res = await getAllRecordTypesInLast30Days(
        patientId,
        token,
        thirtyDaysAgo.getFullYear(),
        thirtyDaysAgo.getMonth() + 1,
        thirtyDaysAgo.getDate(),
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );
      if (res.message === "Success") {
        setSummary(res.content);
      }
    }

    fetchPatient();
    getLast30DaysSummary();
  }, []);

  function goBack() {
    navigator(`/clinicUser/patients`);
  }

  const onClickLogsPage = () => {
    navigator(`/clinicUser/patientAllLogs/${patientId}`);
  };

  const onClickProgressPage = () => {
    navigator(`/clinicUser/patientProgress/${patientId}`);
  };

  return (
    <>
      <ClinicAdminSideNav />
      <div style={mainDivStyle}>
        <Grid container>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Grid container padding="10px">
              <Grid
                item
                xs={6}
                paddingBottom="10px"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <IconButton padding={"10px"} onClick={goBack}>
                  <BackArrow />
                </IconButton>
                <Typography style={{ fontWeight: "700", paddingLeft: "10px" }}>
                  Patient Profile
                </Typography>
              </Grid>
              <Grid item xs={6} padding="10px" alignItems={"flex-end"}></Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} style={{ padding: "10px" }}>
                <Grid
                  width="100%"
                  backgroundColor="white"
                  height={"390px"}
                  padding="10px"
                >
                  <Grid container direction="row">
                    <img
                      alt="profile"
                      width={"150px"}
                      height={"150px"}
                      src={
                        !patientData.profilePicURL
                          ? require("../Images/testuser.png")
                          : patientData.profilePicURL
                      }
                      style={{ borderRadius: "100%", margin: "10px" }}
                    />
                    <CardContent>
                      <Typography fontWeight="md" variant="h4">
                        {patientData.givenName && patientData.familyName
                          ? patientData.givenName + " " + patientData.familyName
                          : patientData.patientName}
                      </Typography>
                      <Grid container flexDirection="column" marginTop={"10px"}>
                        <Typography
                          variant="head2"
                          paddingBottom={"5px"}
                          fontWeight={"600"}
                        >
                          Contact Details
                        </Typography>
                        <Grid container alignItems={"center"} marginTop={"5px"}>
                          <PhoneIcon />
                          <Typography
                            variant="body2"
                            alignItems={"center"}
                            marginLeft={"10px"}
                            color={"#72767C"}
                          >
                            {patientData.phoneNumber}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          alignItems={"center"}
                          marginTop={"10px"}
                        >
                          <GmailRedIcon />
                          <Typography
                            variant="body2"
                            alignItems={"center"}
                            marginLeft={"10px"}
                            color={"#72767C"}
                          >
                            {patientData.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Grid
                      container
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      padding={"20px"}
                    >
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography variant="head2" color={"#72767C"}>
                          Date of Birth
                        </Typography>
                        <Typography variant="h6" fontWeight={"600"}>
                          {patientData.dateOfBirth
                            ? patientData.dateOfBirth
                            : "No Available"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography variant="head2" color={"#72767C"}>
                          Age
                        </Typography>
                        <Typography variant="h6" fontWeight={"600"}>
                          {patientData.age
                            ? `${patientData.age} Years`
                            : "No Available"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography variant="head2" color={"#72767C"}>
                          Joined Date
                        </Typography>
                        <Typography variant="h6" fontWeight={"600"}>
                          {new Date(patientData.joinedDate)
                            .getDate()
                            .toString()
                            .padStart(2, "0") +
                            "/" +
                            (new Date(patientData.joinedDate).getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            new Date(patientData.joinedDate)
                              .getFullYear()
                              .toString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} padding="10px">
                <Grid
                  container
                  padding="10px"
                  height={"200px"}
                  backgroundColor="white"
                  flexDirection={"column"}
                >
                  <Typography
                    variant="h6"
                    padding="10px"
                    marginLeft={"30px"}
                    fontWeight={"600"}
                  >
                    Overview
                  </Typography>
                  <Grid
                    container
                    padding="10px"
                    marginLeft={"30px"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={6}>
                      <Typography variant="head2">Activity</Typography>
                      <Grid container direction="row">
                        <Typography
                          variant="body1"
                          fontWeight="500"
                          color="#E24020"
                        >
                          {summary.length} Days
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight="400"
                          color="#72767C"
                          marginLeft={"5px"}
                        >
                          Usage in
                        </Typography>
                      </Grid>
                      <Typography
                        variant="body1"
                        fontWeight="400"
                        color="#72767C"
                      >
                        Last 30 Days{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Status</Typography>
                      {LABELS.map((item, index) => {
                        return (
                          state.patientStatus &&
                          state.patientStatus.includes(item.label) && (
                            <Chip
                              key={index}
                              label={item.label}
                              size="small"
                              hidden={true}
                              style={{
                                margin: "2px",
                                backgroundColor: item.backgroundColor,
                                color: item.color,
                              }}
                            />
                          )
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"space-between"}>
                  <StatusCard
                    StatusIcon={ExerciseStatusIcon}
                    name={"Exercises Assigned"}
                    number={state.exerciseCount ? state.exerciseCount : 0}
                    color={"#D44C31"}
                    icon={true}
                  />
                  <StatusCard
                    icon={true}
                    name={"Medication Prescribed"}
                    number={state.medicationCount ? state.medicationCount : 0}
                    color={"#D69C37"}
                    StatusIcon={MedStatusIcon}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={7} style={{ padding: "10px" }}>
                <Grid container backgroundColor="white" padding="10px">
                  <Grid
                    padding="10px"
                    height="155px"
                    width="70%"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#E24020"
                      fontWeight="700"
                    >
                      Diagnosis:
                    </Typography>
                    <Typography variant="body2">
                      {patientData.diagnosis
                        ? patientData.diagnosis
                        : "Not Available"}
                    </Typography>
                  </Grid>
                  <Grid height="155px" width="30%" textAlign={"right"}>
                    <IconButton onClick={open}>
                      <EditDiagnosisIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} padding="10px">
                <Button
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "20px",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    textTransform: "none",
                  }}
                  onClick={onClickProgressPage}
                >
                  <Grid
                    container
                    marginLeft={"15px"}
                    alignItems={"center"}
                    flexDirection={"row"}
                  >
                    <img alt="progressIcon" src={PatientProgressIcon} />
                    <Typography
                      paddingLeft={"10px"}
                      color={"black"}
                      fontWeight={"600"}
                    >
                      Patient Progress
                    </Typography>
                  </Grid>
                  <RightArrow />
                </Button>
                {/* <Button 
                                    style={{
                                        width:"100%",
                                        flexDirection:"row", 
                                        alignItems:"center", 
                                        padding:"20px", 
                                        borderRadius:"5px", 
                                        backgroundColor:"white", 
                                        textTransform:"none", 
                                        marginTop:"5px"
                                    }}
                                    onClick={onClickLogsPage}
                                >
                                    <Grid container marginLeft={"15px"} alignItems={"center"} flexDirection={"row"}>
                                        <img alt="progressIcon" src={PatientLogIcon}/>
                                        <Typography paddingLeft={"10px"} color={"black"} fontWeight={"600"}>Patient Logs</Typography>
                                    </Grid>
                                    <RightArrow />
                                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DiagnosisPopUp open={openEditDiagnosis} handleClose={()=>handleClose()} patientId={patient}/>
    </>
  );
}

export default ClinicAdminPatientProfile;
