import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"].join(","),

    //SideNav
    sideNavFont: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18.23px",
    },

    //Rate Card
    rateCardNoFont: {
      fontSize: "25px",
      fontWeight: 600,
      lineHeight: "40px",
    },
    rateCardSubFont: {
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.5)",
    },
    rateCardPercentageFont: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      color: "rgba(34, 198, 135, 1)",
      paddingLeft: "5px",
    },
    rateCardPercentageSubFont: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.35)",
      paddingLeft: "10px",
    },

    //Record card
    recordCardHeaderFont: {
      fontWeight: 600,
      fontSize: "18px",
      color: "#374151",
      lineHeight: "24px",
    },
    cardTopicFont: {
      fontSize: "20px",
      fontWeight: 500,
      color: "rgba(55, 65, 81, 1)",
      lineHeight: "26px",
    },
    cardNoFont: {
      fontSize: "40px",
      fontWeight: 600,
      color: "rgba(61, 61, 61, 1)",
      lineHeight: "71px",
    },
    cardSubFont: {
      fontSize: "11px",
      fontWeight: 400,
      color: "rgba(95, 97, 101, 1)",
      lineHeight: "14px",
    },

    //View & Update
    viewAndUpdateHeaderFont: {
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "45px",
      color: "rgba(36, 39, 49, 1)",
    },
    viewClinicNameFont: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
    },
    dateFont: {
      fontSize: "14px",
      fontWeight: 300,
      color: "rgba(107, 107, 107, 1)",
    },
    subtitle1: {
      fontWeight: 500,
      color: "rgba(0, 0, 0, 1)",
      fontSize: "16px",
    },
    detailValueFont: {
      fontWeight: 400,
      fontSize: "15px",
      color: "#6B6B6B",
      paddingBottom: "5px",
    },
    graphHeader: {
      fontSize: "18px",
      fontWeight: 600,
      color: "rgba(37, 40, 43, 1)",
      marginTop: "200px",
      paddingLeft: "75px",
    },

    //table
    tableHeader: {
      fontSize: "12px",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.5)",
    },
    tableBody: {
      fontSize: "12px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 1)",
    },

    //Exersise
    exersiceCardTopic: {
      fontSize: "16px",
      fontWeight: 500,
      color: "rgba(37, 40, 43, 1)",
      lineHeight: "24px",
    },
    exersiceCardBody: {
      fontSize: "12px",
      fontWeight: 400,
      color: "rgba(159, 159, 159, 1)",
      lineHeight: "18px",
    },
    fileUploadBoxSubText: {
      fontSize: "12px",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 1)",
      lineHeight: "23px",
    },
    successFormText: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#717171",
    },

    medicationTableBodyText: {
      color: "#000",
      fontSize: "12px",
      lineHeight: "16px",
      display: "inline",
      paddingLeft: "32px",
      fontWeight: "600px",
    },
    medicationTableHeaderText: {
      color: "#000",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600px",
      lineHeight: "16px",
      paddingLeft: "32px",
    },
    exerciseInstructionSubText: {
      color: "#8C8C8C",
      fontSize: "12px",
      fontWeight: "400px",
      lineHeight: "22.94px",
    },
    exerciseInstructionSmallText: {
      color: "#8C8C8C",
      fontSize: "10px",
      fontWeight: "400px",
    },
  },
  palette: {
    primary: {
      main: "#E24020",
    },
    customGray: {
      main: "rgba(100, 90, 90, 1)",
    },
  },
});

export const mainDivStyle = {
  paddingLeft: 250,
  paddingTop: "30px",
  backgroundColor: "#F5F5F5",
  paddingRight: "10px",
  height: "100%",
  paddingBottom: "30px",
};
export const helperText = {
  fontSize: "12px",
  color: "red",
  paddingBottom: "5px",
  paddingLeft: "10px",
};
export const dateDiv = {
  backgroundColor: "white",
  borderRadius: "4px",
  border: "1px solid #ECE9F1",
  margin: "20px",
  marginTop: "0px",
  textAlign: "center",
  width: "85%",
};
export const SubmitButton = {
  boxShadow: "none",
  marginLeft: "10px",
  textTransform: "none",
};
export const DialogTitleStyle = {
  fontWeight: "600px",
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "0px 3px 14px -1px rgba(0, 0, 0, 0.08)",
  marginBottom: "24px",
};
export const FloatingButtonDiv = {
  position: "fixed",
  top: "90%",
  right: "2%",
  textAlign: "center",
};
export const FileUploadDiv = {
  border: "2px dashed #E24020",
  backgroundColor: "#FFF3F0",
  borderRadius: "13px",
  padding: "20px",
  textAlign: "center",
  marginBottom: "10px",
};
export const DialogBackground = {
  backgroundColor: "rgba(0,0,0,0.74)",
};
export const ProgressDiv = {
  borderRadius: "20px",
  height: "20px",
  backgroundColor: "#F8F8F8",
  boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
  display: "flex",
  alignItems: "center",
};

export const ImportButton = {
  textTransform: "none",
  borderRadius: "5px",
  backgroundColor: "#FFF",
  color: "#A5A5A5",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
  width: "100px",
  marginTop: "24px",
  marginRight: "10px",
};

export const EditButton = {
  textTransform: "none",
  borderRadius: "5px",
  backgroundColor: "#FFF",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
  width: "100px",
  marginTop: "24px",
  marginRight: "10px",
};

export const DeleteButton = {
  textTransform: "none",
  borderRadius: "5px",
  color: "white",
  backgroundColor: "rgba(226, 64, 32, 1)",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
  width: "100px",
  marginTop: "24px",
  marginRight: "24px",
};

export const MedicationTableDark = {
  backgroundColor: "rgba(249, 250, 250, 1)",
  paddingLeft: "32px",
  height: "48px",
  paddingTop: "12px",
};

export const MedicationTableLight = {
  backgroundColor: "white",
  paddingLeft: "32px",
  height: "48px",
  paddingTop: "12px",
};

export const SortButton = {
  backgroundColor: "rgba(245, 245, 245, 1)",
  fontSize: "12px",
  marginLeft: "24px",
  color: "#676767",
  textTransform: "none",
  height: "24px",
};

export const BulkUploadButton = {
  textTransform: "none",
  borderRadius: "5px",
  color: "white",
  backgroundColor: "rgba(226, 64, 32, 1)",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.12)",
  margin: "auto",
  marginLeft: "5px",
};

export const CenterGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "90vh",
};

export const PaginationDiv = {
  backgroundColor: "#FFFFFF",
  padding: "8px",
  alignItems: "center",
  display: "inline-block",
  justifyContent: "center",
};

export const TagsScrollContainer = {
  display: "inline-flex",
  width: "80%",
  overflow: "hidden",
  paddingBottom: "10px",
};

export const DiagnosisScrollContainer = {
  display: "inline-flex",
  width: "100%",
  overflow: "hidden",
  paddingBottom: "10px",
};

export const TagsScrollContainerItem = {
  overflowX: "hidden",
  whiteSpace: "nowrap",
  overflowY: "hidden",
  WebkitOverflowScrolling: "touch",
  paddingTop: "2px",
  paddingLeft: "5px",
};
export const BulkUploadButtonDiv = {
  display: "inline",
  textAlign: "right",
};

export const FilterButton = {
  textTransform: "none",
  backgroundColor: "#E24020",
  boxShadow: "0px 2px 6px 0px #0000001F",
  marginLeft: "10px",
  marginRight: "10px",
  maxWidth: "80px",
  minWidth: "80px",
  color: "white",
};
export const FilterButtonNotSelected = {
  textTransform: "none",
  backgroundColor: "white",
  boxShadow: "0px 2px 6px 0px #0000001F",
  marginLeft: "10px",
  marginRight: "10px",
  maxWidth: "80px",
  minWidth: "80px",
};

export const defaultTag = {
  marginRight: "7px",
  borderRadius: "4px",
  color: "#686868",
};

export const selectedTag = {
  marginRight: "7px",
  borderRadius: "4px",
  color: "white",
  backgroundColor: "#E24020",
};
