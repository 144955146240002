import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
  helperText,
} from "../Theme/Theme";
import { CloseIcon } from "../Icons/Icons";
import { TextInput } from "../InputComponents/TextInput";
import { addOneMedication } from "../Api/Api";
import { getAccessToken, getAdminId } from "../Auth/Auth";
import { toast } from "react-toastify";

export function AddMedicationForm(props) {
  const [medication, setMedication] = useState("");
  const [medicationError, setMedicationError] = useState({
    status: false,
    msg: "",
  });

  const readMedication = (e) => {
    setMedicationError({
      status: false,
      msg: "",
    });
    setMedication(e);
  };
  const close = (status) => {
    setMedication("");
    setMedicationError({ status: false, msg: "" });
    props.handleClose(status);
  };

  const addNewMedication = async () => {
    if (medication === "") {
      setMedicationError({ status: true, msg: "Medication name is required." });
      return;
    }
    const token = getAccessToken();
    const id = getAdminId();
    const data = { name: medication };
    const result = await addOneMedication(token, id, data);
    if (result.message === "Success") {
      close(true);
      toast.success("Medication added successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      toast.error(result.message);
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          New Medication
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle2"
            style={{
              color: "rgba(36, 39, 49, 1)",
              paddingBottom: "5px",
              display: "inline",
            }}
          >
            Medication Name
          </DialogContentText>
          <TextInput
            value={medication}
            error={medicationError.status}
            onChange={(e) => readMedication(e.target.value)}
            fullWidth
            style={{ paddingBottom: "10px" }}
            placeholder="Enter Medication Name"
          />
          <Typography style={helperText}>{medicationError.msg}</Typography>
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={SubmitButton}
            autoFocus
            onClick={addNewMedication}
          >
            Add Medication
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
