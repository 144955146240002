import { Typography } from "@mui/material";
import React from "react";

export function SwitchButton(props) {
  const FalseStyle = {
    width: "50%",
    backgroundColor: "#ECECEC",
    borderRadius: "24px",
    textAlign: "center",
    marginTop: "5px",
    transition:
      "background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
  };
  const TrueStyle = {
    width: "50%",
    backgroundColor: "white",
    borderRadius: "24px",
    boxShadow: "0px 1px 4px 0px #00000021",
    color: "#EA5131",
    textAlign: "center",
    paddingTop: "5px",
    transition:
      "background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
  };
  return (
    <div
      style={{
        height: "30px",
        width: "80px",
        backgroundColor: "#ECECEC",
        borderRadius: "24px",
        display: "flex",
        justifyContent: "space-between",
        cursor: props.disabled !== true && "pointer",
      }}
      onClick={() => props.disabled !== true && props.readChatDisable()}
    >
      <div style={props.disableChat ? FalseStyle : TrueStyle}>
        <Typography variant="subtitle2">ON</Typography>
      </div>
      <div style={props.disableChat ? TrueStyle : FalseStyle}>
        <Typography variant="subtitle2">OFF</Typography>
      </div>
    </div>
  );
}
