import { Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { AddIcon } from "../Icons/Icons";

export function IconButton(props) {
  return (
    <CustomButton
      variant="contained"
      startIcon={<AddIcon />}
      onClick={props.onClick}
    >
      {props.text}
    </CustomButton>
  );
}

const CustomButton = styled(Button)(({ theme, onClick }) => ({
  textTransform: "none",
  fontWeight: "bold",
  borderRadius: "7px",
  boxShadow: "none",
}));
