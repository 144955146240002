import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteClinicById,
  getClinicDetails,
  getGrowthRate,
  getRecordSummary,
} from "../../Api/Api";
import { getAccessToken, getAdminId, setExpireTime } from "../../Auth/Auth";
import CustomDatePicker from "../../InputComponents/DatePicker";
import { dateDiv, mainDivStyle } from "../../Theme/Theme";
import ClinicTable from "../../UIComponents/ClinicTable";
import { RateCard } from "../../UIComponents/RateCard";
import { RecordCard } from "../../UIComponents/RecordCard";
import SideNav from "../../UIComponents/SideNav";
import "react-toastify/dist/ReactToastify.css";

export function Dashboard(props) {
  const [clinicDetails, setClinicDetails] = useState([]);
  const [growthRates, setGrowthRates] = useState({
    clinicGrowth: 0,
    patientsGrowth: 0,
  });
  const [recordSummary, setRecordSummary] = useState({
    newUsers: 0,
    powerUsers: 0,
    activeUsers: 0,
  });
  const [totalClinics, setTotalClinics] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);

  const [displayDate, setDisplayDate] = useState(new Date());

  const onDateChange = async (value) => {
    const token = getAccessToken();
    const adminId = getAdminId();
    setDisplayDate(value);
    if (value.format("YYYY-M-D") !== "Invalid Date") {
      const records = await getRecordSummary(
        value.format("YYYY-MM-DD"),
        token,
        adminId
      );
      setRecordSummary(records.content);
    }
  };

  const deleteClinic = async (token, clinicId) => {
    const response = await deleteClinicById(token, clinicId);
    if (response.message === "Success") {
      let newClinics = [];
      clinicDetails.map((c) => {
        if (c.id !== clinicId) {
          newClinics.push(c);
        }
        return newClinics;
      });
      setClinicDetails(newClinics);
      toast.success("Clinic deleted successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    setExpireTime()
    const token = getAccessToken();
    const adminId = getAdminId();
    const getClinicSummary = async () => {
      const clinicSummary = await getClinicDetails(token, adminId);

      //Summing up all pateints array lengths to calculate total patients.
      let totalPatient = 0;
      clinicSummary.content !== undefined &&
        clinicSummary.content.map((obj) => {
          if (obj.patients !== null || obj.patients !== undefined) {
            totalPatient = totalPatient + obj.patients.length;
          }
          return totalPatient;
        });
      setTotalClinics(clinicSummary.content.length);
      setTotalPatients(totalPatient);
      setClinicDetails(clinicSummary.content);
    };

    const getGrowthRates = async () => {
      const rates = await getGrowthRate(token, adminId);
      setGrowthRates(rates.content);
    };

    const getRecords = async () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const today = `${year}-${month}-${day}`;
      const records = await getRecordSummary(today, token, adminId);
      setRecordSummary(records.content);
    };

    getClinicSummary();
    getGrowthRates();
    getRecords();
  }, []);

  return (
    <>
      <SideNav />
      <div style={mainDivStyle}>
        <Grid container style={{ paddingTop: "30px" }}>
          <Grid item xs={12} md={4} sm={12}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <RateCard
                  type={"noOfClinics"}
                  value={totalClinics}
                  text={"Number of Clinics"}
                  percentage={
                    growthRates !== undefined &&
                    (growthRates.clinicGrowth === "NaN" ||
                    growthRates.clinicGrowth === "Infinity"
                      ? "0%"
                      : growthRates.clinicGrowth + "%")
                  }
                />
                <RateCard
                  type={"totalsPatients"}
                  value={totalPatients}
                  text={"Total Patients"}
                  percentage={
                    growthRates !== undefined &&
                    (growthRates.patientsGrowth === "NaN" ||
                    growthRates.patientsGrowth === "Infinity"
                      ? "0%"
                      : growthRates.patientsGrowth.toFixed(2) + "%")
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sm={12}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.39)",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "8px",
              boxShadow: "0px 10px 100px rgba(0, 0, 0, 0.04)",
              padding: "25px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={8}
                style={{ padding: "20px", paddingTop: "0px" }}
              >
                <Typography variant="recordCardHeaderFont">
                  Monthly Overview
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={dateDiv}>
                  <CustomDatePicker
                    displayDate={displayDate}
                    onDateChange={(value) => onDateChange(value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <RecordCard
                  icon={false}
                  name={"New Users"}
                  number={
                    recordSummary === undefined ? 0 : recordSummary.newUsers
                  }
                  color={"#3ECD96"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RecordCard
                  icon={false}
                  name={"Active Users"}
                  number={
                    recordSummary === undefined ? 0 : recordSummary.activeUsers
                  }
                  color={"#E24020"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RecordCard
                  icon={true}
                  name={"Power Users"}
                  number={
                    recordSummary === undefined ? 0 : recordSummary.powerUsers
                  }
                  color={"#4285F4"}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingLeft: "15px", paddingBottom: "30px" }}
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 700,
                color: "rgba(0, 0, 0, 0.75)",
                paddingTop: "30px",
                paddingBottom: "20px",
              }}
            >
              Clinic Details
            </Typography>
            <ClinicTable
              clinicDetails={clinicDetails}
              deleteClinic={(token, clinicId) => deleteClinic(token, clinicId)}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
