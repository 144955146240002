import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
} from "../Theme/Theme";
import { CloseIcon, SuccessIcon } from "../Icons/Icons";
import { IconButton, Typography } from "@mui/material";
import { deleteExerciseById } from "../Api/Api";
import { getAccessToken } from "../Auth/Auth";

export function ExerciseDelete(props) {
  const [success, setSuccess] = useState(false);

  const deleteExercise = async () => {
    const token = getAccessToken();
    const result = await deleteExerciseById(props.exerciseId, token);
    if (result.content === "Success") {
      setSuccess(true);
    }
  };

  const close = () => {
    setSuccess(false);
    props.handleClose();
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        {success === false ? (
          <>
            <DialogTitle style={DialogTitleStyle}>
              Delete Exercise Video?
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                variant="subtitle1"
                style={{ color: "rgba(113, 113, 113, 1)", fontWeight: 400 }}
              >
                Deleting an exercise video will permanently remove it from your
                panel.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ marginRight: "20px", marginBottom: "20px" }}
            >
              <Button
                color="customGray"
                style={{ textTransform: "none" }}
                onClick={close}
              >
                No, Keep Video
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={SubmitButton}
                autoFocus
                onClick={deleteExercise}
              >
                Yes, Delete Video
              </Button>
            </DialogActions>
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <SuccessIcon />
            <br />
            <Typography variant="successFormText">
              Exercise video deleted succesfully !!!
            </Typography>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={close}
              style={{
                boxShadow: "none",
                marginTop: "30px",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Continue
            </Button>
          </div>
        )}
      </Dialog>
    </>
  );
}
