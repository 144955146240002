import { Grid, Typography } from "@mui/material";

export const YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/";
export const YOUTUBE_THUMBNAIL_URL = "https://img.youtube.com/vi/";

export const steps = [
  {
    label: "Prepare Your Data",
    description: `Before creating the CSV file, ensure you have all the necessary information about the videos you want to upload. This may include titles, descriptions, video URLs, thumbnails and tags.`,
  },
  {
    label: "Open a Spreadsheet Program",
    description:
      "Open your preferred spreadsheet program such as Microsoft Excel, Google Sheets or Apple Numbers.",
  },
  {
    label: "Set Up Columns and data",
    description: (
      <>
        <Typography variant="exerciseInstructionSubText">
          Columns should be as follows:
        </Typography>
        <div style={{ paddingLeft: "20%" }}>
          <Typography variant="exerciseInstructionSubText">
            <span style={{ color: "#E24020" }}>Column 1</span>
            <span>: The title of the video</span>
          </Typography>
          <br />
          <Typography variant="exerciseInstructionSubText">
            <span style={{ color: "#E24020" }}>Column 2</span>
            <span>: A brief description of the video content.</span>
          </Typography>
          <br />
          <Typography variant="exerciseInstructionSubText">
            <span style={{ color: "#E24020" }}>Column 3</span>
            <span>: The YouTube video URL</span>
          </Typography>
          <br />
          <Grid container>
            <Grid item sm={2} xs={2} md={2} lg={2}>
              <Typography variant="exerciseInstructionSubText">
                <span style={{ color: "#E24020" }}>Column 4</span>
              </Typography>
            </Grid>
            <Grid item sm={8} xs={8} md={8} lg={8}>
              <Typography variant="exerciseInstructionSubText">
                <span>
                  : The thumbnail URL - Any public image URL should end with png
                  or jpeg
                </span>
              </Typography>
              <br />
              <Typography variant="exerciseInstructionSmallText">
                (If the thumbnail image not added, the default thumbnail in the
                video will be used)
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={3} xs={3} md={3} lg={3}>
              <Typography variant="exerciseInstructionSubText">
                <span style={{ color: "#E24020" }}>Other columns</span>
              </Typography>
            </Grid>
            <Grid item sm={8} xs={8} md={8} lg={8}>
              <Typography variant="exerciseInstructionSubText">
                <span>: Tags - Keywords related to the video content </span>
              </Typography>
              <Typography variant="exerciseInstructionSmallText">
                (One tag name per column)
              </Typography>
            </Grid>
          </Grid>
        </div>
      </>
    ),
  },
  {
    label: "Save as CSV",
    description:
      "Once you have entered all the data, save the file as a CSV file. This format is compatible with our bulk upload system.",
  },
  {
    label: "Upload CSV File",
    description:
      "Click on next to navigate to  the bulk upload section of our platform and follow the instructions to upload your CSV file. ",
  },
];
