import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton as CustomizedButton } from "../InputComponents/IconButton";
import { CheckBoxIcon, CheckboxBorder, CloseIcon } from "../Icons/Icons";
import { TextInput } from "../InputComponents/TextInput";
import { Checkbox, Grid, IconButton, Typography } from "@mui/material";
import {
  SubmitButton,
  DialogTitleStyle,
  helperText,
  DialogBackground,
} from "../Theme/Theme";
import { SwitchButton } from "../InputComponents/SwitchButton";

export default function AlertForm(props) {
  return (
    <div>
      <CustomizedButton onClick={props.handleClickOpen} text="New Clinic" />
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.open}
        onClose={props.handleClose}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle} id="alert-dialog-title">
          {props.title}
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            Clinic Name
          </DialogContentText>
          <TextInput
            error={props.nameError.status}
            value={props.name}
            onChange={(e) => props.readClinic(e.target.value)}
            placeholder="Enter Clinic Name"
            fullWidth
            style={{ paddingBottom: "10px" }}
          />
          <Typography style={helperText}>{props.nameError.msg}</Typography>
          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            Location
          </DialogContentText>
          <TextInput
            error={props.locationError.status}
            value={props.location}
            onChange={(e) => props.readLocation(e.target.value)}
            placeholder="Enter Location"
            fullWidth
            style={{ paddingBottom: "10px" }}
          />
          <Typography style={helperText}>{props.locationError.msg}</Typography>
          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            Contact No
          </DialogContentText>
          <TextInput
            type="text"
            onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            error={props.contactError.status}
            value={props.contact}
            onChange={(e) => props.readContact(e.target.value, e)}
            placeholder="Enter Contact no"
            fullWidth
            style={{ paddingBottom: "10px" }}
          />
          <Typography style={helperText}>{props.contactError.msg}</Typography>
          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            Email
          </DialogContentText>
          <TextInput
            error={props.emailError.status}
            value={props.email}
            onChange={(e) => props.readEmail(e.target.value)}
            placeholder="Enter Email"
            fullWidth
            style={{ paddingBottom: "10px" }}
          />
          <Typography style={helperText}>{props.emailError.msg}</Typography>
          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            Choose reporting record types for the patient app
          </DialogContentText>

          {props.clinicTypes.map((t, i) => {
            return (
              <div key={i}>
                <Checkbox
                  icon={
                    props.selectedClinicTypes.includes(t.code) ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckboxBorder />
                    )
                  }
                  style={{ paddingLeft: "0px" }}
                  size="small"
                  onChange={() => props.readClinicType(t.code)}
                  checked={
                    props.selectedClinicTypes.includes(t.code) ? true : false
                  }
                />
                <Typography
                  variant="subtitle2"
                  style={{
                    display: "inline",
                    color: "#676767",
                  }}
                >
                  {t.name}
                </Typography>
                <br />
              </div>
            );
          })}
          <Typography style={helperText}>
            {props.clinicTypeError.msg}
          </Typography>
          <Grid container>
            <Grid item xs={7} md={7}>
              <DialogContentText
                variant="subtitle2"
                style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
              >
                Enable Chat Functionality in mobile apps
              </DialogContentText>
            </Grid>
            <Grid item xs={5} md={5}>
              <SwitchButton
                disableChat={props.disableChat}
                readChatDisable={() => props.readChatDisable()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "20px", paddingBottom: "20px" }}>
          <Button
            style={{ textTransform: "none" }}
            color="customGray"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => props.addNewClinic()}
            style={SubmitButton}
          >
            Add Clinic
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
