import { React, useState, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CloseIcon } from "../Icons/Icons";
import { IconButton } from "@mui/material";
import { getAccessToken } from "../Auth/Auth";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
} from "../Theme/Theme";

export const AlertDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteClinic = () => {
    const token = getAccessToken();
    props.deleteClinic(token, props.clinicId);
    handleClose();
  };
  return (
    <Fragment>
      <IconButton onClick={handleClickOpen}>{props.icon}</IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          {props.title}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle1"
            style={{ color: "rgba(113, 113, 113, 1)", fontWeight: 400 }}
          >
            {props.sentence2}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={handleClose}
          >
            No, Keep Clinic
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={SubmitButton}
            onClick={deleteClinic}
            autoFocus
          >
            Yes, Delete Clinic
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AlertDialog;
