import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Dashboard } from "./Components/Dashboard/Dashboard";
import { Clinics } from "./Components/Clinic/Clinics";
import { ClinicDetails } from "./Components/Clinic/ClinicDetails";
import { ClinicSummary } from "./Components/Clinic/ClinicSummary";
import { Login } from "./Components/Login/Login";
import {
  getAccessToken,
  getExpireTime,
  getRememberMe,
  getRole,
} from "./Auth/Auth";
import { RequestPasswordReset } from "./Components/PasswordReset/RequestPasswordReset";
import { ResetPassword } from "./Components/PasswordReset/ResetPassword";
import { Exercise } from "./Components/ExerciseLibrary/Exercise";
import { SuperAdminDashboard } from "./SuperAdmin/SuperAdminDashboard";
import { VerifyAccount } from "./Components/Login/VerifyAccount";
import { Medications } from "./Components/Medications/Medications";
import { useEffect } from "react";
import { ToastAlert } from "./UIComponents/ToastAlert";
import { ClinicAdminDashboard } from "./ClinicAdmin/ClinicAdminDashboard";
import { ClinicAdminTherapists } from "./ClinicAdmin/ClinicAdminTherapists";
import { ClinicAdminExercise } from "./ClinicAdmin/ClinicAdminExercise";
import { ClinicAdminViewClinic } from "./ClinicAdmin/ClinicAdminViewClinic";
import { ClinicAdminPatients } from "./ClinicAdmin/ClinicAdminPatients";
import ClinicAdminPatientProgress from "./ClinicAdmin/ClinicAdminPatientProgress";
import ClinicAdminPatientAllLogs from "./ClinicAdmin/ClinicAdminPatientAllLogs";
import ClinicAdminPatientProfile from "./ClinicAdmin/ClinicAdminPatientProfile";
import ClinicAdminChat from "./ClinicAdmin/ClinicAdminChat";

function App() {
  useEffect(() => {
    if (getExpireTime() !== null) {
      if (parseInt(getRememberMe()) === 0 || parseInt(getRememberMe()) === 2) {
        let date = new Date();
        if (date.getTime().toString() > getExpireTime().toString()) {
          localStorage.clear();
        }
      }
    }
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthenticatedRoutes />}>
            <Route path="/" element={<Login type="login" />} />
            <Route path="/reset" element={<RequestPasswordReset />} />
            <Route
              path="/reset/role/:roleid/key/:keyId/id/:userid"
              element={<ResetPassword />}
            />
            <Route
              path="/verify/role/:roleid/key/:keyId/id/:userid"
              element={<VerifyAccount />}
            />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/clinics" element={<Clinics />} />
            <Route path="/exercise" element={<Exercise />} />
            <Route
              path="/clinics/:clinicId/:type"
              element={<ClinicDetails />}
            />
            <Route
              path="/clinics/:clinicId/summary"
              element={<ClinicSummary />}
            />
            <Route path="/medications" element={<Medications />} />
          </Route>

          <Route element={<SuperAdminRoutes />}>
            <Route path="/super/dashboard" element={<SuperAdminDashboard />} />
          </Route>

          <Route element={<ClinicAdminRoutes />}>
            <Route
              path="/clinicUser/dashboard"
              element={<ClinicAdminDashboard />}
            />
            <Route
              path="/clinicUser/users"
              element={<ClinicAdminTherapists />}
            />
            <Route
              path="/clinicUser/exercise"
              element={<ClinicAdminExercise />}
            />
            <Route path="/clinicUser" element={<ClinicAdminViewClinic />} />
            <Route
              path="/clinicUser/patients"
              element={<ClinicAdminPatients />}
            />
            <Route
              path="/clinicUser/patientProgress/:patientId"
              element={<ClinicAdminPatientProgress />}
            />
            <Route
              path="/clinicUser/patientAllLogs/:patientId"
              element={<ClinicAdminPatientAllLogs />}
            />
            <Route
              path="/clinicUser/patientProfile/:patientId"
              element={<ClinicAdminPatientProfile />}
            />
            <Route path="/clinicUser/chat" element={<ClinicAdminChat />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastAlert />
    </div>
  );
}

export default App;

export const PrivateRoutes = () => {
  return getAccessToken() && parseInt(getRole()) === 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export const AuthenticatedRoutes = () => {
  return getAccessToken() && parseInt(getRole()) === 0 ? (
    <Navigate to="/dashboard" />
  ) : getAccessToken() && parseInt(getRole()) === 100 ? (
    <Navigate to="/super/dashboard" />
  ) : getAccessToken() &&
    (parseInt(getRole()) === 1 ||
      parseInt(getRole()) === 5 ||
      parseInt(getRole()) === 6 ||
      parseInt(getRole()) === 7) ? (
    <Navigate to="/clinicUser/dashboard" />
  ) : (
    <Outlet />
  );
};

export const SuperAdminRoutes = () => {
  return getAccessToken() && parseInt(getRole()) === 100 ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export const ClinicAdminRoutes = () => {
  return getAccessToken() &&
    (parseInt(getRole()) === 1 ||
      parseInt(getRole()) === 5 ||
      parseInt(getRole()) === 6 ||
      parseInt(getRole()) === 7) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
