import React, { useEffect, useRef, useState } from "react";
import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import { DiagnosisScrollContainer, FilterButton, FilterButtonNotSelected, TagsScrollContainer, TagsScrollContainerItem, defaultTag, mainDivStyle, selectedTag } from "../Theme/Theme";
import { Button, Chip, Grid, IconButton, MenuItem, Popover, Skeleton } from "@mui/material";
import PatientTable from "../UIComponents/PatientTable";
import { getPatientsOfClinic, getSixteenPlusPatients } from "../Api/Api";
import { getAccessToken, getAdminId } from "../Auth/Auth";
import { FilterIcon, TagsButtonIcon } from "../Icons/Icons";
import { FILTER_TYPES } from "../Utils/Enums";
import { getToday } from "../Utils/Helpers";
import { SearchBar } from "../InputComponents/SearchBar";

export function ClinicAdminPatients(props) {
  const token = getAccessToken();
  const clinicId = getAdminId();
  const [patients, setPatients] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [highPatients, setHighPatients] = useState([]);
  const [lowPatients, setLowPatients] = useState([]);
  const [idlePatients, setIdlePatients] = useState([]);
  const [activePatients, setActivePatients] = useState([]);
  const [sixteenPlusUsagePatient, setSixteenPlusUsagePatient] = useState([]);
  const [sixteenPlusUsagePatientIds, setSixteenPlusUsagePatientIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [allDiagnoses, setAllDiagnoses]=useState([]);
  const [selectedDiagnoses,setSelectedDiagnoses]=useState(["All"])

  const filterValues = {
    High: "HIGH",
    Low: "LOW",
    Idle: "IDLE",
    Active: "ACTIVE",
  };

  const scrollContainerRef = useRef(null);
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 100; // Adjust the scroll distance as needed
    }
  };
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 100; // Adjust the scroll distance as needed
    }
  };

  const readDiagnoses=(val)=>{
    let finalArry;
    if (val !== "All") {
      let diagnosesWithoutAll = selectedDiagnoses.filter((t) => t !== "All");
      if (diagnosesWithoutAll.includes(val)) {
        diagnosesWithoutAll.indexOf(val) !== -1 &&
          diagnosesWithoutAll.splice(diagnosesWithoutAll.indexOf(val), 1);
      } else {
        diagnosesWithoutAll.push(val);
      }
      finalArry = diagnosesWithoutAll;
      setSelectedDiagnoses(diagnosesWithoutAll);
    }
    if (val === "All") {
      setSelectedDiagnoses(["All"]);
      finalArry = ["All"];
      setPatients(allPatients)
    }
    if (finalArry.length === 0) {
      setSelectedDiagnoses(["All"]);
    }

    if(finalArry.length!==0){
      let filteredPatients =[]
      allPatients.map((p)=>{
        if(p.diagnosis && finalArry.includes(p.diagnosis[0])){
          filteredPatients.push(p)
        }
      })
      setPatients(filteredPatients)
    }
    if(finalArry.length===0 || finalArry[0]==="All"){
      setPatients(allPatients)
    }
  }

  const getPatients = async (filter) => {
    setLoading(true);
    setIsStatusLoading(true);
    const result = await getPatientsOfClinic(token, clinicId, filter);
    if (result.message === "Success") {
      if (filter === undefined) {
        setPatients(result.content);
        setAllPatients(result.content);
        let diag=["All"]
        result.content.map((d)=>{
          if(d.diagnosis && !diag.includes(d.diagnosis[0])){
              diag.push(d.diagnosis[0])
          }
        })
        setAllDiagnoses(diag)
      }
      if (filter === filterValues.High) {
        setHighPatients(result.content);
      }
      if (filter === filterValues.Low) {
        setLowPatients(result.content);
      }
      if (filter === filterValues.Idle) {
        setIdlePatients(result.content);
      }
      if (filter === filterValues.Active) {
        setActivePatients(result.content);
      }

      if(filter){
        setIsStatusLoading(false)
      }
    }
    setLoading(false);
  };

  const fetchSixteenPlusPatients = async () => {
    const todayDate = getToday();
    const result = await getSixteenPlusPatients(clinicId, todayDate, token);
    setSixteenPlusUsagePatient(result.content);
    setSixteenPlusUsagePatientIds(result.content.map(value => value.id));
  };

  useEffect(() => {
    getPatients();
    getPatients(filterValues.High);
    getPatients(filterValues.Low);
    getPatients(filterValues.Idle);
    getPatients(filterValues.Active);
    fetchSixteenPlusPatients();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterPatient = (type) => {
    setSelectedFilter(type);
    if (type === FILTER_TYPES.all) {
      setPatients(allPatients);
    }
    if (type === FILTER_TYPES.highUsage) {
      setPatients(allPatients.filter(o1 => highPatients.some(o2 => o1.id === o2.id)));
    }
    if (type === FILTER_TYPES.lowUsage) {
      setPatients(allPatients.filter(o1 => lowPatients.some(o2 => o1.id === o2.id)));
    }
    if (type === FILTER_TYPES.activePatient) {
      setPatients(allPatients.filter(o1 => activePatients.some(o2 => o1.id === o2.id)));
    }
    if (type === FILTER_TYPES.idle) {
      setPatients(allPatients.filter(o1 => idlePatients.some(o2 => o1.id === o2.id)));
    }
    if (type === FILTER_TYPES.sixteenPlus) {
      setPatients(allPatients.filter(o1 => sixteenPlusUsagePatient.some(o2 => o1.id === o2.id)));
    }

    handleClose();
  };

  const filterFunction = (e) => {
    function getSearchValue(value) {
      if(value["familyName"]){
        return `${value["familyName"]} ${value["givenName"]}`;
      }else{
        return value["patientName"];
      }
    }
    const searchResults = allPatients.filter(value => {
      const searchedValue = getSearchValue(value);
      return searchedValue.toLowerCase().match(e.toLowerCase());
    });

    setPatients(searchResults);
  };

  return (
    <>
      <ClinicAdminSideNav />
      <div style={mainDivStyle}>
        <Grid container>
          <Grid container justifyContent="flex-end" paddingTop="10px" paddingBottom="10px">
            <Grid item xs={12} sm={12} md={7} lg={7}>
              {
                isStatusLoading===false ? 
                <div style={DiagnosisScrollContainer}>
                <IconButton style={{ padding: "10px" }} onClick={scrollLeft}>
                  <TagsButtonIcon rotate="180deg" />
                </IconButton>
                <div style={TagsScrollContainerItem} ref={scrollContainerRef}>
                {
                  allDiagnoses.map((d,i)=>{
                    return(
                      <Chip key={i} label={d} onClick={()=>readDiagnoses(d)}  
                      style={
                        selectedDiagnoses.includes(d) ? selectedTag : defaultTag
                      }
                      />
                    )
                  })
                }
                </div>
                <IconButton style={{ padding: "10px" }} onClick={scrollRight}>
                  <TagsButtonIcon />
                </IconButton>
              </div>
              :
              <div style={DiagnosisScrollContainer}>
                <IconButton style={{ padding: "10px" }} onClick={scrollLeft}>
                  <TagsButtonIcon rotate="180deg" />
                </IconButton>
                <div style={TagsScrollContainerItem} ref={scrollContainerRef}>
                {
                  new Array(30).fill("",0,30).map((d,i)=>{
                    return(
                      <Skeleton
                      key={i}
                      animation="wave"
                      variant="text"
                      style={{display:"inline-flex",marginRight:"10px"}}
                      width={45}
                      height={50}
                    />
                    )
                  })
                }
                </div>
                <IconButton style={{ padding: "10px" }} onClick={scrollRight}>
                  <TagsButtonIcon />
                </IconButton>
              </div>
              }
              
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <SearchBar placeHolder={"Search patient"} filterFunction={filterFunction} bgColor={"#FFFFFF"}/>
            </Grid>
            <Grid item xs={6} sm={6} md={1} lg={1}>
              <Button
                  onClick={handleClick}
                  style={{
                    ...selectedFilter === "" ? FilterButtonNotSelected : FilterButton,
                    ...{ marginRight: 0 }
                  }}
                  startIcon={
                    <FilterIcon
                      iconColor={selectedFilter === "" ? "#E24020" : "white"}
                    />
                  }
                >
                  Filter
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {
                  Object.values(FILTER_TYPES).map((item, index) =>{
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => filterPatient(item)}
                        style={{
                          backgroundColor: selectedFilter === item && "#FCE8E4",
                        }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })
                }
              </Popover>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <PatientTable
              patientDetails={patients}
              highPatients={highPatients}
              lowPatients={lowPatients}
              idlePatients={idlePatients}
              activePatients={activePatients}
              statusType={selectedFilter === "" ? FILTER_TYPES.all : selectedFilter}
              sixteenPlusUsagePatient={sixteenPlusUsagePatientIds}
              isLoading={loading}
              isStatusLoading={isStatusLoading}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
