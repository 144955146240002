import { Divider } from "@mui/material";
import React from "react";

export function CustomDivider(props) {
  return (
    <Divider
      style={{
        width: props.width,
        marginBottom: props.mb,
        marginTop: props.mt,
        marginLeft: props.ml,
        marginRight: props.mr,
      }}
    ></Divider>
  );
}
