import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AdminIcon, LogoutIcon, SettingsIcon } from "../Icons/Icons";
import { SuperAdminTopNav } from "./SuperAdminTopNav";

const drawerWidth = 240;

export default function SuperAdminSideNav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabName, settabName] = useState("/");
  const listItems = [
    {
      name: "Admin",
      route: "/super/dashboard",
    },
  ];

  const bottomListItems = [
    {
      name: "Logout",
      route: "/",
    },
  ];
  useEffect(() => {
    settabName(location.pathname);
  }, [location.pathname]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SuperAdminTopNav
        drawerWidth={drawerWidth}
        filterFunction={(e) => props.filterFunction(e)}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderWidth: "0px",
          },
        }}
        variant="permanent"
        anchor="left"
        elevation={0}
      >
        <Toolbar style={{ paddingLeft: "0px" }}>
          <img
            src={require("../Images/logo.png")}
            style={{ width: "95px" }}
            alt="logo"
          />
        </Toolbar>
        <List style={{ paddingTop: "30px" }}>
          {listItems.map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              style={{
                backgroundColor:
                  tabName === text.route ||
                  tabName.substring(0, 8) === text.route
                    ? "rgba(226, 64, 32, 0.1)"
                    : "white",
                marginLeft: "10px",
                width: "90%",
                borderRadius: "6px",
              }}
            >
              <ListItemButton
                component={Link}
                to={text.route}
                onClick={() => {
                  settabName(text.route);
                }}
              >
                {text.route === "/super/dashboard" && (
                  <AdminIcon
                    color={
                      tabName === text.route ||
                      tabName.substring(0, 8) === text.route
                        ? "#E24020"
                        : "#5F6165"
                    }
                  />
                )}
                <Typography
                  variant="sideNavFont"
                  style={{
                    paddingLeft: "20px",
                    color:
                      tabName === text.route ||
                      tabName.substring(0, 8) === text.route
                        ? "#E24020"
                        : "#5F6165",
                  }}
                >
                  {text.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List style={{ position: "absolute", bottom: 0, width: "90%" }}>
          {bottomListItems.map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              style={{
                backgroundColor:
                  tabName === text.route ? "rgba(226, 64, 32, 0.1)" : "white",
                marginLeft: "10px",
                width: "100%",
                borderRadius: "6px",
              }}
            >
              <ListItemButton
                component={Link}
                to={text.route}
                onClick={() => {
                  if (text.route === "/") {
                    localStorage.clear();
                    navigate("/");
                  }
                  settabName(text.route);
                }}
              >
                {text.route === "/settings" && (
                  <SettingsIcon
                    color={tabName === text.route ? "#E24020" : "#5F6165"}
                  />
                )}
                {text.route === "/" && (
                  <LogoutIcon
                    color={tabName === text.route ? "#E24020" : "#5F6165"}
                  />
                )}
                <Typography
                  variant="sideNavFont"
                  style={{
                    paddingLeft: "20px",
                    color: tabName === text.route ? "#E24020" : "#5F6165",
                  }}
                >
                  {text.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      ></Box>
    </Box>
  );
}
