import React, { useState } from "react";
import { IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { ExerciseVideoIcon, RecordNoteIcon, ViewEyeIcon } from "../Icons/Icons";
import { convertTo12HourTime, dateFormatter, getRecordColor } from "../Utils/Helpers";

function PatientRecordTable(props) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function getRecordIcon(type, isRecordExerciseVideo = false) {
        if (type === "record" && isRecordExerciseVideo) {
            return <ExerciseVideoIcon/>;
        } else {
            return <RecordNoteIcon iconColor={getRecordColor(type)}/>;
        }
    }

    function getRecordName(type) {
      if (type === "record") {
          return "Exercise";
      } else if (type === "diet") {
          return "Diet";
      } else if (type === "vitals") {
          return "Vitals";
      } else if (type === "medication") {
          return "Medications";
      } else if (type === "symptoms") {
          return "Symptoms";
      }
  }


    return <>
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ padding:"0px" }}>
            <TableRow>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Record Type</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Date</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Time </Typography>
              </StyledTableCell>
              <StyledTableCell align="left"/>
              <StyledTableCell align="left"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.patientRecords && props.patientRecords.length > 0 ? (
              props.patientRecords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record, i) => (
                  <StyledTableRow color={getRecordColor(record.type)} key={i}>
                    <StyledTableCell component="th" scope="column">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="tableBody" paddingLeft={record?.feedback ? "8px" : "10px"} paddingRight={"10px"}>
                                {getRecordIcon(record.type, record?.feedback ? true : false)}
                            </Typography>
                            <Typography variant="tableBody" style={{ paddingBottom:"5px" }}>
                                {getRecordName(record.type)}
                            </Typography>
                        </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {dateFormatter(record.uploadedDate)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {convertTo12HourTime(record.uploadedDate.split(" ")[1])}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        <IconButton style={{padding: "5px"}} onClick={() => props.onViewRecord(record)}>
                          <ViewEyeIcon/>
                        </IconButton>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell/>
                  </StyledTableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
                >
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={
          props.patientRecords && props.patientRecords.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFFFFF",
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: 12,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontWeight: 400,
      color: "#000000",
    },
  }));
  
const StyledTableRow = styled(TableRow)((props) => ({
    "&:nth-of-type(odd)": {
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1",
        borderLeft: `10px solid ${props.color}`,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:nth-of-type(even)": {
        borderLeft: `10px solid ${props.color}`
    },
  }));

export default PatientRecordTable;