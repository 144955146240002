import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
} from "../Theme/Theme";
import { CloseIcon } from "../Icons/Icons";
import { getAccessToken } from "../Auth/Auth";
import { deleteBulkMedications } from "../Api/Api";
import { toast } from "react-toastify";

export function DeleteMedication(props) {
  const close = (status) => {
    props.handleClose(status);
  };

  const deleteMedications = async () => {
    const token = getAccessToken();
    const data = props.selectedItems;
    const result = await deleteBulkMedications(token, data);
    if (result.message === "Success") {
      close(true);
      toast.success("Medication deleted successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      toast.error(result.message);
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          Delete Medication ?
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle1"
            style={{ color: "rgba(113, 113, 113, 1)", fontWeight: 400 }}
          >
            Deleting Medication will permanently remove it from your panel.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={close}
          >
            No, Keep Medication
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={SubmitButton}
            autoFocus
            onClick={deleteMedications}
          >
            Yes, Delete Medication
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
