import { Grid, Typography } from "@mui/material";
import React from "react";

export function CsvLoadingScreen(props) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        style={{ textAlign: "center" }}
      >
        <img
          src={require("../Images/Loading.gif")}
          alt="loading"
          style={{ width: "80%" }}
        />
        <Typography
          style={{
            color: "#898989",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "143.4%",
            paddingBottom: "25px",
          }}
        >
          Your file is Uploading Please Wait ...
        </Typography>
      </Grid>
    </Grid>
  );
}
