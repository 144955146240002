import React from "react";
import { SearchBar } from "../InputComponents/SearchBar";
import { AppBar, Grid } from "@mui/material";

export function TopNav(props) {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${props.drawerWidth}px)`,
        ml: `${props.drawerWidth}px`,
      }}
      style={{ backgroundColor: "white" }}
      elevation={0}
    >
      <Grid container style={{ padding: "15px", paddingRight: "0px" }}>
        <Grid item xs={6} md={3} style={{ paddingTop: "8px" }}>
          {props.tabName !== "/dashboard" && (
            <SearchBar placeHolder={"Search or type"} filterFunction={(e) => props.filterFunction(e)} bgColor={"#F5F4F6"} />
          )}
        </Grid>
        <Grid item md={7}></Grid>
        <Grid
          item
          xs={3}
          md={1}
          style={{
            textAlign: "right",
            display: "flex",
            paddingRight: "8px",
            justifyContent: "right",
            alignItems: "right",
            cursor: "pointer",
            paddingTop: "12px",
          }}
        >
          {/* <NotificationIcon /> */}
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
          style={{
            paddingLeft: "8px",
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="profile"
            width={"40px"}
            height={"40px"}
            src={require("../Images/testuser.png")}
            style={{ borderRadius: "100%" }}
          />
        </Grid>
      </Grid>
    </AppBar>
  );
}
