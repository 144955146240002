import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DialogBackground,
  DialogTitleStyle,
  FileUploadDiv,
  SubmitButton,
  helperText,
} from "../Theme/Theme";
import { CloseIcon, UploadIcon } from "../Icons/Icons";
import { TextInput } from "../InputComponents/TextInput";
import { getAccessToken, getAdminId, getRole } from "../Auth/Auth";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import {
  addTherapist,
  getOneTherapist,
  updateTherapist,
  uploadThumbnail,
} from "../Api/Api";
import { CustomDropDown } from "../InputComponents/CustomDropDown";

export function AddtherapistForm(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [profilePic, setProfilePic] = useState();
  const [userType, setUserType] = useState("");
  const [nameError, setNameError] = useState({
    status: false,
    msg: "",
  });
  const [emailError, setEmailError] = useState({
    status: false,
    msg: "",
  });
  const [contactError, setContactError] = useState({
    status: false,
    msg: "",
  });
  const [profilePicError, setProfilePicError] = useState({
    status: false,
    msg: "",
  });
  const [userTypeError, setUserTypeError] = useState({
    status: false,
    msg: "",
  });

  const [profilePicAdded, setProfilePicAdded] = useState(false);
  const [change, setChange] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const readName = (e) => {
    setChange(true);
    setNameError({
      status: false,
      msg: "",
    });
    setName(e);
  };
  const readEmail = (e) => {
    setChange(true);
    setEmailError({ status: false, msg: "" });
    setEmail(e);
  };
  const readContact = (e, k) => {
    setChange(true);
    let no = e.toLowerCase();
    if (no.startsWith("-")) {
      return;
    }
    if (no.includes("--")) {
      return;
    }
    if (/^[0-9-]+$/i.test(no) === false && no !== "") {
      return;
    }
    if (
      no.length === 3 &&
      k.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      no = no + "-";
    }
    if (
      no.length === 7 &&
      k.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      no = no + "-";
    }
    if (no.length > 12) {
      return;
    }
    setContactError({ status: false, msg: "" });
    setContact(no);
  };

  const readUserType = (e) => {
    setChange(true);
    setUserTypeError({ status: false, msg: "" });
    setUserType(e.target.value);
  };
  const readProfilePic = (e) => {
    if (e[0].type.includes("image")) {
      setChange(true);
      setProfilePicAdded(true);
      setProfilePicError({ status: false, msg: "" });
      setProfilePic(e[0]);
      setChange(true);
    }
  };

  const close = (status) => {
    setName("");
    setEmail("");
    setContact("");
    setProfilePic();
    setUserType("");
    setNameError({ status: false, msg: "" });
    setEmailError({ status: false, msg: "" });
    setContactError({ status: false, msg: "" });
    setProfilePicError({ status: false, msg: "" });
    setUserTypeError({ status: false, msg: "" });
    setProfilePicAdded(false);
    setChange(false);
    setUploadedUrl("");

    props.handleClose(status);
  };

  const addNewTherapist = async () => {
    const token = getAccessToken();
    let error = false;
    if (name === "") {
      setNameError({ status: true, msg: "Therapist name is required." });
      error = true;
    }
    if (props.type !== "EDIT" && email === "") {
      setEmailError({ status: true, msg: "Email is Required." });
      error = true;
    }
    if (props.type !== "EDIT" && email !== "" && email.indexOf("@") === -1) {
      setEmailError({ status: true, msg: "Invalid Email." });
      error = true;
    }
    if (contact === "") {
      setContactError({ status: true, msg: "Contact No is Required." });
      error = true;
    }
    if (props.type !== "EDIT" && userType === "") {
      setUserTypeError({ status: true, msg: "User role is Required." });
      error = true;
    }
    if (contact !== "" && contact.length < 10) {
      setContactError({ status: true, msg: "Invalid Contact No." });
      error = true;
    }
    if (error) {
      return;
    }

    let file;
    if (profilePic !== undefined) {
      file = await uploadThumbnail(profilePic);
    }

    const data = {
      clinicId: getAdminId(),
      email: email,
      therapistName: name,
      contactNO: contact,
      therapistsType: userType,
      profilePicURL: file !== undefined ? file : undefined,
    };

    if (props.type !== "EDIT") {
      const result = await addTherapist(token, data);
      if (result.message === "Success") {
        toast.success("User created successfully", {
          style: {
            background: "#4F9E4E",
          },
        });
        close();
      }
    } else {
      const result = await updateTherapist(token, data, props.id);
      if (result.message === "Success") {
        toast.success("User updated successfully", {
          style: {
            background: "#4F9E4E",
          },
        });
        close();
      }
    }
  };

  const getOneTherapistDetails = async (token, therapistId) => {
    const result = await getOneTherapist(token, therapistId);
    if (result.message === "Success") {
      setName(result.content.therapistName);
      setContact(result.content.contactNO);
      setEmail(result.content.email);
      if (result.content.profilePicURL !== undefined) {
        setUploadedUrl(result.content.profilePicURL);
      }
      setUserType(result.content.therapistsType);
    }
  };

  useEffect(() => {
    if (props.type === "EDIT" || props.type === "VIEW") {
      const token = getAccessToken();
      getOneTherapistDetails(token, props.id);
    }
  }, [props.open]);
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          {props.type === "EDIT"
            ? "Edit "
            : props.type === "VIEW"
            ? "View"
            : "New "}{" "}
          User
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle2"
            style={{
              color: "rgba(36, 39, 49, 1)",
              paddingBottom: "5px",
              display: "inline",
            }}
          >
            Name
          </DialogContentText>
          <TextInput
            value={name}
            error={nameError.status}
            onChange={(e) => readName(e.target.value)}
            fullWidth
            style={{ paddingBottom: "10px" }}
            placeholder="Enter Therapist Name"
            disabled={props.type === "VIEW"}
          />
          <Typography style={helperText}>{nameError.msg}</Typography>

          {props.type !== "EDIT" && (
            <>
              <DialogContentText
                variant="subtitle2"
                style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
              >
                Email
              </DialogContentText>
              <TextInput
                error={emailError.status}
                value={email}
                onChange={(e) => readEmail(e.target.value)}
                placeholder="Enter Email"
                fullWidth
                style={{ paddingBottom: "10px" }}
                disabled={props.type === "VIEW"}
              />
              <Typography style={helperText}>{emailError.msg}</Typography>
            </>
          )}

          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            Contact No
          </DialogContentText>
          <TextInput
            type="text"
            onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            error={contactError.status}
            value={contact}
            onChange={(e) => readContact(e.target.value, e)}
            placeholder="Enter Contact no"
            fullWidth
            style={{ paddingBottom: "10px" }}
            disabled={props.type === "VIEW"}
          />
          <Typography style={helperText}>{contactError.msg}</Typography>

          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            User Role
          </DialogContentText>
          <FormControl fullWidth>
            {isFocused ||
              (userType === "" && props.type !== "" && (
                <InputLabel
                  style={{
                    fontSize: "14px",
                    color: "rgba(159, 159, 159, 0.6)",
                  }}
                >
                  Choose Role
                </InputLabel>
              ))}

            <CustomDropDown
              onChange={readUserType}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={userType}
              disabled={props.type === "VIEW"}
            >
              {parseInt(getRole()) === 1
                ? [
                    <MenuItem key="clinic_admin" value={"CLINIC_ADMIN"}>
                      Clinic Admin
                    </MenuItem>,
                    <MenuItem key="therapist" value={"THERAPIST"}>
                      Therapist
                    </MenuItem>,
                    <MenuItem key="reporting_staff" value={"REPORTING_STAFF"}>
                      Reporting Staff
                    </MenuItem>,
                  ]
                : parseInt(getRole()) === 6
                ? [
                    <MenuItem key="therapist" value={"THERAPIST"}>
                      Therapist
                    </MenuItem>,
                    <MenuItem key="reporting_staff" value={"REPORTING_STAFF"}>
                      Reporting Staff
                    </MenuItem>,
                  ]
                : null}
            </CustomDropDown>
          </FormControl>
          <Typography style={helperText}>{userTypeError.msg}</Typography>

          {props.type !== "VIEW" && (
            <>
              <DialogContentText
                variant="subtitle2"
                style={{
                  color: "rgba(36, 39, 49, 1)",
                  paddingBottom: "5px",
                  display: "inline",
                }}
              >
                Upload Profile Image
              </DialogContentText>
              <Typography style={helperText}>{}</Typography>
              <Dropzone
                onDrop={(acceptedFiles) => readProfilePic(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={FileUploadDiv}>
                      <input {...getInputProps()} />

                      {props.type === "EDIT" &&
                      uploadedUrl !== "" &&
                      uploadedUrl !== null &&
                      profilePicAdded === false ? (
                        <img
                          src={uploadedUrl}
                          width="50%"
                          alt="thumbnail"
                          style={{ borderRadius: "8px" }}
                        />
                      ) : (
                        <UploadIcon />
                      )}

                      <br />
                      {profilePic === null || profilePic === undefined ? (
                        <>
                          <Typography variant="subtitle2">
                            Drag & Drop
                          </Typography>
                          <Typography variant="fileUploadBoxSubText">
                            or Select files from device
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="fileUploadBoxSubText">
                          {profilePic.name}
                        </Typography>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <Typography style={helperText}>{profilePicError.msg}</Typography>
            </>
          )}

          {props.type === "VIEW" && (
            <>
              <DialogContentText
                variant="subtitle2"
                style={{
                  color: "rgba(36, 39, 49, 1)",
                  paddingBottom: "5px",
                  display: "inline",
                }}
              >
                Profile Image
              </DialogContentText>
              <Typography style={helperText}>{}</Typography>
              <div style={FileUploadDiv}>
                <img
                  src={uploadedUrl}
                  width="50%"
                  alt="thumbnail"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={close}
          >
            Cancel
          </Button>
          {props.type !== "VIEW" && (
            <Button
              color="primary"
              variant="contained"
              style={SubmitButton}
              autoFocus
              disabled={change === false && true}
              onClick={addNewTherapist}
            >
              {props.type === "EDIT" ? "Edit " : "Add "} User
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
