import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DeleteIcon, EditIcon, ViewIcon } from "../Icons/Icons";
import { IconButton, TablePagination, Typography } from "@mui/material";
import { useState } from "react";
import { DeleteTherapist } from "./DeleteTherapist";
import { deleteOneTherapist } from "../Api/Api";
import { toast } from "react-toastify";

export default function TherapistTable(props) {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteTherapist = async (token, therapistId) => {
    const result = await deleteOneTherapist(token, therapistId);
    if (result.message === "Success") {
      toast.success("Therapist deleted successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
      props.handleClose();
    } else {
      toast.error("Therapist delete failed");
    }
  };
  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">User Name</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Joined Date </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">User Role</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Last Activity</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="tableHeader">Contact no</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                {" "}
                <Typography variant="tableHeader"></Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.therapistDetails !== undefined &&
            props.therapistDetails.length > 0 ? (
              props.therapistDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((therapist, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt="profile"
                          width={"30px"}
                          height={"30px"}
                          src={
                            therapist.profilePicURL === null
                              ? require("../Images/testuser.png")
                              : therapist.profilePicURL
                          }
                          style={{ borderRadius: "100%", marginRight: "20px" }}
                        />
                        <Typography variant="tableBody">
                          {therapist.therapistName}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {new Date(therapist.joinedDate)
                          .getDate()
                          .toString()
                          .padStart(2, "0") +
                          "/" +
                          (new Date(therapist.joinedDate).getMonth() + 1)
                            .toString()
                            .padStart(2, "0") +
                          "/" +
                          new Date(therapist.joinedDate)
                            .getFullYear()
                            .toString()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {therapist.therapistsType === "CLINIC_ADMIN"
                          ? "Clinic Admin"
                          : therapist.therapistsType === "REPORTING_STAFF"
                          ? "Reporting Staff"
                          : "Therapist"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {therapist.lastActivity === null
                          ? "-"
                          : new Date(therapist.lastActivity)
                              .getDate()
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            (new Date(therapist.lastActivity).getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "/" +
                            new Date(therapist.lastActivity)
                              .getFullYear()
                              .toString()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {therapist.contactNO}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={() => props.handleOpen(therapist.id, "VIEW")}
                      >
                        <ViewIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => props.handleOpen(therapist.id, "EDIT")}
                      >
                        <EditIcon />
                      </IconButton>
                      <DeleteTherapist
                        therapistId={therapist.id}
                        title={"Delete Therapist"}
                        sentence2="Deleting a Therapist will permanently remove it from your panel."
                        icon={DeleteIcon()}
                        deleteTherapist={(token, therapistId) =>
                          deleteTherapist(token, therapistId)
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
                >
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={
          props.therapistDetails !== undefined && props.therapistDetails.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.5)",
    paddingTop: "30px",
    fontSize: 12,
    fontWeigth: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeigth: 400,
    color: "#000000",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
