import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Typography,
    DialogActions,
    Button,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import {
    DialogBackground,
    DialogTitleStyle,
    SubmitButton,
    helperText,
  } from "../Theme/Theme";
  import { CloseIcon } from "../Icons/Icons";
  import { TextInput } from "../InputComponents/TextInput";
  import {  editDiagnosisOnly, getPatient } from "../Api/Api";
  import { getAccessToken } from "../Auth/Auth";
  import { toast } from "react-toastify";
  
  export function DiagnosisPopUp(props) {
    const [diagnosis, setDiagnosis] = useState("");
    const [diagnosisError, setDiagnosisError] = useState({
      status: false,
      msg: "",
    });
    const [disabled,setDisabled] = useState(true)
  
    const readDiagnosis = (e) => {
      setDisabled(false)  
      setDiagnosisError({
        status: false,
        msg: "",
      });
      setDiagnosis(e);
    };
    const close = (status) => {
      setDiagnosisError({ status: false, msg: "" });
      props.handleClose(status);
    };
  
    const editDiagnosis = async () => {
      if (diagnosis === "") {
        setDiagnosisError({ status: true, msg: "Diagnosis is required." });
        return;
      }
      const token = getAccessToken();
      const data = { diagnosis: [diagnosis] };
      const result = await editDiagnosisOnly(props.patientId,data,token);
      if (result.message === "Success") {
        close(true);
        toast.success("Diagnosis updated successfully", {
          style: {
            background: "#4F9E4E",
          },
        });
      } else {
        toast.error(result.message);
      }
    };
    useEffect(()=>{
        const token = getAccessToken()
        async function fetchPatient() {
            const result = await getPatient(props.patientId, token);
            if (result.message === "Success") {
              setDiagnosis(Array.isArray(result.content.diagnosis) && result.content.diagnosis.length > 0 ? result.content.diagnosis[0] : "");

            }
        }

        fetchPatient()
    },[props.open])
    return (
      <>
        <Dialog
          open={props.open}
          onClose={close}
          maxWidth="sm"
          fullWidth={true}
          style={DialogBackground}
        >
          <DialogTitle style={DialogTitleStyle}>
            Edit Diagnosis
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextInput
              value={diagnosis}
              error={diagnosisError.status}
              onChange={(e) => readDiagnosis(e.target.value)}
              fullWidth
              style={{ paddingBottom: "10px" }}
              placeholder="Enter Medication Name"
            />
            <Typography style={helperText}>{diagnosisError.msg}</Typography>
          </DialogContent>
          <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              style={SubmitButton}
              autoFocus
              onClick={editDiagnosis}
              disabled={disabled}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  