import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DialogBackground,
  DialogTitleStyle,
  SubmitButton,
  helperText,
} from "../Theme/Theme";
import { CloseIcon } from "../Icons/Icons";
import { TextInput } from "../InputComponents/TextInput";
import { editMedication, getOneMedication } from "../Api/Api";
import { getAccessToken } from "../Auth/Auth";
import { toast } from "react-toastify";

export function EditMedicationForm(props) {
  const [medication, setMedication] = useState("");
  const [medicationError, setMedicationError] = useState({
    status: false,
    msg: "",
  });
  const [typeStarted, setTypeStarted] = useState(false); //check whether user start typing or not

  const readMedication = (e) => {
    setTypeStarted(true);
    setMedicationError({
      status: false,
      msg: "",
    });
    setMedication(e);
  };
  const close = (status) => {
    setMedication("");
    setTypeStarted(false);
    setMedicationError({
      status: false,
      msg: "",
    });
    props.handleClose(status);
  };

  const editMedicationName = async () => {
    if (medication === "") {
      setMedicationError({ status: true, msg: "Medication Name is required." });
      return;
    }
    const token = getAccessToken();
    const medicationId = props.selectedItems[0];
    const data = { name: medication };
    const result = await editMedication(token, medicationId, data);
    if (result.message === "Success") {
      close(true);
      toast.success("Medication updated successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      toast.error(result.message);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    const medicationId = props.selectedItems[0];
    const getOne = async () => {
      const result = await getOneMedication(token, medicationId);
      if (result.message === "Success") {
        setMedication(result.content.name);
      }
    };
    if (props.selectedItems.length === 1) {
      getOne();
    }
  }, [props.open]);
  return (
    <>
      <Dialog
        open={props.open}
        onClose={close}
        maxWidth="sm"
        fullWidth={true}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle}>
          Edit Medication
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle2"
            style={{
              color: "rgba(36, 39, 49, 1)",
              paddingBottom: "5px",
              display: "inline",
            }}
          >
            Medication Name
          </DialogContentText>
          <TextInput
            value={medication}
            error={medicationError.status}
            onChange={(e) => readMedication(e.target.value)}
            fullWidth
            style={{ paddingBottom: "10px" }}
            placeholder="Enter Medication Name"
          />
          <Typography style={helperText}>{medicationError.msg}</Typography>
        </DialogContent>
        <DialogActions style={{ marginRight: "20px", marginBottom: "20px" }}>
          <Button
            color="customGray"
            style={{ textTransform: "none" }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={SubmitButton}
            autoFocus
            onClick={editMedicationName}
            disabled={!typeStarted}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
