import { Grid, Typography } from "@mui/material";
import React from "react";

export function CheckEmail(props) {
  return (
    <Grid container>
      <Grid item xs={1} md={4}></Grid>
      <Grid item xs={10} md={4} style={{ textAlign: "center" }}>
        <Grid container style={{ paddingTop: "30%" }}>
          <Grid item xs={12} md={12}>
            <img
              style={{ width: "110px" }}
              src={require("../../Images/logo.png")}
              alt="logo"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: "center",
              border: "1px solid #F4F5F6",
              boxShadow: "0px 10px 110px 1px rgba(59, 59, 59, 0.08)",
              marginTop: "20px",
              paddingTop: "20px",
              padding: "15px",
              borderRadius: "10px",
            }}
          >
            <Typography variant="subtitle2">
              Password reset email is sent successfully! <br />
              Please check your inbox to reset.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
            <Typography
              variant="subtitle2"
              style={{
                color: "#777E91",
                paddingTop: "10px",
                paddingBottom: "30px",
              }}
            >
              <a href="/" style={{ textDecoration: "none" }}>
                <span style={{ color: "#E24020", cursor: "pointer" }}>
                  Back to Login
                </span>
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
