import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { StarIcon, TooltipIcon } from "../Icons/Icons";
import styled from "@emotion/styled";

export function RecordCard(props) {
  var name = props.name;
  var nameAndIcon = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ marginRight: "10px" }}>{StarIcon()}</div>
      <div>{props.name}</div>
    </div>
  );

  var temp = props.icon === false ? name : nameAndIcon;
  var sentence =
    props.icon === false
      ? ""
      : "Number of patients who did exercise for atleast 16 days per month.";
  return (
    <Grid
      container
      style={{
        backgroundColor: "white",
        width: "90%",
        paddingTop: "20px",
        textAlign: "center",
        margin: "10px",
        borderLeft: `6px solid ${props.color}`,
        borderRadius: "4px",
        boxShadow: "0px 10px 100px rgba(0, 0, 0, 0.04)",
        minHeight: "180px",
        maxHeight: "180px",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{ minHeight: props.icon === true && "45px" }}
      >
        <Typography variant="cardTopicFont" component={"span"}>
          {temp}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="cardNoFont">{props.number}</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12} style={{ textAlign: "right" }}>
        {props.icon === true && (
          <CutomToolTip title={sentence} placement="left-start">
            <IconButton>
              <TooltipIcon />
            </IconButton>
          </CutomToolTip>
        )}
      </Grid>
    </Grid>
  );
}

const CutomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    color: #5F6165;
    background-color: white;
    width:150px
`);
