import React, { useEffect, useState } from "react";
import SuperAdminSideNav from "../UIComponents/SuperAdminSideNav";
import { FloatingButtonDiv, mainDivStyle } from "../Theme/Theme";
import {
  Fab,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { DeleteAdmin, FloatingButtonAddIcon } from "../Icons/Icons";
import { StyledTableCell, StyledTableRow } from "../UIComponents/ClinicTable";
import CreateNewAdminForm from "../UIComponents/CreateNewAdminForm";
import { addNewAdminAccount, getAlladmins } from "../Api/Api";
import { getAccessToken } from "../Auth/Auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminDelete } from "../UIComponents/AdminDelete";

export function SuperAdminDashboard(props) {
  const [openNew, setOpenNew] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [adminId, setAdminId] = useState("");

  const [admins, setAdmins] = useState([]);
  const [constAdmins, setConstAdmins] = useState([]);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ status: false, msg: "" });

  const readEmail = (e) => {
    setEmailError({ status: false, msg: "" });
    setEmail(e);
  };

  const addNewAdmin = async () => {
    let error = false;
    if (email === "") {
      setEmailError({ status: true, msg: "Email is Required." });
      error = true;
    }
    if (email !== "" && email.indexOf("@") === -1) {
      setEmailError({ status: true, msg: "Invalid Email." });
      error = true;
    }
    if (error) {
      return;
    }
    const data = {
      admin: {
        email: email,
        clinics: [],
      },
      host: process.env.REACT_APP_FE_URL,
    };
    const result = await addNewAdminAccount(getAccessToken(), data);
    if (result.message === "Success") {
      setOpenNew(false);
      setEmail("");
      toast.success("Admin created successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
      getAllAdminsDetails();
    } else {
      toast.error(result.message);
    }
  };

  const handleOpenNew = () => {
    setOpenNew(true);
  };
  const handleCloseNew = () => {
    setEmail("");
    setEmailError({ status: false, msg: "" });
    setOpenNew(false);
  };
  const handleOpenDelete = (id) => {
    setAdminId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setAdminId("");
    setOpenDelete(false);
    getAllAdminsDetails();
  };

  const filterAdmins = (e) => {
    let filetedAdins = [];
    constAdmins.filter((obj) => {
      if (
        (obj.firstName + " " + obj.lastName)
          .toLowerCase()
          .includes(e.trim().toLowerCase())
      ) {
        filetedAdins.push(obj);
      }
      return filetedAdins;
    });
    setAdmins(filetedAdins);
  };

  const getAllAdminsDetails = async () => {
    const addmins = await getAlladmins(getAccessToken());
    setAdmins(addmins);
    setConstAdmins(addmins);
  };
  useEffect(() => {
    getAllAdminsDetails();
  }, []);
  return (
    <>
      <SuperAdminSideNav filterFunction={(e) => filterAdmins(e)} />
      <div style={mainDivStyle}>
        {admins.length === 0 ? (
          <Grid
            container
            style={{
              paddingTop: "50px",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
            >
              <Typography style={{ margin: "auto" }}>No data</Typography>
            </Grid>
          </Grid>
        ) : (
          <TableContainer component={Paper} style={{ marginTop: "10px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    <Typography variant="tableHeader">
                      User Email Address
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="tableHeader">User Name</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="tableHeader">Actions</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((admin, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      <Typography variant="tableBody">{admin.email}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableBody">
                        {admin.firstName !== null && admin.firstName + " "}
                        {admin.lastName !== null && admin.lastName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <IconButton onClick={() => handleOpenDelete(admin.id)}>
                        <DeleteAdmin />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div style={FloatingButtonDiv}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{ boxShadow: "none" }}
            onClick={() => handleOpenNew()}
          >
            <FloatingButtonAddIcon />
          </Fab>
        </div>
      </div>

      <CreateNewAdminForm
        open={openNew}
        handleClose={() => handleCloseNew()}
        readEmail={(e) => readEmail(e)}
        emailError={emailError}
        submit={addNewAdmin}
        email={email}
      />
      <AdminDelete
        open={openDelete}
        adminId={adminId}
        handleClose={() => handleCloseDelete()}
      />
    </>
  );
}
