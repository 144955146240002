import { Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { requestForResetPassword } from "../../Api/Api";
import { TextInput } from "../../InputComponents/TextInput";
import { CenterGrid, helperText } from "../../Theme/Theme";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CheckEmail } from "./CheckEmail";

export function RequestPasswordReset(props) {
  const [status, setStatus] = useState(0);
  const [emailError, setEmailError] = useState({
    status: false,
    msg: "",
  });
  const [email, setEmail] = useState("");

  const readEmail = (e) => {
    setEmailError({ status: false, msg: "" });
    setEmail(e);
  };

  const submit = async () => {
    let error = false;
    if (email === "") {
      setEmailError({ status: true, msg: "Email is Required." });
      error = true;
    }
    if (email !== "" && email.indexOf("@") === -1) {
      setEmailError({ status: true, msg: "Invalid Email" });
      error = true;
    }
    if (error) {
      return;
    }
    const data = {
      host: process.env.REACT_APP_FE_URL,
      email: email,
    };
    const result = await requestForResetPassword(data);
    if (result === 200) {
      localStorage.clear();
      setStatus(1);
    }
    if (result !== 200) {
      console.log(result);
      toast.error(result.message);
    }
  };
  return (
    <div style={CenterGrid}>
      {status === 0 ? (
        <Grid container>
          <Grid item xs={1} md={4}></Grid>
          <Grid item xs={10} md={4} style={{ textAlign: "center" }}>
            <Grid container style={{ paddingTop: "30%" }}>
              <Grid item xs={12} md={12}>
                <img
                  style={{ width: "110px" }}
                  src={require("../../Images/logo.png")}
                  alt="logo"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  textAlign: "left",
                  border: "1px solid #F4F5F6",
                  boxShadow: "0px 10px 110px 1px rgba(59, 59, 59, 0.08)",
                  marginTop: "20px",
                  paddingTop: "20px",
                  padding: "15px",
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                }}
              >
                <Typography variant="subtitle2">Email</Typography>
                <TextInput
                  error={emailError.status}
                  onChange={(e) => readEmail(e.target.value)}
                  fullWidth
                  style={{ marginTop: "5px", marginBottom: "10px" }}
                />
                <Typography style={helperText}>{emailError.msg}</Typography>
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Button
                  style={{
                    width: "100%",
                    boxShadow: "none",
                    marginTop: "10px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={submit}
                >
                  Submit
                </Button>

                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#777E91",
                    paddingTop: "10px",
                    paddingBottom: "30px",
                  }}
                >
                  <a href="/" style={{ textDecoration: "none" }}>
                    <span style={{ color: "#E24020", cursor: "pointer" }}>
                      Back to Login
                    </span>
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <CheckEmail />
      )}
    </div>
  );
}
