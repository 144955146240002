import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { mainDivStyle } from "../Theme/Theme";
import { RateCard } from "../UIComponents/RateCard";
import { RecordCard } from "../UIComponents/RecordCard";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllTherapists,
  getClinicInfo,
  getClinicPatientOverView,
  getPatientsOfClinic,
  getSixteenPlusPatients,
} from "../Api/Api";
import {
  getAccessToken,
  getAdminId,
  getRole,
  setClinicAdminId,
  setExpireTime,
} from "../Auth/Auth";
import TherapistTable from "../UIComponents/TherapistTable";
import { AddtherapistForm } from "../UIComponents/AddNewTherapist";
import PatientTable from "../UIComponents/PatientTable";
import { getToday } from "../Utils/Helpers";
import { FILTER_TYPES } from "../Utils/Enums";

export function ClinicAdminDashboard(props) {
  const [totalTherapist, setTotalTherapist] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);

  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [idle, setIdle] = useState(0);

  const [therapitsDetails, setTherapistDetails] = useState([]);

  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState("");
  const [id, setId] = useState("");

  const [patients, setPatients] = useState([]);
  const [highPatients, setHighPatients] = useState([]);
  const [lowPatients, setLowPatients] = useState([]);
  const [idlePatients, setIdlePatients] = useState([]);
  const [activePatients, setActivePatients] = useState([]);
  const [sixteenPlusUsagePatientIds, setSixteenPlusUsagePatientIds] = useState(
    []
  );
  const [selectedFilter, setSelectedFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const handleOpen = (id) => {
    setOpenType("EDIT");
    setId(id);
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    setOpenType("");
    const token = getAccessToken();
    const clinicId = getAdminId(); //method was declared in earlier versions. Here It returns the clinicId

    const getAllTheapistsOfClinic = async () => {
      const therapists = await getAllTherapists(token, clinicId);

      if (therapists.content !== null) {
        setTherapistDetails(therapists.content);
      }
    };
    getAllTheapistsOfClinic();
  };

  const filterValues = {
    High: "HIGH",
    Low: "LOW",
    Idle: "IDLE",
    Active: "ACTIVE",
  };

  const getPatients = async (filter) => {
    setLoading(true);
    setIsStatusLoading(true);
    const token = getAccessToken();
    const clinicId = getAdminId();
    const result = await getPatientsOfClinic(token, clinicId, filter);
    if (result.message === "Success") {
      if (filter === undefined) {
        setPatients(result.content);
      }
      if (filter === filterValues.High) {
        setHighPatients(result.content);
      }
      if (filter === filterValues.Low) {
        setLowPatients(result.content);
      }
      if (filter === filterValues.Idle) {
        setIdlePatients(result.content);
      }
      if (filter === filterValues.Active) {
        setActivePatients(result.content);
      }

      if (filter) {
        setIsStatusLoading(false);
      }
    }
    setLoading(false);
  };

  const fetchSixteenPlusPatients = async () => {
    const token = getAccessToken();
    const clinicId = getAdminId();
    const todayDate = getToday();
    const result = await getSixteenPlusPatients(clinicId, todayDate, token);
    setSixteenPlusUsagePatientIds(result.content.map((value) => value.id));
  };

  useEffect(() => {
    setExpireTime()
    const token = getAccessToken();
    const clinicId = getAdminId(); //method was declared in earlier versions. Here It returns the clinicId

    const getOneClinic = async () => {
      const clinic = await getClinicInfo(clinicId, token);
      if (clinic.content !== null) {
        setTotalPatient(clinic.content.patients.length);
        setTotalTherapist(clinic.content.therapists.length);
        localStorage.setItem("profilePic", clinic.content.profilePicURL);
        setClinicAdminId(clinic.content.adminId);
      }
    };

    const getPatientSummary = async (filter) => {
      const summary = await getClinicPatientOverView(token, clinicId, filter);
      if (summary.content !== null) {
        if (filter === "LOW") {
          setLow(summary.content.length);
        }
        if (filter === "HIGH") {
          setHigh(summary.content.length);
        }
        if (filter === "IDLE") {
          setIdle(summary.content.length);
        }
      }
    };

    const getAllTheapistsOfClinic = async () => {
      const therapists = await getAllTherapists(token, clinicId);

      if (therapists.content !== null) {
        if (parseInt(getRole()) === 1) {
          setTherapistDetails(therapists.content);
        }
        if (parseInt(getRole()) === 6) {
          setTherapistDetails(
            therapists.content.filter(
              (t) => t.therapistsType !== "CLINIC_ADMIN"
            )
          );
        }
      }
    };

    getAllTheapistsOfClinic();
    getOneClinic();
    getPatientSummary("HIGH");
    getPatientSummary("LOW");
    getPatientSummary("IDLE");

    if (parseInt(getRole()) === 5 || parseInt(getRole()) === 7) {
      getPatients();
      getPatients(filterValues.High);
      getPatients(filterValues.Low);
      getPatients(filterValues.Idle);
      getPatients(filterValues.Active);
      fetchSixteenPlusPatients();
    }
  }, []);

  return (
    <>
      <ClinicAdminSideNav />
      <div style={mainDivStyle}>
        <Grid container style={{ paddingTop: "30px" }}>
          <Grid item xs={12} md={4} sm={12}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <RateCard
                  type={"noOfTherapist"}
                  value={totalTherapist}
                  text={"Total Therapists"}
                  percentage={null}
                />
                <RateCard
                  type={"totalsPatients"}
                  value={totalPatient}
                  text={"Total Patients"}
                  percentage={null}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sm={12}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.39)",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "8px",
              boxShadow: "0px 10px 100px rgba(0, 0, 0, 0.04)",
              padding: "25px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={8}
                style={{ padding: "20px", paddingTop: "0px" }}
              >
                <Typography variant="recordCardHeaderFont">
                  Patients Overview
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}></Grid>

              <Grid item xs={12} md={4}>
                <RecordCard
                  icon={false}
                  name={"High Usage"}
                  number={high}
                  color={"#3ECD96"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RecordCard
                  icon={false}
                  name={"Low Usage"}
                  number={low}
                  color={"#E24020"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RecordCard
                  icon={false}
                  name={"Idle"}
                  number={idle}
                  color={"#4285F4"}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingLeft: "15px", paddingBottom: "30px" }}
          >
            {(parseInt(getRole()) === 1 || parseInt(getRole()) === 6) && (
              <>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    color: "rgba(0, 0, 0, 0.75)",
                    paddingTop: "30px",
                    paddingBottom: "20px",
                  }}
                >
                  User Details
                </Typography>
                <TherapistTable
                  therapistDetails={therapitsDetails}
                  handleOpen={(id) => handleOpen(id)}
                  handleClose={() => handleClose()}
                />
                <AddtherapistForm
                  open={open}
                  handleClose={() => handleClose()}
                  type={openType}
                  id={id}
                />
              </>
            )}
            {(parseInt(getRole()) === 5 || parseInt(getRole()) === 7) && (
              <PatientTable
                patientDetails={patients}
                highPatients={highPatients}
                lowPatients={lowPatients}
                idlePatients={idlePatients}
                activePatients={activePatients}
                statusType={
                  selectedFilter === "" ? FILTER_TYPES.all : selectedFilter
                }
                sixteenPlusUsagePatient={sixteenPlusUsagePatientIds}
                isLoading={loading}
                isStatusLoading={isStatusLoading}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
