export const CLINIC_TYPES = {
  ALL: {
    key: "A",
    name: "Overview",
    label: "all",
  },
  EXERCISE: {
    key: "E",
    name: "Exercise",
    label: "record",
  },
  MEDICATION: {
    key: "M",
    name: "Medication",
    label: "medication",
  },
  DIET: {
    key: "D",
    name: "Diet",
    label: "diet",
  },
  VITALS: {
    key: "V",
    name: "Vitals",
    label: "vitals",
  },
  SYMPTOMS: {
    key: "S",
    name: "Symptoms",
    label: "symptoms",
  },
};

export const CLINIC_TYPES_KEYS = ["E", "M", "D", "V", "S"];

export const DAYS_SHORT = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const FILTER_TYPES = {
  all: "All",
  activePatient: "Active Patient",
  highUsage: "High Usage",
  lowUsage: "Low Usage",
  idle: "Idle",
  sixteenPlus: "16+ Usage",
};

export const CLINIC_RECORDS = {
  ALL: "All",
  EXERCISE: "Exercise",
  MEDICATION: "Medication",
  DIET: "Diet",
  VITALS: "Vitals",
  SYMPTOMS: "Symptoms",
};

export const GRAPH_TYPES = ["day", "week", "month"];

export const USER_TYPES_FOR_CLINIC_OWNER = {
  ALL: "All",
  CLINIC_ADMIN: "Clinic Admin",
  THERAPIST: "Therapist",
  REPORTING_STAFF: "Reporting Staff",
};
export const USER_TYPES_FOR_CLINIC_ADMIN = {
  ALL: "All",
  THERAPIST: "Therapist",
  REPORTING_STAFF: "Reporting Staff",
};
