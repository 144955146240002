import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CloseIcon, UploadIcon } from "../Icons/Icons";
import {
  DialogBackground,
  DialogTitleStyle,
  FileUploadDiv,
  SubmitButton,
} from "../Theme/Theme";
import { helperText } from "../Theme/Theme";
import Dropzone from "react-dropzone";
import Papa from "papaparse";
import { CsvLoadingScreen } from "./CsvLoadingScreen";
import { uploadBulkMedication } from "../Api/Api";
import { getAccessToken, getAdminId } from "../Auth/Auth";
import { toast } from "react-toastify";

export function MedicationCsvUpload(props) {
  const [csv, setCsv] = useState();
  const [loading, setLoading] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [csvError, setCsvError] = useState({ status: false, msg: "" });
  const [disabled, setDisabled] = useState(true);

  const close = (status) => {
    setCsv();
    setDataArray([]);
    setLoading(false);
    setCsvError({ status: false, msg: "" });
    setDisabled(true)
    props.handleClose(status);
  };

  const readCsvfile = (e) => {
    setCsv(e[0]);
    if (e[0].path.slice(-3) !== "csv") {
      setCsvError({ status: true, msg: "Invalid file format." });
      return;
    }
    setCsvError({ status: false, msg: "" });
    if (e) {
      Papa.parse(e[0], {
        complete: function (results) {
          let data = [];
          results.data.map((d) => {
            d.map((m) => {
              if (m.length > 0 && m !== "\n") {
                data.push(m);
              }
              return data;
            });
            return data;
          });
          setDataArray(data);
          setDisabled(false);
        },
      });
    }
  };

  const submitCsv = async () => {
    if (csv === undefined) {
      setCsvError({ status: true, msg: "CSV file is required." });
      return;
    }
    if (dataArray.length === 0) {
      setCsvError({ status: true, msg: "CSV file content is empty" });
      return;
    }
    setLoading(true);
    const token = getAccessToken();
    const adminId = getAdminId();
    const result = await uploadBulkMedication(token, adminId, dataArray);
    if (result.message === "Success") {
      close(true);
      toast.success("Medications added successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
    } else {
      setLoading(false);
      toast.error(result.message);
    }
  };
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.open}
        onClose={close}
        style={DialogBackground}
      >
        {loading === true ? (
          <CsvLoadingScreen />
        ) : (
          <>
            <DialogTitle style={DialogTitleStyle} id="alert-dialog-title">
              Import Medication List
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Dropzone onDrop={(acceptedFiles) => readCsvfile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={FileUploadDiv}>
                      <input {...getInputProps()} />
                      <UploadIcon />

                      <br />
                      {csv === null || csv === undefined ? (
                        <>
                          <Typography variant="subtitle2">
                            Drag & Drop
                          </Typography>
                          <Typography variant="fileUploadBoxSubText">
                            or Select files from device
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="fileUploadBoxSubText">
                          {csv.name}
                        </Typography>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <Typography style={helperText}>{csvError.msg}</Typography>
            </DialogContent>

            <DialogActions
              style={{ paddingRight: "20px", paddingBottom: "20px" }}
            >
              <Button
                style={{ textTransform: "none" }}
                color="customGray"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={SubmitButton}
                onClick={() => submitCsv()}
                disabled={disabled}
              >
                Import
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
