import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../UIComponents/SideNav";
import {
  BulkUploadButton,
  BulkUploadButtonDiv,
  FloatingButtonDiv,
  PaginationDiv,
  TagsScrollContainer,
  TagsScrollContainerItem,
  defaultTag,
  mainDivStyle,
  selectedTag,
} from "../../Theme/Theme";
import { ExerciseCard } from "../../UIComponents/ExerciseCard";
import {
  Button,
  Chip,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  BulkExerciseUploadIcon,
  DeletTagsIcon,
  FloatingButtonAddIcon,
  TagsButtonIcon,
} from "../../Icons/Icons";
import { ExerciseForm } from "../../UIComponents/ExerciseForm";
import { getAllExercises, getAllTags, updateTag } from "../../Api/Api";
import { VideoPlayer } from "../../UIComponents/VideoPlayer";
import { ExerciseDelete } from "../../UIComponents/ExerciseDelete";
import { ExerciseCsvUpload } from "../../UIComponents/ExerciseCsvUpload";
import { CustomPagination } from "../../UIComponents/CustomPagination";
import { getAccessToken, getAdminId } from "../../Auth/Auth";
import { toast } from "react-toastify";
import { DeleteTag } from "../../UIComponents/DeleteTag";

export function Exercise(props) {
  const [exerciseFormOpen, setExerciseFormOpen] = useState(false);
  const [exerciseFormOpenType, setExerciseFormOpenType] = useState("");
  const [bulkUploadFormOpen, setBulkUploadFormOpen] = useState(false);

  const [videoFormOpen, setVideoFormOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const [deleteTagOpen, setDeleteTagOpen] = useState(false);

  const [exercises, setExercises] = useState([]);
  const [constExercises, setConstExercises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exerciseId, setExerciseId] = useState("");

  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(["0"]);

  const [edit, setEdit] = useState();
  const [newTagName, setNewTagName] = useState("");

  const [deleteTag, setDeleteTag] = useState();

  const [page, setPage] = useState(0);
  const exercisesPerPage = 8;

  const scrollContainerRef = useRef(null);
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 100; // Adjust the scroll distance as needed
    }
  };
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 100; // Adjust the scroll distance as needed
    }
  };

  const openExerciseForm = (type, id) => {
    setExerciseFormOpenType(type);
    setExerciseId(id);
    setExerciseFormOpen(true);
  };
  const closeExerciseForm = () => {
    setExerciseFormOpenType("");
    setExerciseFormOpen(false);
    getAllTagsOfAdmin();
    setSelectedTags(["0"]);
    getTutotialExercises();
  };

  const openVideo = (url) => {
    setVideoUrl(url);
    setVideoFormOpen(true);
  };
  const closeVideo = () => {
    setVideoFormOpen(false);
  };

  const openDelete = (id) => {
    setExerciseId(id);
    setDeleteFormOpen(true);
  };
  const closeDelete = () => {
    setExerciseId("");
    setDeleteFormOpen(false);
    setSelectedTags(["0"]);
    getTutotialExercises();
  };
  const openBulkUpload = () => {
    setBulkUploadFormOpen(true);
  };
  const closeBulkUpload = (status) => {
    setBulkUploadFormOpen(false);
    if (status) {
      getTutotialExercises();
      getAllTagsOfAdmin();
    }
  };

  const getTutotialExercises = async (filterArray) => {
    const exes = await getAllExercises(filterArray, getAdminId());
    if (exes.message === "Success") {
      setPage(0);
      setExercises(
        exes.content.filter((t) => t.tutorialUploader === getAdminId())
      );
      setConstExercises(
        exes.content.filter((t) => t.tutorialUploader === getAdminId())
      );
      setLoading(false);
    } else if (exes === "Network Error") {
      toast.error(exes, { toastId: "id" });
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const getAllTagsOfAdmin = async () => {
    const result = await getAllTags(getAdminId());
    if (result.message === "Success") {
      result.content.splice(0, 0, { id: "0", name: "All" }); //Add all tag
      setAllTags(result.content);
    } else {
      setAllTags([]);
    }
  };

  const readSelectedTags = (id) => {
    setEdit();
    let finalArry;
    if (id !== "0") {
      let tagsWithoutAll = selectedTags.filter((t) => t !== "0");
      if (tagsWithoutAll.includes(id)) {
        tagsWithoutAll.indexOf(id) !== -1 &&
          tagsWithoutAll.splice(tagsWithoutAll.indexOf(id), 1);
      } else {
        tagsWithoutAll.push(id);
      }
      finalArry = tagsWithoutAll;
      setSelectedTags(tagsWithoutAll);
    }
    if (id === "0") {
      setSelectedTags(["0"]);
      finalArry = ["0"];
    }
    if (finalArry.length === 0) {
      setSelectedTags(["0"]);
    }
    getTutotialExercises(finalArry);
  };

  const editTag = (id) => {
    if (id === "0") {
      return;
    }
    setSelectedTags(["0"]);
    getTutotialExercises();
    setEdit(id);
  };

  const updateTagName = async (id, newName) => {
    const data = {
      name: newName,
    };
    const token = getAccessToken();
    const admin = getAdminId();
    const result = await updateTag(token, admin, id, data);
    if (result.message !== "Success") {
      toast.error(result.message);
    } else {
      toast.success("Tag updated successfully", {
        style: {
          background: "#4F9E4E",
        },
      });
      setSelectedTags(["0"]);
      getTutotialExercises();
      getAllTagsOfAdmin();
      setEdit();
    }
  };

  const openDeleteTag = (id) => {
    setDeleteTag(id);
    setDeleteTagOpen(true);
  };

  const closeDeleteTag = (status) => {
    setDeleteTagOpen(false);
    if (status === true) {
      getTutotialExercises();
      getAllTagsOfAdmin();
    }
  };

  const filterExercises = (e) => {
    let filteredExercises = [];
    constExercises.filter((obj) => {
      if (obj.tutorialTitle.toLowerCase().includes(e.trim().toLowerCase())) {
        filteredExercises.push(obj);
      }
      return filteredExercises;
    });
    setExercises(filteredExercises);
  };

  const paginationFilter = (n) => {
    setPage(n - 1);
  };

  useEffect(() => {
    getTutotialExercises();
    getAllTagsOfAdmin();
  }, []);
  return (
    <>
      <SideNav filterFunction={(e) => filterExercises(e)} />
      <div style={mainDivStyle} onClick={() => setEdit()}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            xl={12}
            lg={12}
            style={{
              paddingBottom: "25px",
              paddingTop: "30px",
            }}
          >
            <div style={TagsScrollContainer}>
              <IconButton style={{ padding: "10px" }} onClick={scrollLeft}>
                <TagsButtonIcon rotate="180deg" />
              </IconButton>
              <div style={TagsScrollContainerItem} ref={scrollContainerRef}>
                {allTags.map((t, i) => (
                  <>
                    {edit !== t.id ? (
                      <Chip
                        key={i}
                        label={t.name}
                        style={
                          selectedTags.includes(t.id) ? selectedTag : defaultTag
                        }
                        onMouseDown={() => readSelectedTags(t.id)}
                        onDoubleClick={() => editTag(t.id)}
                      />
                    ) : (
                      <TextField
                        key={i}
                        defaultValue={t.name}
                        autoFocus={true}
                        size="small"
                        style={{ marginRight: "7px" }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "33px",
                            maxWidth: "200px",
                          },
                        }}
                        onChange={(e) => setNewTagName(e.target.value)}
                        onKeyPress={(e) =>
                          e.code === "Enter" && updateTagName(t.id, newTagName)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                style={{
                                  marginRight: "-12px",
                                  backgroundColor: "#FEECE8",
                                  borderRadius: "0px",
                                }}
                                onClick={() => openDeleteTag(t.id)}
                              >
                                <DeletTagsIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </>
                ))}
              </div>
              <IconButton style={{ padding: "10px" }} onClick={scrollRight}>
                <TagsButtonIcon />
              </IconButton>
            </div>
            <div style={BulkUploadButtonDiv}>
              <Button
                style={BulkUploadButton}
                onClick={() => openBulkUpload()}
                startIcon={<BulkExerciseUploadIcon />}
              >
                Upload Bulk Videos
              </Button>
            </div>
          </Grid>
        </Grid>
        {loading === true ? (
          <Grid
            container
            style={{
              paddingTop: "50px",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "70vh",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <CircularProgress size={30} />
            </Grid>
          </Grid>
        ) : exercises.length === 0 ? (
          <>
            <Grid
              container
              style={{
                paddingTop: "50px",
                width: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.5)" }}
              >
                <Typography style={{ margin: "auto" }}>No data</Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container style={{ paddingLeft: "30px" }}>
              {exercises
                .slice(
                  page * exercisesPerPage,
                  page * exercisesPerPage + exercisesPerPage
                )
                .map((exercise, i) => {
                  return (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl={3}
                      style={{ paddingBottom: "50px" }}
                    >
                      <ExerciseCard
                        tutorialTitle={exercise.tutorialTitle}
                        thumbnailURL={exercise.thumbnailURL}
                        description={exercise.description}
                        owned={exercise.tutorialUploader === getAdminId()}
                        extraction={exercise.extraction}
                        editExercise={() =>
                          openExerciseForm("EDIT", exercise.id)
                        }
                        openVideo={() => openVideo(exercise.videoURL)}
                        openDelete={() => openDelete(exercise.id)}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Grid container>
              <Grid item xs={1} md={4} lg={4} xl={4}></Grid>
              <Grid
                item
                xs={10}
                md={4}
                lg={4}
                xl={4}
                style={{ textAlign: "center" }}
              >
                <div style={PaginationDiv}>
                  <CustomPagination
                    maxPages={
                      exercises.length % exercisesPerPage > 0
                        ? Math.ceil(exercises.length / exercisesPerPage)
                        : Math.floor(exercises.length / exercisesPerPage)
                    }
                    paginationFilter={(n) => paginationFilter(n)}
                  />
                </div>
              </Grid>
              <Grid item xs={1} md={4} lg={4} xl={4}></Grid>
            </Grid>
          </>
        )}

        <div style={FloatingButtonDiv}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{ boxShadow: "none" }}
            onClick={() => openExerciseForm("NEW", "")}
          >
            <FloatingButtonAddIcon />
          </Fab>
        </div>
      </div>

      <ExerciseForm
        open={exerciseFormOpen}
        handleClose={() => closeExerciseForm()}
        type={exerciseFormOpenType}
        exerciseId={exerciseId}
      />
      <VideoPlayer
        open={videoFormOpen}
        handleClose={() => closeVideo()}
        videoURL={videoUrl}
      />
      <ExerciseDelete
        open={deleteFormOpen}
        exerciseId={exerciseId}
        handleClose={() => closeDelete()}
      />

      <ExerciseCsvUpload
        open={bulkUploadFormOpen}
        handleClose={(status) => closeBulkUpload(status)}
      />

      <DeleteTag
        open={deleteTagOpen}
        handleClose={(status) => closeDeleteTag(status)}
        tagId={deleteTag}
      />
    </>
  );
}
