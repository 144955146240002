import {
  Card,
  CardMedia,
  Typography,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import debounce from "lodash.debounce";
import {
  ExerciseDeleteIcon,
  ExerciseEditIcon,
  ExtractionFailed,
  ExtractionSuccess,
  PlayIcon,
} from "../Icons/Icons";
import styled from "@emotion/styled";
import { getRole } from "../Auth/Auth";

export function ExerciseCard(props) {
  const [clicked, setClicked] = useState(false);
  const [clicked1, setClicked1] = useState(false);

  const debouncedHandleMouseEnter = debounce(() => setClicked(true), 200);

  const handlOnMouseLeave = () => {
    setClicked(false);
    debouncedHandleMouseEnter.cancel();
  };

  const progressDivDescriptionEnter = debounce(() => setClicked1(true), 200);
  const progressDivDescriptionLeave = () => {
    console.log("---");
    setClicked1(false);
    progressDivDescriptionEnter.cancel();
  };

  return (
    <Card
      sx={{ minWidth: "218px", minHeight: "310px", maxHeight: "310px" }}
      style={{
        borderRadius: "10px",
        width: "80%",
        cursor: "pointer",
        boxShadow: "0px 3px 24px -6px #0000000F",
      }}
    >
      <div
        onMouseEnter={debouncedHandleMouseEnter}
        onMouseLeave={handlOnMouseLeave}
      >
        {clicked ? (
          <CardMedia
            component="div"
            height="145px"
            style={{
              minHeight: "145px",
              backgroundImage: `url(${props.thumbnailURL})`,
              position: "relative",
              objectFit: "cover",
            }}
            width="80%"
            alt="thumbnail"
          >
            <div
              style={{
                width: "100%",
                height: "145px",
                backgroundColor: "rgba(0,0,0,0.6)",
                paddingTop: "40px",
              }}
            >
              <Grid container>
                {props.owned === true ? (
                  <>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      style={{ padding: "10px", textAlign: "center" }}
                    >
                      <IconButton
                        style={{ padding: "0px" }}
                        onClick={() => props.openVideo()}
                      >
                        <PlayIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      style={{ padding: "10px", textAlign: "center" }}
                    >
                      <IconButton
                        style={{ padding: "0px" }}
                        onClick={() => props.editExercise()}
                      >
                        <ExerciseEditIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      style={{ padding: "10px", textAlign: "center" }}
                    >
                      <IconButton
                        style={{ padding: "0px" }}
                        onClick={() => props.openDelete()}
                      >
                        <ExerciseDeleteIcon />
                      </IconButton>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      style={{ padding: "10px", textAlign: "center" }}
                    ></Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      style={{ padding: "10px", textAlign: "center" }}
                    >
                      <IconButton
                        style={{ padding: "0px" }}
                        onClick={() => props.openVideo()}
                      >
                        <PlayIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      style={{ padding: "10px", textAlign: "center" }}
                    ></Grid>
                  </>
                )}
              </Grid>
            </div>
          </CardMedia>
        ) : (
          <CardMedia
            component="img"
            height="145px"
            width="218px"
            image={props.thumbnailURL}
            alt="thumbnail"
          />
        )}
      </div>

      <CardContent
        style={{
          minHeight: "130px",
          maxHeight: "130px",
        }}
      >
        <CutomToolTip
          title={props.tutorialTitle.length > 40 ? props.tutorialTitle : ""}
          placement="bottom"
        >
          <Typography variant="exersiceCardTopic">
            {props.tutorialTitle.length > 40
              ? props.tutorialTitle.slice(0, 40) + "..."
              : props.tutorialTitle}
          </Typography>
        </CutomToolTip>
        <br />
        <div style={{ textAlign: "center" }}>
          <CutomToolTip
            title={props.description.length > 60 ? props.description : ""}
            placement="bottom"
          >
            <div style={{ textAlign: "left" }}>
              <Typography variant="exersiceCardBody">
                {props.description.length > 60
                  ? props.description.slice(0, 60) + "..."
                  : props.description}
              </Typography>
            </div>
          </CutomToolTip>
        </div>
      </CardContent>
      <Grid container>
        <Grid
          item
          sm={10}
          xs={10}
          lg={10}
          md={10}
          style={{
            minHeight: "25px",
            maxHeight: "25px",
            paddingLeft: "15px",
          }}
        >
          <DisplayStatus
            progressDivDescriptionEnter={() => progressDivDescriptionEnter()}
            progressDivDescriptionLeave={() => progressDivDescriptionLeave()}
            clicked1={clicked1}
            extraction={props.extraction}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          style={{ textAlign: "right", paddingRight: "20px" }}
        >
          {parseInt(getRole()) === 1 && props.owned === true ? (
            <>
              {localStorage.getItem("profilePic") === "" ? (
                <svg
                  width="13"
                  height="18"
                  viewBox="0 0 13 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 17.5C0 15.7319 0.67945 14.0362 1.88888 12.786C3.09831 11.5357 4.73865 10.8333 6.44904 10.8333C8.15943 10.8333 9.79976 11.5357 11.0092 12.786C12.2186 14.0362 12.8981 15.7319 12.8981 17.5H0ZM7.25517 12.5692V15.8333H11.0109C10.7228 14.9928 10.2227 14.2471 9.56243 13.6733C8.90216 13.0994 8.10563 12.7183 7.25517 12.5692ZM5.64291 15.8333V12.5692C4.79244 12.7183 3.99591 13.0994 3.33564 13.6733C2.67538 14.2471 2.17532 14.9928 1.88715 15.8333H5.64291ZM6.44904 10C3.77672 10 1.61226 7.7625 1.61226 5C1.61226 2.2375 3.77672 0 6.44904 0C9.12136 0 11.2858 2.2375 11.2858 5C11.2858 7.7625 9.12136 10 6.44904 10ZM6.44904 8.33333C8.23058 8.33333 9.67355 6.84167 9.67355 5C9.67355 3.15833 8.23058 1.66667 6.44904 1.66667C4.66749 1.66667 3.22452 3.15833 3.22452 5C3.22452 6.84167 4.66749 8.33333 6.44904 8.33333Z"
                    fill="#E24020"
                  />
                </svg>
              ) : (
                <img
                  src={localStorage.getItem("profilePic")}
                  width={"30px"}
                  alt="admin"
                ></img>
              )}
            </>
          ) : (
            <>
              <img
                src={require("../Images/logo.png")}
                width={"40px"}
                alt="admin"
              ></img>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

const CutomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    color: #5F6165;
    background-color: white;
    width:70%;
    margin:auto
`);

export const Loading = (props) => {
  return (
    <Box sx={{ position: "relative", display: "flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary,
          position: "absolute",
          left: 0,
        }}
        size={20}
        thickness={4}
        disableShrink
        {...props}
      />
    </Box>
  );
};

export const DisplayStatus = (props) => {
  return (
    <div
      style={{
        borderRadius: "20px",
        height: "20px",
        backgroundColor: "#F8F8F8",
        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
        display: "flex",
        alignItems: "center",
        width: props.clicked1 === true ? "fit-content" : "20px",
      }}
    >
      {props.extraction === "Pending" && (
        <Loading
          onMouseEnter={() => props.progressDivDescriptionEnter()}
          onMouseLeave={() => props.progressDivDescriptionLeave()}
        />
      )}
      {props.extraction === "Success" && (
        <div
          onMouseEnter={() => props.progressDivDescriptionEnter()}
          onMouseLeave={() => props.progressDivDescriptionLeave()}
        >
          <ExtractionSuccess />
        </div>
      )}
      {(props.extraction === "Failed" || props.extraction === null) && (
        <div
          onMouseEnter={() => props.progressDivDescriptionEnter()}
          onMouseLeave={() => props.progressDivDescriptionLeave()}
        >
          <ExtractionFailed />
        </div>
      )}

      <Typography
        style={{
          fontSize: "12px",
          paddingLeft: "10px",
          paddingRight: "10px",
          color:
            props.extraction === "Pending"
              ? "rgba(234, 81, 49, 1)"
              : props.extraction === "Success"
              ? "rgba(79, 158, 78, 1)"
              : "rgba(229, 60, 53, 1)",
        }}
        display={props.clicked1 === false && "none"}
      >
        {props.extraction === "Pending" && "Uploading..."}
        {props.extraction === "Success" && "Uploaded Successfully"}
        {(props.extraction === "Failed" || props.extraction === null) &&
          "Uploading Failed"}
      </Typography>
    </div>
  );
};
