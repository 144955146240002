import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CloseIcon } from "../Icons/Icons";
import { TextInput } from "../InputComponents/TextInput";
import { IconButton, Typography } from "@mui/material";
import {
  SubmitButton,
  DialogTitleStyle,
  helperText,
  DialogBackground,
} from "../Theme/Theme";

export default function CreateNewAdminForm(props) {
  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.open}
        onClose={props.handleClose}
        style={DialogBackground}
      >
        <DialogTitle style={DialogTitleStyle} id="alert-dialog-title">
          Add New User
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant="subtitle2"
            style={{ color: "rgba(36, 39, 49, 1)", paddingBottom: "5px" }}
          >
            User’s Email
          </DialogContentText>
          <TextInput
            error={props.emailError.status}
            value={props.email}
            onChange={(e) => props.readEmail(e.target.value)}
            placeholder="Enter Valid Email Address"
            fullWidth
            style={{ paddingBottom: "10px" }}
          />
          <Typography style={helperText}>{props.emailError.msg}</Typography>
        </DialogContent>
        <DialogActions style={{ paddingRight: "20px", paddingBottom: "20px" }}>
          <Button
            style={{ textTransform: "none" }}
            color="customGray"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={SubmitButton}
            onClick={() => props.submit()}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
