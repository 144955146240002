import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Arrow,
  NoOfClinics,
  TotalPatients,
  TotalTherapists,
} from "../Icons/Icons";
import { styled } from "@mui/material/styles";

export function RateCard(props) {
  return (
    <Grid
      container
      style={{
        backgroundColor: "white",
        borderRadius: "8px",
        paddingTop: "28px",
        paddingBottom: "28px",
        paddingLeft: "25px",
        margin: "15px",
        width: "85%",
        boxShadow: "0px 10px 100px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Grid item xs={12} md={3} xl={3}>
        <StyledDiv>
          {props.type === "noOfClinics" && <NoOfClinics />}
          {props.type === "totalsPatients" && <TotalPatients />}
          {props.type === "noOfTherapist" && <TotalTherapists />}
        </StyledDiv>
      </Grid>
      <Grid item xs={12} md={9} xl={9}>
        <Typography variant="rateCardNoFont">{props.value}</Typography>
        <br />
        <Typography variant="rateCardSubFont">{props.text}</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container style={{ paddingTop: "15px" }}>
          {props.percentage !== null && (
            <Grid item xs={8} md={12}>
              <Arrow />
              <Typography variant="rateCardPercentageFont">
                {props.percentage}
              </Typography>
              <Typography variant="rateCardPercentageSubFont">
                since last month
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const StyledDiv = styled("div")(({ theme }) => ({
  width: "60px",
  height: "60px",
  backgroundColor: "#F8F8F8",
  borderRadius: "50px",
  textAlign: "center",
  paddingTop: "20px",
  [theme.breakpoints.between(600, 1300)]: {
    width: "35px",
    height: "35px",
    paddingTop: "8px",
  },
}));
