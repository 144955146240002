import React, { useEffect, useState } from "react";
import ClinicAdminSideNav from "../UIComponents/ClinicAdminSideNav";
import { mainDivStyle } from "../Theme/Theme";
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import PatientRecordTable from "../UIComponents/PatientRecordTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllRecordTypesInLast30Days,
  getAssignedTutorials,
  getClinicInfo,
  getPatient,
  getPatientRecordSummary,
  getPatientRecords,
} from "../Api/Api";
import { getAccessToken, getAdminId } from "../Auth/Auth";
import { BackArrow, DateIcon, FilterIcon } from "../Icons/Icons";
import { CLINIC_TYPES, CLINIC_TYPES_KEYS, Months } from "../Utils/Enums";
import ReactDatePicker from "react-datepicker";
import RecordPopup from "../UIComponents/RecordPopup";
import Prize from "../Images/Prize.png";
import "react-datepicker/dist/react-datepicker.css";
import _ from "underscore";

function ClinicAdminPatientAllLogs() {
  const { patientId } = useParams();
  const token = getAccessToken();
  const navigator = useNavigate();
  const [patientRecords, setPatientRecords] = useState([]);
  const [patientAllRecords, setPatientAllRecords] = useState([]);
  const [patientData, setPatientData] = useState({});
  const [summary, setSummary] = useState([]);
  const [recordTitle, setRecordTitle] = useState("All");
  const [record, setRecord] = useState({});
  const [assignedTutorials, setAssignedTutorials] = useState([]);
  const [clinicRecordType, setClinicRecordType] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  useEffect(() => {
    function getDataCallback(data) {
      const orderedRecord = _.sortBy(
        data,
        (item) => item.uploadedDate
      ).reverse();
      setPatientRecords(orderedRecord);
      setPatientAllRecords(orderedRecord);
    }
    getPatientRecords(patientId, selectedMonth, getDataCallback, token);
  }, [selectedMonth]);

  useEffect(() => {
    async function fetchPatient() {
      const result = await getPatient(patientId, token);
      if (result.message === "Success") {
        setPatientData(result.content);
      }
    }

    async function fetchClinic() {
      const clinicId = getAdminId(); //method was declared in earlier versions. Here It returns the clinicId
      const result = await getClinicInfo(clinicId, token);
      if (result.content !== null) {
        const filteredClinicTypes = CLINIC_TYPES_KEYS.filter(
          (value) => result.content?.clinicTypes.includes(value) && value
        );
        setClinicRecordType([CLINIC_TYPES.ALL.key, ...filteredClinicTypes]);
      }
    }

    async function fetchPatientAssignedTutorials() {
      const result = await getAssignedTutorials(patientId, token);
      if (result.message === "Success") {
        setAssignedTutorials(result.content);
      }
    }

    fetchPatient();
    fetchClinic();
    fetchPatientAssignedTutorials();
    getSummary();
  }, []);

  useEffect(() => {
    const date = new Date();
    onSelectedMonth(date);
  }, []);

  function onSelectedMonth(date) {
    const month = [Months[date.getMonth()]];
    setSelectedDate(`${month[0].slice(0, 3)}, ${date.getFullYear()}`);
    setSelectedMonth(date);
    setRecordTitle("All");
    setAnchorEl(null);
  }

  function filterRecords(type) {
    if (type === CLINIC_TYPES.ALL.key) {
      setPatientRecords(patientAllRecords);
      setRecordTitle("All");
    } else {
      let recordType;
      switch (type) {
        case CLINIC_TYPES.EXERCISE.key:
          recordType = "record";
          setRecordTitle(CLINIC_TYPES.EXERCISE.name);
          break;
        case CLINIC_TYPES.DIET.key:
          recordType = CLINIC_TYPES.DIET.label;
          setRecordTitle(CLINIC_TYPES.DIET.name);
          break;
        case CLINIC_TYPES.MEDICATION.key:
          recordType = CLINIC_TYPES.MEDICATION.label;
          setRecordTitle(CLINIC_TYPES.MEDICATION.name);
          break;
        case CLINIC_TYPES.VITALS.key:
          recordType = CLINIC_TYPES.VITALS.label;
          setRecordTitle(CLINIC_TYPES.VITALS.name);
          break;
        case CLINIC_TYPES.SYMPTOMS.key:
          recordType = CLINIC_TYPES.SYMPTOMS.label;
          setRecordTitle(CLINIC_TYPES.SYMPTOMS.name);
          break;
        default:
          break;
      }
      const clone = patientAllRecords.filter(
        (value) => value.type === recordType
      );
      setPatientRecords(clone);
      setAnchorFilter(null);
    }
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleFilterClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handleFilterClosePopup = () => {
    setAnchorFilter(null);
  };

  async function getSummary() {
    let currentDate = new Date();
    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const res = await getAllRecordTypesInLast30Days(
      patientId,
      token,
      thirtyDaysAgo.getFullYear(),
      thirtyDaysAgo.getMonth() + 1,
      thirtyDaysAgo.getDate(),
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    if (res.message === "Success") {
      setSummary(res.content);
    }
  }

  function onViewRecord(data) {
    setRecord(data);
    handleOpen();
  }

  function goBack() {
    navigator(`/clinicUser/patients`);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ClinicAdminSideNav />
      <div style={mainDivStyle}>
        <Grid container>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Grid container padding={"10px"} justifyContent={"space-between"}>
              <Grid container width={"30%"} alignItems={"center"}>
                <IconButton padding={"10px"} onClick={goBack}>
                  <BackArrow />
                </IconButton>
                <Typography style={{ fontWeight: "700", paddingLeft: "10px" }}>
                  All Logs
                </Typography>
              </Grid>
              <Grid>
                <Button
                  onClick={handleFilterClick}
                  style={{
                    textTransform: "none",
                    backgroundColor: recordTitle === "" ? "black" : "#E24020",
                    boxShadow: "0px 2px 6px 0px #0000001F",
                    marginLeft: "10px",
                    marginRight: "10px",
                    maxWidth: "120px",
                    color: recordTitle === "" ? "black" : "white",
                  }}
                  startIcon={
                    <FilterIcon
                      iconColor={recordTitle === "" ? "#E24020" : "white"}
                    />
                  }
                >
                  {recordTitle}
                </Button>
                <Popover
                  open={Boolean(anchorFilter)}
                  anchorEl={anchorFilter}
                  onClose={handleFilterClosePopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {clinicRecordType &&
                    clinicRecordType.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => filterRecords(item)}
                          style={{
                            backgroundColor: recordTitle === item && "#FCE8E4",
                          }}
                        >
                          {Object.values(CLINIC_TYPES).map((record) => {
                            return record.key === item
                              ? record.name === "Overview"
                                ? "All"
                                : record.name
                              : null;
                          })}
                        </MenuItem>
                      );
                    })}
                </Popover>
                <Button
                  onClick={handleClick}
                  style={{
                    color: "#000000",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  endIcon={<DateIcon iconColor={"#E24020"} />}
                >
                  {selectedDate}
                </Button>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClosePopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  elevation={0}
                >
                  <ReactDatePicker
                    selected={selectedMonth}
                    onChange={(date) => onSelectedMonth(date)}
                    inline
                    showMonthYearPicker
                  />
                </Popover>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} style={{ padding: "10px" }}>
                <Grid container backgroundColor="white" padding="10px">
                  <Grid item xs={11}>
                    <Grid container>
                      <img
                        alt="profile"
                        width={"100px"}
                        height={"100px"}
                        src={
                          !patientData.profilePicURL
                            ? require("../Images/testuser.png")
                            : patientData.profilePicURL
                        }
                        style={{ borderRadius: "100%", margin: "10px" }}
                      />
                      <CardContent>
                        <Typography fontWeight="md" variant="h4">
                          {patientData.givenName && patientData.familyName
                            ? patientData.givenName +
                              " " +
                              patientData.familyName
                            : patientData.patientName}
                        </Typography>
                        <Typography variant="body2" paddingTop={"10px"}>
                          Activity
                        </Typography>
                        <Grid container direction="row">
                          <Typography
                            variant="body2"
                            fontWeight={"500"}
                            color={summary.length >= 16 ? "#009D09" : "#E24020"}
                          >
                            {summary.length} Days
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={"500"}
                            color={"#72767C"}
                            marginLeft={"5px"}
                          >
                            Usage in Last 30 Days{" "}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                  >
                    {summary.length >= 16 ? (
                      <img alt="prize" src={Prize} />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ padding: "10px" }}>
                <Grid width="100%" backgroundColor="white" padding="10px">
                  <Grid
                    padding="10px"
                    height="130px"
                    width="100%"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#E24020"
                      fontWeight="700"
                    >
                      Diagnosis:
                    </Typography>
                    <Typography variant="body2">
                      {patientData.diagnosis}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid padding={"10px"}>
              <Typography
                variant="body1"
                fontWeight="500"
                paddingBottom={"10px"}
              >
                {recordTitle} Logs
              </Typography>
              <PatientRecordTable
                patientRecords={patientRecords}
                onViewRecord={onViewRecord}
              />
            </Grid>
          </Grid>
        </Grid>
        <RecordPopup
          isModalOpen={open}
          onClose={handleClose}
          recordData={record}
          type={record?.type}
          assignedTutorials={assignedTutorials}
        />
      </div>
    </>
  );
}

export default ClinicAdminPatientAllLogs;
