export function dateFormatter(date) {
    if(!date){
        return "";
    }
    
    const formattedDate = 
    `${new Date(date).getDate().toString().padStart(2, "0")}/${(new Date(date).getMonth() + 1).toString().padStart(2, "0")}/${new Date(date).getFullYear().toString()}`;

    return formattedDate;
}

export function convertTo12HourTime(time24) {
    if (!time24) {
      return time24;
    }
    const [hours, minutes] = time24.split(':');
    let period = 'AM';
    let hour = parseInt(hours, 10);
  
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) {
        hour -= 12;
      }
    }
  
    return `${hour.toString().padStart(2, '0')}:${minutes} ${period}`;
}

export function getToday() {
    const today = new Date();
  
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1 and pad with '0' if needed
    const day = String(today.getDate()).padStart(2, '0'); // Pad the day with '0' if needed
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = date.getDate().toString().padStart(2, '0');
    return { year, month, day };
}


export function getRecordColor(type) {
    if (type === "record") {
        return "#D44C31";
    } else if (type === "diet") {
        return "#0C6BBB";
    } else if (type === "vitals") {
        return "#4BA41A";
    } else if(type === "medication"){
        return "#D69C37";
    }if (type === "symptoms") {
        return "#CDD01F";
    } 
}

export function getTodayDate(dayDiff, type = "ymd") {
    let today = new Date();
  
    if (dayDiff) {
      today.setDate(today.getDate() + dayDiff);
    }
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
  
    if (type === "ymd") {
      return yyyy + "-" + mm + "-" + dd;
    } else if (type === "mdy") {
      return mm + "-" + dd + "-" + yyyy;
    } else {
      return dd + "-" + mm + "-" + yyyy;
    }
  
  
  }
